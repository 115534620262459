import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
  SHARED_ZONE_API_URL,
} from '../apiRequests';

import {
  SHARED_ZONES_LIST_GET_REQUEST,
  SHARED_ZONES_LIST_MY_GET_FAILURE,
  SHARED_ZONES_LIST_MY_GET_SUCCESS,
  SHARED_ZONES_LIST_TO_ME_GET_FAILURE,
  SHARED_ZONES_LIST_TO_ME_GET_SUCCESS,
  SHARED_ZONE_ADD_FAILURE,
  SHARED_ZONE_ADD_REQUEST,
  SHARED_ZONE_ADD_SUCCESS,
  SHARED_ZONE_GET_DEL_ACCEPT_FAILURE,
  SHARED_ZONE_GET_DEL_ACCEPT_REQUEST,
  SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS,
  SHARED_ZONE_CLEAR_ERRORS,
} from './actionTypes';

// SHARED ZONES LIST GET
export const sharedZonesListGetAction = (toMe = null, fetchUrl = `${SHARED_ZONE_API_URL}/${toMe ? 'to-me' : 'my'}`, isActive = null, accepted = null) => (dispatch) => {
  dispatch({ type: SHARED_ZONES_LIST_GET_REQUEST });

  return axiosGet(
    fetchUrl,
    {
      is_active: isActive,
      accepted,
    },
  ).then((response) => {
    const data = {
      type: toMe ? SHARED_ZONES_LIST_TO_ME_GET_SUCCESS : SHARED_ZONES_LIST_MY_GET_SUCCESS,
    };

    if (toMe) {
      data.sharedZonesListToMe = response.data.data.results;
      data.sharedZonesCountToMe = response.data.data.count || 0;
      data.sharedZonesListToMeNext = response.data.data.next || null;
      data.sharedZonesListToMePrevious = response.data.data.previous || null;
    } else {
      data.sharedZonesListMy = response.data.data.results;
      data.sharedZonesCountMy = response.data.data.count || 0;
      data.sharedZonesListMyNext = response.data.data.next || null;
      data.sharedZonesListMyPrevious = response.data.data.previous || null;
    }

    dispatch(data);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        const errorData = {
          type: toMe ? SHARED_ZONES_LIST_TO_ME_GET_FAILURE : SHARED_ZONES_LIST_MY_GET_FAILURE,
        };

        if (toMe) {
          errorData.errorsSharedZonesListToMeGet = response.data ? response.data.data.detail : null;
        } else {
          errorData.errorsSharedZonesListMyGet = response.data ? response.data.data.detail : null;
        }

        dispatch(errorData);
      } else {
        const errorData = {
          type: toMe ? SHARED_ZONES_LIST_TO_ME_GET_FAILURE : SHARED_ZONES_LIST_MY_GET_FAILURE,
        };

        if (toMe) {
          errorData.errorsSharedZonesListToMeGet = `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`;
        } else {
          errorData.errorsSharedZonesListMyGet = `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`;
        }
        dispatch(errorData);
      }
    }
  });
};

// SINGLE SHARED ZONE ADD
export const sharedZoneAddAction = (zoneId, email, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: SHARED_ZONE_ADD_REQUEST });

  return axiosPost(
    `${SHARED_ZONE_API_URL}/create`,
    {
      zone: zoneId,
      email,
    },
  ).then(() => {
    dispatch({
      type: SHARED_ZONE_ADD_SUCCESS,
    });
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
    sharedZonesListGetAction()(dispatch);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: SHARED_ZONE_ADD_FAILURE,
          errors: {
            zone: response.data.data.zone ? response.data.data.zone[0] : null,
            email: response.data.data.email ? response.data.data.email[0] : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors[0] : null,
          },
        });
      } else if (response.status === 403 || response.status === 404) {
        dispatch({
          type: SHARED_ZONE_ADD_FAILURE,
          errors: { nonField: response.data && response.data.data.detail },
        });
      } else {
        dispatch({
          type: SHARED_ZONE_ADD_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// SINGLE SHARED ZONE GET / DEL / ACCEPT
export const sharedZoneGetDelAcceptAction = (sharedZoneId, action = null, callbackSuccess = null, callbackError = null) => (dispatch) => {
  dispatch({ type: SHARED_ZONE_GET_DEL_ACCEPT_REQUEST });
  let method = null;

  const ACTIONS = {
    del: 'del',
    accept: 'accept',
  };

  if (action === ACTIONS.del) {
    method = axiosDelete;
  } else if (action === ACTIONS.accept) {
    method = axiosPut;
  } else {
    method = axiosGet;
  }

  return method(
    `${SHARED_ZONE_API_URL}/${sharedZoneId}${action === ACTIONS.accept ? '/accept' : ''}`,
    { shared_zone_id: sharedZoneId },
  ).then((response) => {
    dispatch({
      type: SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS,
      sharedZoneData: response.data.data,
    });
    if (callbackSuccess !== null) {
      callbackSuccess();
    }

    sharedZonesListGetAction(action === ACTIONS.accept || null)(dispatch);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: SHARED_ZONE_GET_DEL_ACCEPT_FAILURE,
          errorsSharedZoneGetDelAccept: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: SHARED_ZONE_GET_DEL_ACCEPT_FAILURE,
          errorsSharedZoneGetDelAccept: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
      if (callbackError !== null) {
        callbackError();
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearSharedZoneErrorsAction = () => (dispatch) => {
  dispatch({ type: SHARED_ZONE_CLEAR_ERRORS });
};

// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@mui/material';

import { Text } from '../../atoms';
import { DialogCustom, InputField } from '../../molecules';
import ConfirmWidget from '../../organisms/ConfirmWidget/ConfirmWidget';
import { useAxios, useTr } from '../../../hooks';
import {
  ZONE_API_URL,
  VISION_API_URL,
  axiosGet,
  axiosPatch,
} from '../../../apiRequests';
import VisionSvg from '../../../assets/VisionSvg';
import './VisionManageForm.sass';

const ContainerStyled = styled.div`
  border: ${({ zoneColor }) => `2px dashed ${zoneColor}`};
`;

const VisionManageForm = ({
  handleDeleteVision,
  responseErrorDel,
  visionText,
  zoneId,
}) => {
  const {
    zone,
    zoneMode,
  } = useSelector(({ zoneReducer }) => zoneReducer);

  const t153 = useTr(153);
  const t154 = useTr(154);
  const t155 = useTr(155);
  const t156 = useTr(156);
  const t157 = useTr(157);
  const t158 = useTr(158);
  const t159 = useTr(159);
  const t160 = useTr(160);

  const [newVisionText, setNewVisionText] = useState(visionText);
  const [editedText, setEditedText] = useState(newVisionText);
  const [visionTextChanged, setVisionTextChanged] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteWidgetOpened, setIsDeleteWidgetOpened] = useState(false);

  // fetch updated vision text after edit
  const {
    axiosFetch: axiosFetchGet,
    isLoading: isLoadingGet,
    responseData,
  } = useAxios({
    method: axiosGet,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}`,
    data: { zone_id: parseFloat(zoneId) },
  });

  useEffect(() => {
    if (responseData) {
      setNewVisionText(responseData.data.text);
    }
  }, [responseData]);

  const saveVisionSuccessCallback = () => {
    setIsDialogOpen(false);
    axiosFetchGet();
  };

  // edit vision text
  const {
    axiosFetch,
    isLoading,
    responseError,
  } = useAxios({
    method: axiosPatch,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}`,
    data: {
      zone_id: parseFloat(zoneId),
      text: editedText,
    },
    callbackSuccess: saveVisionSuccessCallback,
  });

  const handleChange = (e) => {
    setEditedText(e.target.value);
    setVisionTextChanged(true);
  };

  const handleSaveVisionText = (e) => {
    e.preventDefault();
    axiosFetch();
  };

  const handleCancelClick = () => {
    isDeleteWidgetOpened(false);
    setNewVisionText(newVisionText);
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setNewVisionText(newVisionText);
    setIsDeleteWidgetOpened(false);
  };

  const loading = isLoading || isLoadingGet;
  let visionTextAdded = newVisionText.length > 0;

  return (
    <ContainerStyled zoneColor={visionTextAdded ? zone.color : 'transparent'} className={`vision-manage-form__container${visionTextAdded ? ' vision-manage-form__container--content' : ''}`}>
      <VisionSvg zoneColor={zone.color} className="vision-manage-form__svg" />
      <Text info={!visionTextAdded || null} className="vision-manage-form__text">{newVisionText || t153}</Text>
      {zoneMode === 'RW' && (
        <DialogCustom
          onClose={handleClose}
          onOpen={handleOpen}
          onCancel={handleCancelClick}
          openDialogButton={!visionTextAdded}
          openDialogButtonIcon={visionTextAdded}
          openIcon="cogs"
          openDialogButtonText={!visionTextAdded ? t154 : t155}
          dialogTitle={t154}
          open={isDialogOpen}
          openBtnClassName="vision-manage-form__btn"
          disabled={loading}
          btnIconClassName="vision-manage-form__btn-icon"
        >
          {isDeleteWidgetOpened ? (
            <>
              <ConfirmWidget
                confirmationBtnText={t156}
                onConfirm={handleDeleteVision}
                onCancel={() => setIsDeleteWidgetOpened(false)}
                confirmationText={t157}
                confirmationInfo={t158}
              />
              {responseErrorDel}
            </>
          ) : (
            <form id="visionForm">
              <InputField
                autoFocus
                disabled={loading}
                error={responseError && (responseError.detail || responseError.text)}
                inputId="visionInput"
                label={t159}
                name="visionText"
                onChange={handleChange}
                value={editedText}
                maxLength={500}
                textarea
              />
              <Button
                color="primary"
                disabled={loading || !visionTextChanged || newVisionText === editedText}
                fullWidth
                onClick={handleSaveVisionText}
                type="submit"
                variant="contained"
              >
                {t160}
              </Button>
              {visionTextAdded && <Button onClick={() => setIsDeleteWidgetOpened(true)} color="error" fullWidth>{t156}</Button>}
            </form>
          )}
        </DialogCustom>
      )}
    </ContainerStyled>
  );
};

VisionManageForm.propTypes = {
  handleDeleteVision: PropTypes.func,
  responseErrorDel: PropTypes.element,
  visionText: PropTypes.string,
  zoneId: PropTypes.string,
};

VisionManageForm.defaultProps = {
  handleDeleteVision: null,
  responseErrorDel: null,
  visionText: '',
  zoneId: null,
};

export default VisionManageForm;

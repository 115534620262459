// Add or edit Sphere form
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Heading, Loader, Text } from '../../atoms';
import { Pagination, SubObjectivesListItem } from '../../molecules';

import {
  subObjectivesListGetAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

import './SubObjectivesList.sass';

const SubObjectivesList = ({ objectiveId, objectiveDone }) => {
  const dispatch = useDispatch();
  const t136 = useTr(136);

  useEffect(() => {
    dispatch(subObjectivesListGetAction(objectiveId));
    dispatch(subObjectivesListGetAction(objectiveId, true, true));
  }, [dispatch, objectiveId]);

  const subObjectivesList = useSelector(({ subObjectiveReducer }) => subObjectiveReducer.subObjectivesList[objectiveId]);
  const subObjectivesListDone = useSelector(({ subObjectiveReducer }) => subObjectiveReducer.subObjectivesListDone[objectiveId]);

  const {
    subObjectivesListNext,
    subObjectivesListPrevious,
    subObjectivesListDoneNext,
    subObjectivesListDonePrevious,
    errorsSubObjectivesListGet,
    errorsSubObjectivesListDoneGet,
    subObjectiveLoading,
  } = useSelector(({ subObjectiveReducer }) => subObjectiveReducer);

  const handleClickPagination = (e, page, done = null) => {
    e.preventDefault();
    if (page) {
      dispatch(subObjectivesListGetAction(objectiveId, done, null, page));
    }
  };

  return (
    <>
      {subObjectiveLoading && <Loader view />}
      {subObjectivesList && subObjectivesList.length > 0 && (
        <>
          <ul>
            {subObjectivesList.map((subObjective) => (
              <SubObjectivesListItem
                key={subObjective.id}
                subObjectiveId={subObjective.id}
                name={subObjective.name}
                endDate={subObjective.end_date}
                objectiveId={objectiveId}
                objectiveDone={objectiveDone}
              />
            ))}
          </ul>
          {errorsSubObjectivesListGet && <Text error>{errorsSubObjectivesListGet}</Text>}
          {subObjectivesListNext && subObjectivesListPrevious && (subObjectivesListNext[objectiveId] || subObjectivesListPrevious[objectiveId]) && (
            <Pagination
              isNext={subObjectivesListNext[objectiveId]}
              isPrev={subObjectivesListPrevious[objectiveId]}
              nextButtonText="Następne"
              prevButtonText="Poprzednie"
              onClickPrev={(e) => handleClickPagination(e, subObjectivesListPrevious[objectiveId])}
              onClickNext={(e) => handleClickPagination(e, subObjectivesListNext[objectiveId])}
            />
          )}
        </>
      )}
      {subObjectivesListDone && subObjectivesListDone.length > 0 && (
        <>
          <Heading className="subobjective-list-item__heading">{t136}</Heading>
          <ul>
            {subObjectivesListDone.map((subObjective) => (
              <SubObjectivesListItem
                key={subObjective.id}
                subObjectiveId={subObjective.id}
                name={subObjective.name}
                endDate={subObjective.end_date}
                done
                objectiveId={objectiveId}
              />
            ))}
          </ul>
          {errorsSubObjectivesListDoneGet && <Text error>{errorsSubObjectivesListDoneGet}</Text>}
          {subObjectivesListDoneNext && subObjectivesListDonePrevious && (subObjectivesListDoneNext[objectiveId] || subObjectivesListDonePrevious[objectiveId]) && (
            <Pagination
              isNext={subObjectivesListDoneNext[objectiveId]}
              isPrev={subObjectivesListDonePrevious[objectiveId]}
              onClickPrev={(e) => handleClickPagination(e, subObjectivesListDonePrevious[objectiveId], true)}
              onClickNext={(e) => handleClickPagination(e, subObjectivesListDoneNext[objectiveId], true)}
              nextButtonText="Następne"
              prevButtonText="Poprzednie"
            />
          )}
        </>
      )}
    </>
  );
};

SubObjectivesList.propTypes = {
  objectiveDone: PropTypes.bool,
  objectiveId: PropTypes.number,
};

SubObjectivesList.defaultProps = {
  objectiveDone: false,
  objectiveId: null,
};

export default SubObjectivesList;

import {
  ZONE_MANAGE_FAILURE,
  ZONE_MANAGE_REQUEST,
  ZONE_MANAGE_SUCCESS,
  ZONE_CLEAR_ERRORS,
  ZONE_CLEAR_DATA,
  NEW_ZONE_ADD_REQUEST,
  NEW_ZONE_ADD_SUCCESS,
  NEW_ZONE_ADD_FAILURE,
} from '../actions/actionTypes';
import { matchZoneColorGradient } from '../helpers';

const newZone = {
  id: null,
  name: null,
  color: null,
  colorGradient: null,
  sphere: null,
  is_active: true,
  progress: 0,
};

// old
const zone = {
  id: null,
  name: null,
  color: null,
  colorGradient: null,
  sphere: null,
  is_active: true,
  progress: 0,
};

const initialState = {
  newZone,
  newErrorsZoneAdd: null,
  newZoneAddLoading: null,
  // old
  zone,
  zoneMode: null,
  errorsZoneAdd: null,
  errorsZoneGetDel: null,
  zoneLoading: false,
};

export const zoneReducer = (state = initialState, action) => {
  const {
    newZoneData,
    newErrors,
    type,
    // old:
    errorsZoneGetDel,
    zoneData,
    zoneMode,
  } = action;

  switch (type) {
    case NEW_ZONE_ADD_REQUEST:
      return {
        ...state,
        newErrorsZoneAdd: null,
        newZoneAddLoading: true,
      };
    case NEW_ZONE_ADD_SUCCESS:
      return {
        ...state,
        newZoneData,
        colorGradient: matchZoneColorGradient(newZoneData.color),
        newZoneAddLoading: false,
      };
    case NEW_ZONE_ADD_FAILURE:
      return {
        ...state,
        newErrorsZoneAdd: {
          sphere: newErrors.sphere || null,
          name: newErrors.name || null,
          color: newErrors.color || null,
          nonField: newErrors.nonField || null,
        },
        newZoneAddLoading: false,
      };
    // old
    case ZONE_MANAGE_REQUEST:
      return {
        ...state,
        errorsZoneAdd: null,
        zoneLoading: true,
      };
    case ZONE_MANAGE_SUCCESS:
      return {
        ...state,
        zone: { ...zoneData },
        colorGradient: matchZoneColorGradient(zoneData.color),
        zoneMode,
        zoneLoading: false,
      };
    case ZONE_MANAGE_FAILURE:
      return {
        ...state,
        errorsZoneGetDel,
        zoneLoading: false,
      };
    case ZONE_CLEAR_ERRORS:
      return {
        ...state,
        newErrorsZoneAdd: null,
        errorsZoneGetDel: null,
      };
    case ZONE_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

import {
  SHARED_ZONES_LIST_GET_REQUEST,
  SHARED_ZONES_LIST_MY_GET_FAILURE,
  SHARED_ZONES_LIST_MY_GET_SUCCESS,
  SHARED_ZONES_LIST_TO_ME_GET_FAILURE,
  SHARED_ZONES_LIST_TO_ME_GET_SUCCESS,
  SHARED_ZONE_ADD_FAILURE,
  SHARED_ZONE_ADD_REQUEST,
  SHARED_ZONE_ADD_SUCCESS,
  SHARED_ZONE_GET_DEL_ACCEPT_FAILURE,
  SHARED_ZONE_GET_DEL_ACCEPT_REQUEST,
  SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS,
  SHARED_ZONE_CLEAR_ERRORS,
} from '../actions/actionTypes';

const sharedZone = {
  id: null,
  zoneId: null,
  zone: {},
  user: {},
  email: null,
  accepted: false,
  is_active: true,
  expire_at: null,
};

const initialState = {
  sharedZone,
  sharedZonesCountMy: 0,
  sharedZonesCountToMe: 0,
  sharedZonesListMy: [],
  sharedZonesListToMe: [],
  errorsSharedZoneAdd: null,
  errorsSharedZoneGetDelAccept: null,
  errorsSharedZonesListMyGet: null,
  errorsSharedZonesListToMeGet: null,
  sharedZonesListMyNext: null,
  sharedZonesListMyPrevious: null,
  sharedZonesListToMeNext: null,
  sharedZonesListToMePrevious: null,
  sharedZoneLoading: false,
};

export const sharedZoneReducer = (state = initialState, action) => {
  const {
    errors,
    errorsSharedZonesListMyGet,
    errorsSharedZonesListToMeGet,
    errorsSharedZoneGetDelAccept,
    sharedZoneData,
    sharedZonesListMy,
    sharedZonesListToMe,
    sharedZonesCountMy,
    sharedZonesCountToMe,
    sharedZonesListMyNext,
    sharedZonesListMyPrevious,
    sharedZonesListToMeNext,
    sharedZonesListToMePrevious,
    type,
  } = action;

  switch (type) {
    case SHARED_ZONES_LIST_GET_REQUEST:
      return {
        ...state,
        errorsSharedZonesListMyGet: null,
        errorsSharedZonesListToMeGet: null,
        sharedZoneLoading: true,
      };
    case SHARED_ZONES_LIST_MY_GET_SUCCESS:
      return {
        ...state,
        sharedZonesListMy,
        sharedZonesCountMy,
        sharedZonesListMyNext,
        sharedZonesListMyPrevious,
        sharedZoneLoading: false,
      };
    case SHARED_ZONES_LIST_TO_ME_GET_SUCCESS:
      return {
        ...state,
        sharedZonesListToMe,
        sharedZonesCountToMe,
        sharedZonesListToMeNext,
        sharedZonesListToMePrevious,
        sharedZoneLoading: false,
      };
    case SHARED_ZONES_LIST_MY_GET_FAILURE:
      return {
        ...state,
        errorsSharedZonesListMyGet,
        sharedZoneLoading: false,
      };
    case SHARED_ZONES_LIST_TO_ME_GET_FAILURE:
      return {
        ...state,
        errorsSharedZonesListToMeGet,
        sharedZoneLoading: false,
      };
    case SHARED_ZONE_ADD_REQUEST:
      return {
        ...state,
        errorsSharedZoneAdd: null,
        sharedZoneLoading: true,
      };
    case SHARED_ZONE_ADD_SUCCESS:
      return {
        ...state,
        sharedZoneLoading: false,
      };
    case SHARED_ZONE_ADD_FAILURE:
      return {
        ...state,
        errorsSharedZoneAdd: {
          zone: errors.zone || null,
          email: errors.email || null,
          nonField: errors.nonField || null,
        },
        sharedZoneLoading: false,
      };
    case SHARED_ZONE_GET_DEL_ACCEPT_REQUEST:
      return {
        ...state,
        errorsSharedZoneGetDelAccept: null,
        sharedZoneLoading: true,
      };
    case SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS:
      return {
        ...state,
        sharedZone: { ...sharedZoneData },
        sharedZoneLoading: false,
      };
    case SHARED_ZONE_GET_DEL_ACCEPT_FAILURE:
      return {
        ...state,
        errorsSharedZoneGetDelAccept,
        sharedZoneLoading: false,
      };
    case SHARED_ZONE_CLEAR_ERRORS:
      return {
        ...state,
        errorsSharedZoneAdd: null,
        errorsSharedZoneGetDelAccept: null,
        errorsSharedZonesListMyGet: null,
      };
    default:
      return state;
  }
};

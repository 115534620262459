import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { routes } from '../../routes';
import { Loader } from '../atoms';

const PrivateRoute = ({
  children,
  ...otherProps
}) => {
  const { user, userInitialized } = useSelector(({ newUserReducer }) => newUserReducer);
  const { is_authenticated: isAuthenticated } = user;

  return (
    <Route
      {...otherProps}
      render={({ location }) => (
        userInitialized
          ? isAuthenticated
            ? children
            : <Redirect to={{ pathname: routes.LOGIN_URL, state: { from: location } }} />
          : <Loader view />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.element,
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;

import moment from 'moment';

export const globalDateFormat = 'YYYY-MM-DD';
export const globalDateTimeFormat = 'YYYY-MM-DD hh:mm';
export const formatDate = (date) => moment(date).format(globalDateFormat);
export const formatDateTime = (date) => moment(date).format(globalDateTimeFormat);

export const sharedZonesListData = (sharedZonesList, id) => sharedZonesList.filter((z) => z.zone.id === parseInt(id, 10));

export const rangeArray = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

// Sample menuItems:

// const menuItems = [
//   {
//     linkTo: '11111',
//     name: 'dzienne akcje',
//     badge: true,
//     badgeColor: 'secondary',
//     badgeContent: null,
//     icon: 'circle',
//     isActive: true,
//   },
//   {
//     linkTo: '11111',
//     name: 'Archiwum sfer',
//     badge: true,
//     badgeColor: 'secondary',
//     badgeContent: '999',
//     icon: 'circle',
//     isActive: false,
//   },
// ];

export function MenuItem(
  linkTo,
  name,
  badge,
  badgeColor,
  badgeContent,
  icon,
  isActive,
) {
  this.linkTo = linkTo;
  this.name = name;
  this.badge = badge;
  this.badgeColor = badgeColor;
  this.badgeContent = badgeContent;
  this.icon = icon;
  this.isActive = isActive;
}

export const ZONE_COLOR_DICT = [
  {
    main: '#9F218C',
    gradient: '#DC1FC0',
  },
  {
    main: '#ED2024',
    gradient: '#FFA848',
  },
  {
    main: '#F15722',
    gradient: '#F99A1D',
  },
  {
    main: '#FCB317',
    gradient: '#FED037',
  },
  {
    main: '#3BB24A',
    gradient: '#88C33F',
  },
  {
    main: '#07AEC4',
    gradient: '#2CB478',
  },
  {
    main: '#483F90',
    gradient: '#5444D2',
  },
  {
    main: '#f05454',
    gradient: '#e09307',
  },
  {
    main: '#06113c',
    gradient: '#2e50d6',
  },
  {
    main: '#145374',
    gradient: '#5588a3',
  },
  {
    main: '#3e31ae',
    gradient: '#4aa9af',
  },
  {
    main: '#c689c6',
    gradient: '#e8a0bf',
  },
  {
    main: '#937dc2',
    gradient: '#c689c6',
  },
  {
    main: '#53d397',
    gradient: '#17f7c7',
  },
  {
    main: '#086972',
    gradient: '#01a9b4',
  },
  {
    main: '#c6fb39',
    gradient: '#f2fa5a',
  },
];

export const matchZoneColorGradient = (color) => {
  const machedColorPair = ZONE_COLOR_DICT.filter((colorPair) => colorPair.main === color);
  if (machedColorPair.length === 0) {
    return color;
  }

  return machedColorPair[0].gradient;
};

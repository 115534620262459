import {
  FEEDBACK_CLEAR_ERRORS,
  FEEDBACK_LIST_GET_FAILURE,
  FEEDBACK_LIST_GET_REQUEST,
  FEEDBACK_LIST_GET_SUCCESS,
  FEEDBACK_ADD_FAILURE,
  FEEDBACK_ADD_REQUEST,
  FEEDBACK_ADD_SUCCESS,
  FEEDBACK_UNREAD_COUNT_FAILURE,
  FEEDBACK_UNREAD_COUNT_REQUEST,
  FEEDBACK_UNREAD_COUNT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  errorsFeedbackListGet: null,
  errorsFeedbackUnreadGet: null,
  errorsFeedbackAdd: null,
  feedbackCount: null,
  feedbackCreatedAt: null,
  feedbackFromUser: null,
  feedbackList: null,
  feedbackListNext: null,
  feedbackListPrevious: null,
  feedbackLoading: false,
  feedbackReadAt: null,
  feedbackText: null,
  feedbackUnreadCount: 0,
};

export const feedbackReducer = (state = initialState, action) => {
  const {
    errorsFeedbackListGet,
    errorsFeedbackUnreadGet,
    errorsFeedbackAdd,
    feedbackCount,
    feedbackCreatedAt,
    feedbackFromUser,
    feedbackList,
    feedbackListNext,
    feedbackListPrevious,
    feedbackReadAt,
    feedbackText,
    feedbackUnreadCount,
    type,
  } = action;

  switch (type) {
    case FEEDBACK_LIST_GET_REQUEST:
      return {
        ...state,
        errorsFeedbackListGet: null,
        feedbackLoading: true,
      };
    case FEEDBACK_LIST_GET_SUCCESS:
      return {
        ...state,
        feedbackList,
        feedbackCount,
        feedbackListNext,
        feedbackListPrevious,
        feedbackLoading: false,
      };
    case FEEDBACK_LIST_GET_FAILURE:
      return {
        ...state,
        errorsFeedbackListGet,
        feedbackLoading: false,
      };
    case FEEDBACK_UNREAD_COUNT_REQUEST:
      return {
        ...state,
        errorsFeedbackUnreadGet: null,
        feedbackLoading: true,
      };
    case FEEDBACK_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        feedbackUnreadCount,
        feedbackLoading: false,
      };
    case FEEDBACK_UNREAD_COUNT_FAILURE:
      return {
        ...state,
        errorsFeedbackUnreadGet,
        feedbackLoading: false,
      };
    case FEEDBACK_ADD_REQUEST:
      return {
        ...state,
        errorsFeedbackAdd: null,
        feedbackLoading: true,
      };
    case FEEDBACK_ADD_SUCCESS:
      return {
        ...state,
        feedbackFromUser,
        feedbackText,
        feedbackCreatedAt,
        feedbackReadAt,
        feedbackLoading: false,
      };
    case FEEDBACK_ADD_FAILURE:
      return {
        ...state,
        errorsFeedbackAdd,
        feedbackLoading: false,
      };
    case FEEDBACK_CLEAR_ERRORS:
      return {
        ...state,
        errorsFeedbackAdd: null,
        errorsFeedbackListGet: null,
        errorsFeedbackUnreadGet: null,
      };
    default:
      return state;
  }
};

/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

import { Text } from '../Text/Text';
import './Progress.sass';

const TextStyled = styled(Text)`
  color: ${({ color }) => color};
`;

const CircularProgressIndicatorStyled = styled(CircularProgress)`
  color: ${({ color }) => color};
  opacity: .2;
`;

const CircularProgressValueStyled = styled(CircularProgress)`
  color: ${({ color }) => color};
`;

export const Progress = ({
  className,
  color,
  progressValue,
  size,
  title,
  ...props
}) => (
  <div className={`progress-own ${className || ''}`}>
    {title && <TextStyled colorCustom={color} size="xxs" weight="weightBold" className="progress-own__prog-name">{title}</TextStyled>}
    <CircularProgressIndicatorStyled style={{ color }} size={size} variant="determinate" value={100} className="progress-own__prog-indicator" {...props} />
    <CircularProgressValueStyled style={{ color }} size={size} variant="determinate" value={progressValue} {...props} />
  </div>
);

Progress.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  progressValue: PropTypes.number,
  size: PropTypes.string,
  title: PropTypes.string,
};

Progress.defaultProps = {
  className: null,
  color: '#fff',
  progressValue: null,
  size: '4rem',
  title: null,
};

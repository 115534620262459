import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { routes } from '../../routes';

const PublicRoute = ({
  children,
  restricted,
  location,
  ...otherProps
}) => {
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const { state } = location;
  return (
    // restricted === true => public but not for loggedIn.
    // restricted === false => public for everybody.
    <Route {...otherProps}>
      {user.is_authenticated && restricted ? <Redirect to={state && state.from ? state.from : routes.HOME_URL} /> : children}
    </Route>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.element.isRequired,
  restricted: PropTypes.bool,
  location: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

PublicRoute.defaultProps = {
  location: null,
  restricted: false,
};

export default withRouter(PublicRoute);

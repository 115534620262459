import {
  USER_CLEAR_ERRORS,
  USER_AUTHENTICATION_REQUEST,
  USER_UPDATE_DATA,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_EMAIL_FAILURE,
  USER_CHANGE_PROFILE_SUCCESS,
  USER_CHANGE_PROFILE_FAILURE,
  USER_CHANGE_LANGUAGE_SUCCESS,
  USER_CHANGE_LANGUAGE_FAILURE,
} from '../actions/actionTypes';

const user = {
  avatar_url: null,
  is_authenticated: false,
  email: null,
  email_confirmed: false,
  id: null,
  marketing_accepted: false,
  language: null,
  username: '',
  is_pro: false,
  is_business: false,
};

const initialState = {
  user,
  userInitialized: false,
  emailSent: false,
  loginFormErrors: {},
  passResetFormErrors: {},
  registerFormErrors: {},
  setPasswordFormErrors: {},
  emailConfirmedErrors: {},
  changeProfileErrors: {},
  authProgressOn: false,
  changeLanguageErrors: {},
  backendConnectionError: false,
};

export const userReducer = (state = initialState, action) => {
  const { errors, type, userData } = action;

  switch (type) {
    case USER_UPDATE_DATA:
      return {
        ...state,
        user: { ...userData },
        authProgressOn: false,
      };
    case USER_AUTHENTICATION_REQUEST:
      return {
        ...state,
        authProgressOn: true,
      };
    case USER_CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        user: { ...userData },
        authProgressOn: false,
      };
    case USER_CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        emailConfirmedErrors: {
          nonField: errors.nonField || null,
        },
        authProgressOn: false,
      };
    case USER_CLEAR_ERRORS:
      return {
        ...state,
        loginFormErrors: {},
        passResetFormErrors: {},
        registerFormErrors: {},
        setPasswordFormErrors: {},
        emailConfirmedErrors: {},
        changeProfileErrors: {},
        changeLanguageErrors: {},
        backendConnectionError: false,
      };
    case USER_CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...userData },
        authProgressOn: false,
      };
    case USER_CHANGE_PROFILE_FAILURE:
      return {
        ...state,
        changeProfileErrors: {
          username: errors.username || null,
          avatarUrl: errors.avatarUrl || null,
          nonField: errors.nonField || null,
          marketingAccepted: errors.marketingAccepted || null,
        },
        authProgressOn: false,
      };
    case USER_CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: userData.language,
        authProgressOn: false,
      };
    case USER_CHANGE_LANGUAGE_FAILURE:
      return {
        ...state,
        changeLanguageErrors: {
          nonField: errors.nonField || null,
        },
        authProgressOn: false,
      };

    default:
      return state;
  }
};

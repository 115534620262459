import styled, { css } from 'styled-components';
import { media } from '../../../theme/GlobalVars';

export const Heading = styled.h2`
  position: relative;
  margin-bottom: 1.6rem;
  font-size: ${({ theme, bannerHeader, mainHeader }) => {
    if (bannerHeader) {
      return theme.fontSize.xl;
    }

    if (mainHeader) {
      return theme.fontSize.l;
    }

    return theme.fontSize.m;
  }};
  font-weight: ${({ bannerHeader, theme }) => (bannerHeader ? theme.weightExtraBold : theme.weightBold)};
  line-height: ${({ bannerHeader }) => (bannerHeader ? 1 : 1.3)};;
  z-index: 1;

  ${({ centered }) => (
    centered && css`
      margin: 0 auto 2rem;
      text-align: center;
    }`
  )};

  @media ${media.s} {
    font-size: ${({ theme, bannerHeader, mainHeader }) => {
    if (bannerHeader) {
      return theme.fontSize.xxl;
    }

    if (mainHeader) {
      return theme.fontSize.xl;
    }

    return theme.fontSize.l;
  }};
  }
`;

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPatch,
  OBJECTIVE_API_URL,
  SUB_OBJECTIVE_API_URL,
} from '../apiRequests';

import {
  SUB_OBJECTIVE_ADD_EDIT_FAILURE,
  SUB_OBJECTIVE_ADD_EDIT_REQUEST,
  SUB_OBJECTIVE_ADD_EDIT_SUCCESS,
  SUB_OBJECTIVES_LIST_GET_FAILURE,
  SUB_OBJECTIVES_LIST_GET_REQUEST,
  SUB_OBJECTIVES_LIST_GET_SUCCESS,
  SUB_OBJECTIVES_LIST_DONE_GET_FAILURE,
  SUB_OBJECTIVES_LIST_DONE_GET_REQUEST,
  SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS,
  SUB_OBJECTIVE_GET_DEL_REQUEST,
  SUB_OBJECTIVE_GET_DEL_SUCCESS,
  SUB_OBJECTIVE_GET_DEL_FAILURE,
  SUB_OBJECTIVE_DONE_REQUEST,
  SUB_OBJECTIVE_DONE_SUCCESS,
  SUB_OBJECTIVE_DONE_FAILURE,
  SUB_OBJECTIVE_CLEAR_ERRORS,
} from './actionTypes';

// SUB-OBJECTIVES LIST GET
export const subObjectivesListGetAction = (objectiveId, done = null, isActive = null, fetchUrl = `${OBJECTIVE_API_URL}/${objectiveId}/sub-objectives`) => (dispatch) => {
  dispatch({ type: done ? SUB_OBJECTIVES_LIST_DONE_GET_REQUEST : SUB_OBJECTIVES_LIST_GET_REQUEST });

  return axiosGet(
    fetchUrl,
    {
      objective_id: objectiveId,
      is_active: isActive,
      done,
    },
  ).then((response) => {
    const data = {
      type: done ? SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS : SUB_OBJECTIVES_LIST_GET_SUCCESS,
      objectiveId,
    };

    if (done) {
      data.subObjectivesResultsListDone = (done !== null) ? response.data.data.results : null;
      data.subObjectivesDoneCount = response.data.data.count;
      data.subObjectivesListDoneNext = response.data.data.next || null;
      data.subObjectivesListDonePrevious = response.data.data.previous || null;
    } else {
      data.subObjectivesResultsList = (done !== null) ? null : response.data.data.results;
      data.subObjectivesCount = response.data.data.count;
      data.subObjectivesListNext = response.data.data.next || null;
      data.subObjectivesListPrevious = response.data.data.previous || null;
    }

    dispatch(data);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        const errorData = {
          type: done ? SUB_OBJECTIVES_LIST_DONE_GET_FAILURE : SUB_OBJECTIVES_LIST_GET_FAILURE,
        };

        if (done) {
          errorData.errorsSubObjectivesListDoneGet = response.data ? response.data.data.detail : null;
        } else {
          errorData.errorsSubObjectivesListGet = response.data ? response.data.data.detail : null;
        }
        dispatch(errorData);
      } else {
        const errorData = {
          type: done ? SUB_OBJECTIVES_LIST_DONE_GET_FAILURE : SUB_OBJECTIVES_LIST_GET_FAILURE,
        };

        if (done) {
          errorData.errorsSubObjectivesListDoneGet = `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`;
        } else {
          errorData.errorsSubObjectivesListGet = `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`;
        }
        dispatch(errorData);
      }
    }
  });
};

// SINGLE SUB-OBJECTIVE ADD / EDIT
export const subObjectiveAddEditAction = (objectiveId, subObjectiveId = null, name, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: SUB_OBJECTIVE_ADD_EDIT_REQUEST });

  const method = subObjectiveId ? axiosPatch : axiosPost;

  return method(
    `${SUB_OBJECTIVE_API_URL}/${subObjectiveId || 'create'}`,
    {
      objective: objectiveId,
      sub_objective_id: subObjectiveId,
      name,
    },
  ).then(() => {
    dispatch({
      type: SUB_OBJECTIVE_ADD_EDIT_SUCCESS,
    });
    subObjectivesListGetAction(objectiveId)(dispatch);
    subObjectivesListGetAction(objectiveId, true)(dispatch);
    callbackSuccess();
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: SUB_OBJECTIVE_ADD_EDIT_FAILURE,
          errors: {
            objective: response.data.data.objective ? response.data.data.objective[0] : null,
            name: response.data.data.name ? response.data.data.name[0] : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors[0] : null,
          },
        });
      } else if (response.status === 403 || response.status === 404) {
        dispatch({
          type: SUB_OBJECTIVE_ADD_EDIT_FAILURE,
          errors: { nonField: response.data && response.data.data.detail },
        });
      } else {
        dispatch({
          type: SUB_OBJECTIVE_ADD_EDIT_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// SINGLE SUB-OBJECTIVE GET / DEL
export const subObjectiveGetDelAction = (subObjectiveId, objectiveId, callbackSuccess = null, subObjectiveDelete = false) => (dispatch) => {
  dispatch({ type: SUB_OBJECTIVE_GET_DEL_REQUEST });

  const method = subObjectiveDelete ? axiosDelete : axiosGet;

  return method(
    `${SUB_OBJECTIVE_API_URL}/${subObjectiveId}`,
    { sub_objective_id: subObjectiveId },
  ).then((response) => {
    dispatch({
      type: SUB_OBJECTIVE_GET_DEL_SUCCESS,
      subObjectiveData: response.data.data,
      subObjectiveMode: response.data.meta.mode,
    });
    subObjectivesListGetAction(objectiveId)(dispatch);
    subObjectivesListGetAction(objectiveId, true)(dispatch);
    callbackSuccess();
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: SUB_OBJECTIVE_GET_DEL_FAILURE,
          errorsSubObjectiveGetDel: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: SUB_OBJECTIVE_GET_DEL_FAILURE,
          errorsSubObjectiveGetDel: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// SINGLE SUB-OBJECTIVE DONE
export const subObjectiveDoneAction = (subObjectiveId, objectiveId, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: SUB_OBJECTIVE_DONE_REQUEST });

  return axiosPatch(
    `${SUB_OBJECTIVE_API_URL}/${subObjectiveId}/done`,
    { sub_objective_id: subObjectiveId },
  ).then((response) => {
    dispatch({
      type: SUB_OBJECTIVE_DONE_SUCCESS,
      subObjectiveData: response.data.data,
    });
    subObjectivesListGetAction(objectiveId)(dispatch);
    subObjectivesListGetAction(objectiveId, true)(dispatch);
    callbackSuccess();
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: SUB_OBJECTIVE_DONE_FAILURE,
          errorsSubObjectiveDone: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: SUB_OBJECTIVE_DONE_FAILURE,
          errorsSubObjectiveDone: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearSubObjectiveErrorsAction = () => (dispatch) => {
  dispatch({ type: SUB_OBJECTIVE_CLEAR_ERRORS });
};

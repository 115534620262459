import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@mui/material';

import {
  Background,
  Text,
  SrOnly,
} from '../components/atoms';
import { Card, InputField } from '../components/molecules';
import { useTr } from '../hooks';

const Wrapper = styled(Background)`
  position: relative;
  width: 100%;
  padding: 9.5rem 1.5rem 1.5rem;
`;

const AuthTemplate = ({
  authFormHeader,
  authFormSubHeader,
  buttonName,
  children,
  disabled,
  errorEmail,
  errorPassword,
  errorRepeatPassword,
  errorTos,
  errorMarketing,
  errorNonFiled,
  emailSetOnParent,
  onSubmit,
  isEmailField,
  isPasswordField,
  isRepeatPasswordField,
  isMarketingAcceptField,
  isTosAcceptField,
  userNameLabel,
}) => {
  const t141 = useTr(141);
  const t173 = useTr(173);
  const t174 = useTr(174);
  const t175 = useTr(175);
  const t176 = useTr(176);
  const t177 = useTr(177);
  const t178 = useTr(178);
  const t240 = useTr(240);

  const [email, setEmail] = useState(emailSetOnParent || '');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [tosAccepted, setTosAccept] = useState(false);
  const [marketingAccepted, setSpamAccept] = useState(false);

  const formErrors = errorEmail || errorPassword || errorRepeatPassword || errorTos || errorMarketing || errorNonFiled;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      email,
      password,
      repeatPassword,
      marketingAccepted,
      tosAccepted,
    });
  };

  return (
    <Wrapper>
      <Card
        cardType="blue"
        cardHeader={authFormHeader}
        cardSubHeader={authFormSubHeader}
      >
        <>
          <form onSubmit={handleSubmit}>
            {isEmailField && (
              <InputField
                autoCapitalize="off"
                error={errorEmail}
                inputId="emailInput"
                label={userNameLabel || t173}
                disabled={disabled}
                value={emailSetOnParent || email}
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            {isPasswordField && (
              <>
                <InputField
                  autoCapitalize="off"
                  error={errorPassword}
                  inputType="password"
                  inputId="passwordInput"
                  isPasswordInput
                  label={t174}
                  disabled={disabled}
                  value={password}
                  onChange={(e) => (setPassword(e.target.value))}
                />
                <Text info className="password-info">{t240}</Text>
              </>
            )}
            {isRepeatPasswordField && (
              <InputField
                autoCapitalize="off"
                error={errorRepeatPassword}
                inputType="password"
                inputId="passwordRepeatInput"
                isPasswordInput
                label={t175}
                disabled={disabled}
                value={repeatPassword}
                onChange={(e) => (setRepeatPassword(e.target.value))}
              />
            )}
            {isTosAcceptField && (
              <InputField
                error={errorTos}
                inputType="checkbox"
                inputId="tosAcceptedInput"
                label={t176}
                checked={tosAccepted}
                disabled={disabled}
                onChange={(e) => setTosAccept(e.target.checked)}
              />
            )}
            {isMarketingAcceptField && (
              <InputField
                error={errorMarketing}
                inputType="checkbox"
                inputId="marketingAcceptedInput"
                label={t177}
                checked={marketingAccepted}
                disabled={disabled}
                onChange={(e) => setSpamAccept(e.target.checked)}
              />
            )}
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              {buttonName || t141}
            </Button>
            {errorNonFiled || null}
            {formErrors && <SrOnly role="alert">{t178}</SrOnly>}
          </form>
          {children}
        </>
      </Card>
    </Wrapper>
  );
};

AuthTemplate.propTypes = {
  authFormHeader: PropTypes.string.isRequired,
  authFormSubHeader: PropTypes.string,
  buttonName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.element,
  disabled: PropTypes.bool,
  emailSetOnParent: PropTypes.string,
  errorEmail: PropTypes.string,
  errorPassword: PropTypes.string,
  errorRepeatPassword: PropTypes.string,
  errorTos: PropTypes.string,
  errorMarketing: PropTypes.string,
  errorNonFiled: PropTypes.element || PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isEmailField: PropTypes.bool,
  isPasswordField: PropTypes.bool,
  isRepeatPasswordField: PropTypes.bool,
  isMarketingAcceptField: PropTypes.bool,
  isTosAcceptField: PropTypes.bool,
  userNameLabel: PropTypes.string,
};

AuthTemplate.defaultProps = {
  authFormSubHeader: '',
  buttonName: '',
  children: null,
  disabled: false,
  emailSetOnParent: '',
  errorEmail: '',
  errorPassword: '',
  errorRepeatPassword: '',
  errorTos: '',
  errorMarketing: '',
  errorNonFiled: null,
  isEmailField: true,
  isPasswordField: true,
  isRepeatPasswordField: false,
  isMarketingAcceptField: false,
  isTosAcceptField: false,
  userNameLabel: null,
};

export default AuthTemplate;

// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { UploadImageWidget } from '../UploadImageWidget/UploadImageWidget';
import { VisionImgWidget } from '../VisionImgWidget/VisionImgWidget';
import { Loader, Text } from '../../atoms';
import { useAxios, useTr } from '../../../hooks';
import {
  ZONE_API_URL,
  VISION_API_URL,
  axiosGet,
  axiosPost,
} from '../../../apiRequests';
import './VisionManagePhotos.sass';

export const VisionManagePhotos = ({ photosList, zoneId }) => {
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);

  const [addedPhotoUrl, setAddedPhotoUrl] = useState(null);
  const [newPhotosList, setNewPhotosList] = useState(photosList);
  const t52 = useTr(52);
  const t54 = useTr(54);

  // fetch updated photosList
  const {
    axiosFetch: axiosFetchGet,
    isLoading: isLoadingGet,
    responseData,
  } = useAxios({
    method: axiosGet,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}`,
    data: { zone_id: parseFloat(zoneId) },
  });

  useEffect(() => {
    if (responseData) {
      setNewPhotosList(responseData.data.photos);
    }
  }, [responseData]);

  // Add photo
  const {
    axiosFetch: axiosFetchPost,
    isLoading,
    responseError: responseErrorPost,
  } = useAxios({
    method: axiosPost,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}/photo`,
    data: {
      zone_id: parseFloat(zoneId),
      photo_url: addedPhotoUrl,
    },
    callbackSuccess: () => axiosFetchGet(),
  });

  const handleGetImageUrl = (url) => {
    setAddedPhotoUrl(url);
  };

  useEffect(() => {
    if (addedPhotoUrl) {
      axiosFetchPost();
    }
  }, [addedPhotoUrl, setAddedPhotoUrl]);

  return (
    <>
      <ul className={`vision-manage-photos__list${(newPhotosList.length < 3) ? ' vision-manage-photos__list--no-grid' : ''}`}>
        {newPhotosList.length > 0 && (
          newPhotosList.map((photoUrl, idx) => (
            <li key={`key_${idx}`} className="vision-manage-photos__list-item">
              <VisionImgWidget
                zoneId={zoneId}
                photoUrl={photoUrl}
                updatePhotoList={() => axiosFetchGet()}
              />
              {responseErrorPost && <Text error>{responseErrorPost.detail}</Text>}
            </li>
          ))
        )}
        {zoneMode === 'RW' && (
          <UploadImageWidget
            addNextPhotoText={t54}
            className="vision-manage-photos__add-img"
            imgTitle={t52}
            onGetImageUrl={handleGetImageUrl}
          />
        )}
      </ul>
      {(isLoading || isLoadingGet) && <Loader />}
    </>
  );
};

VisionManagePhotos.propTypes = {
  photosList: PropTypes.arrayOf(PropTypes.string),
  zoneId: PropTypes.string,
};

VisionManagePhotos.defaultProps = {
  photosList: [],
  zoneId: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ButtonIcon } from '../../atoms';
import { useTr } from '../../../hooks';
import './DialogCustom.sass';

const Transition = (props, ref) => <Slide direction="up" ref={ref} {...props} />;

const Fade = React.forwardRef(Transition);

export const DialogCustom = ({
  btnVariant,
  btnIconClassName,
  children,
  dialogTitle,
  disabled,
  openIcon,
  onClose,
  onOpen,
  open,
  openBtnClassName,
  openDialogButton,
  openDialogButtonIcon,
  openDialogButtonText,
  ...props
}) => {
  const phoneWidth = useMediaQuery('(max-width:700px)');
  const t20 = useTr(20);
  const t21 = useTr(21);

  return (
    <div className={openBtnClassName}>
      {openDialogButton && (
        <Button
          variant={btnVariant}
          color="primary"
          onClick={onOpen}
          disabled={disabled}
        >
          {openDialogButtonText}
        </Button>
      )}
      {openDialogButtonIcon && (
        <ButtonIcon
          ariaLabel={openDialogButtonText}
          onClick={onOpen}
          title={openDialogButtonText || t21}
          icon={<FontAwesomeIcon icon={openIcon} />}
          disabled={disabled}
          btnClassName={btnIconClassName}
        />
      )}
      <Dialog
        fullScreen={phoneWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
        maxWidth="md"
        TransitionComponent={Fade}
        className="dialog-custom"
        {...props}
      >
        <div className="dialog-custom__close-btn">
          <IconButton aria-label={t20} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle id="alert-dialog-title" className="dialog-custom__header"><p className="dialog-custom__title">{dialogTitle}</p></DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogCustom.propTypes = {
  btnVariant: PropTypes.string,
  btnIconClassName: PropTypes.string,
  children: PropTypes.element.isRequired,
  dialogTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  openBtnClassName: PropTypes.string,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  openIcon: PropTypes.string,
  openDialogButton: PropTypes.bool,
  openDialogButtonIcon: PropTypes.bool,
  openDialogButtonText: PropTypes.string,
};

DialogCustom.defaultProps = {
  btnVariant: 'contained',
  btnIconClassName: null,
  dialogTitle: null,
  disabled: false,
  onClose: null,
  onOpen: null,
  open: false,
  openBtnClassName: null,
  openIcon: null,
  openDialogButton: false,
  openDialogButtonIcon: false,
  openDialogButtonText: '',
};

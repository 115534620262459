import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import HabitManageForm from '../HabitManageForm/HabitManageForm';
import { BorderedPart, Text } from '../../../atoms';
import HabitSvgPl from '../HabitSvgPl';
import HabitSvgEn from '../HabitSvgEn';
import SuccessSvg from '../../../../assets/SuccessSvg';
import { useTr } from '../../../../hooks';

import './HabitsListItem.sass';

const HabitsListItem = ({
  habitId,
  name,
  release,
  prize,
  isDeveloped,
  zoneId,
}) => {
  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);
  const language = useSelector(({ newUserReducer }) => newUserReducer.user.language);
  const t46 = useTr(46);
  const t70 = useTr(70);
  const t73 = useTr(73);
  const t74 = useTr(74);
  const t75 = useTr(75);
  const t76 = useTr(76);

  return (
    <BorderedPart className="habit-container">
      {isDeveloped && (
        <div className="habit-list-item__developed">
          <Text size="xxs" weight="weightBold" success className="habit-list-item__developed-text">{t76}</Text>
          <SuccessSvg className="habit-list-item__developed-svg" zoneColor={zoneColor} />
        </div>
      )}
      <div className={`habit-list-item${isDeveloped ? ' habit-list-item--done' : ''}`}>
        {language === 'en' ? <HabitSvgEn className="habit-list-item__svg" zoneColor={zoneColor} /> : <HabitSvgPl className="habit-list-item__svg" zoneColor={zoneColor} />}
        <div className="habit-list-item__info">
          <div className="habit-list-item__trigger">
            <Text size="xxs" weight="weightBold">{t73}</Text>
            <Text size="s">{release || '-'}</Text>
          </div>
          <div className="habit-list-item__habit">
            <Text size="xxs" weight="weightBold">{t74}</Text>
            <Text size="s" weight="weightBold">{name}</Text>
          </div>
          <div className="habit-list-item__trigger">
            <Text size="xxs" weight="weightBold">{t75}</Text>
            <Text size="s">{prize || '-'}</Text>
          </div>
        </div>
      </div>
      {!isDeveloped && zoneMode === 'RW' && (
        <HabitManageForm
          formTitle={t70}
          name={name}
          openDialogButtonText={t46}
          habitId={habitId}
          prize={prize}
          release={release}
          zoneId={zoneId}
        />
      )}
    </BorderedPart>
  );
};

HabitsListItem.propTypes = {
  habitId: PropTypes.number,
  name: PropTypes.string,
  release: PropTypes.string,
  prize: PropTypes.string,
  isDeveloped: PropTypes.bool,
  zoneId: PropTypes.string,
};

HabitsListItem.defaultProps = {
  habitId: null,
  name: '',
  release: '-',
  prize: '-',
  isDeveloped: false,
  zoneId: '',
};

export default HabitsListItem;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { userChangeProfileAction } from '../actions';
import {
  BorderedPart,
  Heading,
  Text,
} from '../components/atoms';
import {
  Avatar,
  ConfirmEmailInfo,
  InputField,
  UploadImageWidget,
} from '../components/molecules';
import { useTr } from '../hooks';

const ProfileSettingView = () => {
  const authProgressOn = useSelector(({ userReducer }) => userReducer.authProgressOn);
  const changeProfileErrors = useSelector(({ userReducer }) => userReducer.changeProfileErrors);
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const dispatch = useDispatch();

  const t5 = useTr(5);
  const t144 = useTr(144);
  const t177 = useTr(177);
  const t201 = useTr(201);
  const t204 = useTr(204);
  const t227 = useTr(227);
  const t228 = useTr(228);
  const t229 = useTr(229);
  const t230 = useTr(230);
  const t231 = useTr(231);

  const {
    avatar_url: avatarUrl,
    email,
    email_confirmed: emailConfirmed,
    marketing_accepted: marketingAccepted,
    username,
  } = user;

  const {
    avatarUrl: avatarUrlError,
    username: usernameError,
    nonField: nonFieldError,
    marketingAccepted: marketingAcceptedError,
  } = changeProfileErrors;

  const [newUsername, setNewUsername] = useState(username);
  const [newMarketingAccepted, setNewMarketingAccepted] = useState(marketingAccepted);
  const [newAvatarUrl, setNewAvatarUrl] = useState(avatarUrl);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAvatarChanged, setIsAvatarChanged] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);

  const callbackSuccess = () => {
    setIsSuccess(true);
    setIsFormChanged(false);
    setIsNameChanged(false);
    setIsAvatarChanged(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userChangeProfileAction(newUsername, newAvatarUrl, newMarketingAccepted, callbackSuccess));
  };

  const handleGetImageUrl = (url) => {
    setNewAvatarUrl(url);
    setIsAvatarChanged(true);
    setIsSuccess(false);
  };

  const cancelChangeAvatar = () => {
    setNewAvatarUrl(avatarUrl);
    setIsAvatarChanged(false);
  };

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
    setIsNameChanged(true);
    setIsSuccess(false);
  };

  const cancelChangeName = () => {
    setNewUsername(username);
    setIsNameChanged(false);
  };

  const handleMarketingChange = (e) => {
    setNewMarketingAccepted(e.target.checked);
    setIsFormChanged(true);
    setIsSuccess(false);
  };

  return (
    <>
      {!emailConfirmed && <ConfirmEmailInfo disabled={authProgressOn} />}
      <form onSubmit={handleSubmit}>
        <BorderedPart>
          <Avatar
            url={newAvatarUrl}
            username={newUsername}
            size="30vw"
          />
          <UploadImageWidget
            imgTitle="avatar"
            imageUploadText={newAvatarUrl ? t227 : t228}
            imgChangeText={t227}
            onGetImageUrl={handleGetImageUrl}
          />
          {avatarUrlError && <Text error>{avatarUrlError}</Text>}
          {isAvatarChanged && <Button onClick={cancelChangeAvatar} width="8rem" className="account-setting-view__cancel-avatar-button">{t5}</Button>}
        </BorderedPart>
        <BorderedPart>
          <div className="account-setting-view__header-flex">
            <Heading className="account-setting-view__header">{t144}</Heading>
            <div className="account-setting-view__present-info">
              <Text size="xs" color="colorGreyDark" weight="weightBold">{t201} {username} </Text>
              <Text size="xs" color="colorGreyDark" weight="weightBold">E-mail: {email} </Text>
            </div>
          </div>
          <InputField
            autoCapitalize="off"
            disabled={authProgressOn}
            error={usernameError}
            inputId="usernameInput"
            label={t229}
            name="username"
            onChange={handleUsernameChange}
            value={newUsername}
          />
          {isNameChanged && <Button onClick={cancelChangeName} className="account-setting-view__cancel-button" width="8rem">{t5}</Button>}
        </BorderedPart>
        <BorderedPart>
          <Heading>{t230}</Heading>
          <InputField
            disabled={authProgressOn}
            checked={newMarketingAccepted}
            error={marketingAcceptedError}
            inputId="marketingAcceptedInput"
            inputType="checkbox"
            label={t177}
            name="marketingAccepted"
            onChange={handleMarketingChange}
          />
        </BorderedPart>
        <Button
          color={isSuccess ? 'success' : 'primary'}
          disabled={authProgressOn || (!isFormChanged && !isAvatarChanged && !isNameChanged)}
          fullWidth
          type="submit"
          variant="contained"
        >
          {t231}
        </Button>
        {nonFieldError && <Text error>{nonFieldError}</Text>}
        {isSuccess && <Text success>{t204}</Text>}
      </form>
    </>
  );
};

export default ProfileSettingView;

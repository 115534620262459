// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Loader, Text } from '../../atoms';
import { Pagination, FeedbackListItem } from '../../molecules';

import {
  feedbackListGetAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

import './FeedbackList.sass';

const FeedbackList = ({ zoneId }) => {
  const dispatch = useDispatch();
  const {
    feedbackList,
    feedbackLoading,
    feedbackListNext,
    feedbackListPrevious,
  } = useSelector(({ feedbackReducer }) => feedbackReducer);
  const t61 = useTr(61);
  const t62 = useTr(62);
  const t63 = useTr(63);
  const t64 = useTr(64);
  const t65 = useTr(65);
  const t66 = useTr(66);

  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);
  const sharedZonesListToMe = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.sharedZonesListToMe);

  const [sharedZoneUserToMe, setSharedZoneUserToMe] = useState(null);

  useEffect(() => {
    if (sharedZonesListToMe.length > 0 && zoneMode === 'RO') {
      setSharedZoneUserToMe(sharedZonesListToMe?.filter((el) => el.zone.id === parseFloat(zoneId))[0].from_user);
    }
  }, [sharedZonesListToMe, zoneId]);

  useEffect(() => {
    dispatch(feedbackListGetAction(zoneId));
  }, [dispatch, zoneId]);

  const handleClickNext = (e) => {
    e.preventDefault();
    if (feedbackListNext) {
      dispatch(feedbackListGetAction(zoneId, feedbackListNext));
    }
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    if (feedbackListPrevious) {
      dispatch(feedbackListGetAction(zoneId, feedbackListPrevious));
    }
  };

  return (
    <section className="feedback-list">
      {feedbackList && feedbackList.length === 0 ? <Text info>{t61}.</Text> : (
        <>
          {feedbackLoading && <Loader view />}
          {!feedbackLoading && feedbackList && (
            <div className="feedback-list__content">
              {(feedbackListNext || feedbackListPrevious) && (
                <Pagination
                  className="feedback-list__pagination"
                  isNext={feedbackListNext !== null}
                  isPrev={feedbackListPrevious !== null}
                  // reversed buttons
                  onClickPrev={handleClickPrev}
                  onClickNext={handleClickNext}
                  nextButtonText={t62}
                  prevButtonText={t63}
                />
              )}
              <ul className="feedback-list__list">
                {feedbackList.length && feedbackList.map((feed) => (
                  <FeedbackListItem
                    key={feed.id}
                    createdAt={feed.created_at}
                    readAt={feed.read_at}
                    text={feed.text}
                    userId={feed.user.id}
                    userName={feed.user.username}
                  />
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      <div className="feedback-list__info">
        <Text colorCustom="#5d5d5d" info>{t64} {zoneMode === 'RO' ? `${t65} ${sharedZoneUserToMe && sharedZoneUserToMe.username}` : t66}</Text>
      </div>
    </section>
  );
};

FeedbackList.propTypes = {
  zoneId: PropTypes.string,
};

FeedbackList.defaultProps = {
  zoneId: '',
};

export default FeedbackList;

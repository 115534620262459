import React, { useState } from 'react';
import { Button } from '@mui/material';

import {
  BorderedPart,
  Heading,
  Text,
} from '../components/atoms';
import { InputField } from '../components/molecules';
import { useAxios, useTr } from '../hooks';
import { ACCOUNTS_API_URL, axiosPost } from '../apiRequests';

const PasswordSettingView = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);

  const t217 = useTr(217);
  const t218 = useTr(218);
  const t219 = useTr(219);
  const t220 = useTr(220);
  const t221 = useTr(221);
  const t222 = useTr(222);
  const t240 = useTr(240);

  const {
    axiosFetch,
    isLoading,
    responseError,
    successInfo,
  } = useAxios({
    method: axiosPost,
    url: `${ACCOUNTS_API_URL}/user/change-password`,
    data: {
      old_password: oldPassword,
      new_password_1: newPassword1,
      new_password_2: newPassword2,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosFetch();
    setOldPassword('');
    setNewPassword1('');
    setNewPassword2('');
    setIsFormChanged(false);
  };

  const handleChangeOldPass = (e) => {
    let val = e.target.value;
    setOldPassword(val);
    setIsFormChanged(val.length);
  };

  return (
    <BorderedPart className="bordered-part--full-padding">
      <Heading>{t217}</Heading>
      <form onSubmit={handleSubmit}>
        <InputField
          autoCapitalize="off"
          disabled={isLoading}
          error={responseError && responseError.old_password ? responseError.old_password[0] : null}
          inputId="oldPasswordInput"
          inputType="password"
          label={t218}
          name="oldPasswordInput"
          onChange={handleChangeOldPass}
          isPasswordInput
          value={oldPassword}
        />
        <InputField
          autoCapitalize="off"
          disabled={isLoading}
          error={responseError && responseError.new_password1 ? responseError.new_password1[0] : null}
          inputId="newPassword1Input"
          inputType="password"
          label={t219}
          name="newPassword1Input"
          onChange={(e) => setNewPassword1(e.target.value)}
          isPasswordInput
          value={newPassword1}
        />
        <Text info className="password-info">{t240}</Text>
        <InputField
          autoCapitalize="off"
          disabled={isLoading}
          error={responseError && responseError.new_password2 ? responseError.new_password2[0] : null}
          inputId="newPassword2Input"
          inputType="password"
          label={t220}
          name="newPassword2Input"
          onChange={(e) => setNewPassword2(e.target.value)}
          isPasswordInput
          value={newPassword2}
        />
        <Button
          color={successInfo ? 'success' : 'primary'}
          disabled={isLoading || !isFormChanged}
          fullWidth
          type="submit"
          variant="contained"
        >
          {t221}
        </Button>
        {responseError && responseError.non_field_errors ? <Text error>{responseError.non_field_errors[0]}</Text> : null}

        {responseError && responseError.detail ? <Text error>{responseError.detail}</Text> : null}
      </form>
      {successInfo && <Text success>{t222}</Text>}
    </BorderedPart>
  );
};

export default PasswordSettingView;

import axios from 'axios';

const axiosRequest = axios.create({
  timeout: 5000,
  withCredentials: true,
  responseType: 'json',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.test.growumaster.com' : 'http://api.umaster.test:5555',
});

export const GLOBAL_GET_ERROR = 'There was temporary problem with getting the data. Please, try again latesr.';
export const GLOBAL_POST_ERROR = 'There was temporary problem with sending data to the server. Please, try again later.';
const GLOBAL_DELETE_ERROR = 'There was temporary problem with deleting data. Please, try again later.';

// API URLs:
export const ACCOUNTS_API_URL = '/accounts';
export const ACTIONS_API_URL = '/action';
export const PICTURES_UPLOAD_API_URL = '/pictures/upload';
export const FEEDBACK_API_URL = '/feedback';
export const HABIT_API_URL = '/habit';
export const OBJECTIVE_API_URL = '/objective';
export const SPHERE_API_URL = '/sphere';
export const SHARED_ZONE_API_URL = '/shared-zone';
export const SUB_OBJECTIVE_API_URL = '/sub-objective';
export const VISION_API_URL = '/vision';
export const ZONE_API_URL = '/zone';

// API request for redux:
export const axiosDelete = (url, data) => axiosRequest.delete(url, data);
export const axiosGet = (url, data) => axiosRequest.get(url, data);
export const axiosPost = (url, data) => axiosRequest.post(url, data);
export const axiosPatch = (url, data) => axiosRequest.patch(url, data);
export const axiosPut = (url, data) => axiosRequest.put(url, data);

const getResponseError = (method) => ['POST', 'PUT'].includes(method)
  ? GLOBAL_POST_ERROR
  : method === 'DEL'
    ? GLOBAL_DELETE_ERROR : GLOBAL_GET_ERROR;

// Fetch data witch redux
export const fetchDataWithRedux = (
  axtiosMethod,
  url,
  body = {},
  requestAction,
  successAction,
  errorAction,
  callbackSuccess = null,
  callbackError = null,
) => (dispatch) => {
  dispatch(requestAction());
  return axtiosMethod(
    url,
    body,
  ).then((response) => {
    dispatch(successAction(response));
    if (callbackSuccess) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) dispatch(errorAction(response));
    if (callbackError) {
      callbackError();
    }
  });
};

// Fetch data without redux

export const fetchData = ({
  method,
  url,
  body = {},
  callbackSuccess = (() => { }),
  callbackError = (() => { }),
}) => {
  let axiosMethod;

  switch (method) {
    case 'GET':
      axiosMethod = axiosGet;
      break;
    case 'POST':
      axiosMethod = axiosPost;
      break;
    case 'PATCH':
      axiosMethod = axiosPatch;
      break;
    case 'DEL':
      axiosMethod = axiosDelete;
      break;
    default:
      axiosMethod = axiosGet;
  }

  return axiosMethod(
    url,
    body,
  ).then((response) => {
    // console.log('ssssssssddddddfff', response);
    callbackSuccess({
      data: response.data,
      status: response.status,
    });
  }).catch((error) => {
    const { response } = error;
    // console.log('3333333333', error);
    if ([400, 403, 404].includes(response?.status)) {
      callbackError({
        data: response?.data?.data || null,
        status: response?.status || null,
      });
    } else {
      // console.log('vvvvvvvvv', getResponseError(method));
      callbackError({
        data: getResponseError(method) || null,
        status: response?.status || null,
      });
    }
  });
};

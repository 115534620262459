import {
  axiosGet,
  axiosPost,
  FEEDBACK_API_URL,
  ZONE_API_URL,
} from '../apiRequests';

import {
  FEEDBACK_CLEAR_ERRORS,
  FEEDBACK_LIST_GET_FAILURE,
  FEEDBACK_LIST_GET_REQUEST,
  FEEDBACK_LIST_GET_SUCCESS,
  FEEDBACK_ADD_FAILURE,
  FEEDBACK_ADD_REQUEST,
  FEEDBACK_ADD_SUCCESS,
  FEEDBACK_UNREAD_COUNT_FAILURE,
  FEEDBACK_UNREAD_COUNT_REQUEST,
  FEEDBACK_UNREAD_COUNT_SUCCESS,
} from './actionTypes';

// FEEDBACK UNREAD GET
export const feedbackListGetUnreadAction = (zoneId) => (dispatch) => {
  dispatch({ type: FEEDBACK_UNREAD_COUNT_REQUEST });

  return axiosGet(
    `${ZONE_API_URL}/${zoneId}${FEEDBACK_API_URL}/unread-count`,
    { zone_id: zoneId },
  ).then((response) => {
    dispatch({
      type: FEEDBACK_UNREAD_COUNT_SUCCESS,
      feedbackUnreadCount: response.data.data.count || 0,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: FEEDBACK_UNREAD_COUNT_FAILURE,
          errorsFeedbackUnreadGet: response.data ? response.data.data.detail : null,
        });
      } else {
        dispatch({
          type: FEEDBACK_UNREAD_COUNT_FAILURE,
          errorsFeedbackUnreadGet: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// FEEDBACK LIST GET
export const feedbackListGetAction = (zoneId, fetchUrl = `${ZONE_API_URL}/${zoneId}${FEEDBACK_API_URL}`) => (dispatch) => {
  dispatch({ type: FEEDBACK_LIST_GET_REQUEST });

  return axiosGet(
    fetchUrl,
    { zone_id: zoneId },
  ).then((response) => {
    dispatch({
      type: FEEDBACK_LIST_GET_SUCCESS,
      feedbackList: response.data.data.results.reverse() || null,
      feedbackCount: response.data.data.count || null,
      feedbackListNext: response.data.data.next || null,
      feedbackListPrevious: response.data.data.previous || null,
    });
    feedbackListGetUnreadAction(zoneId)(dispatch);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: FEEDBACK_LIST_GET_FAILURE,
          errorsFeedbackListGet: response.data ? response.data.data.detail : null,
        });
      } else {
        dispatch({
          type: FEEDBACK_LIST_GET_FAILURE,
          errorsFeedbackListGet: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// FEEDBACK ADD
export const feedbackAddAction = (zoneId, text, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: FEEDBACK_ADD_REQUEST });

  return axiosPost(
    `${ZONE_API_URL}/${zoneId}${FEEDBACK_API_URL}`,
    { zone_id: zoneId, text },
  ).then((response) => {
    dispatch({
      type: FEEDBACK_ADD_SUCCESS,
      feedbackFromUser: response.data.data.user || null,
      feedbackText: response.data.data.text || null,
      feedbackCreatedAt: response.data.data.created_at || null,
      feedbackReadAt: response.data.data.read_at || null,
    });
    feedbackListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: FEEDBACK_ADD_FAILURE,
          errorsFeedbackAdd: response.data ? response.data.data.detail : null,
        });
      } else if (response.status === 400) {
        dispatch({
          type: FEEDBACK_ADD_FAILURE,
          errorsFeedbackAdd: response.data ? response.data.data.text[0] : null,
        });
      } else {
        dispatch({
          type: FEEDBACK_ADD_FAILURE,
          errorsFeedbackAdd: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearFeedbackErrorsAction = () => (dispatch) => {
  dispatch({ type: FEEDBACK_CLEAR_ERRORS });
};

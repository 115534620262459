// Add or edit Sphere form
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { Loader, Text } from '../../atoms';
import {
  CustomCalendar,
  DialogCustom,
  InputField,
} from '../../molecules';
import ConfirmWidget from '../ConfirmWidget/ConfirmWidget';
import SuccessSvg from '../../../assets/SuccessSvg';
import './ObjectiveManageForm.sass';

import {
  clearObjectiveErrorsAction,
  objectiveAddEditAction,
  objectiveGetDelAction,
  objectiveDoneAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

const ObjectiveManageForm = ({
  className,
  objectiveAdd,
  objectiveId,
  name,
  openDialogButtonText,
  endDate,
  zoneId,
}) => {
  const dispatch = useDispatch();
  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const {
    errorsObjectiveAddEdit,
    errorsObjectiveDone,
    errorsObjectiveGetDel,
    objectiveLoading,
  } = useSelector(({ objectiveReducer }) => objectiveReducer);

  const t11 = useTr(11);
  const t82 = useTr(82);
  const t83 = useTr(83);
  const t84 = useTr(84);
  const t85 = useTr(85);
  const t86 = useTr(86);
  const t87 = useTr(87);
  const t88 = useTr(88);
  const t89 = useTr(89);
  const t90 = useTr(90);

  const [newObjectiveId, setNewObjectiveId] = useState(objectiveId);
  const [newName, setNewName] = useState(name);
  const [newEndDate, setNewEndDate] = useState(endDate);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteWidgetOpened, setIsDeleteWidgetOpened] = useState(false);
  const [isDoneWidgetOpened, setIsDoneWidgetOpened] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
    setNewObjectiveId(objectiveId);
    setNewName(name);
    setNewEndDate(endDate);
    setIsDeleteWidgetOpened(false);
    setIsDoneWidgetOpened(false);
    dispatch(clearObjectiveErrorsAction());
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearObjectiveErrorsAction());
    dispatch(objectiveAddEditAction(
      zoneId,
      newObjectiveId || null,
      newName,
      newEndDate,
      handleClose,
      handleOpen,
    ));
  };

  const handleOpenConfirmWidget = () => {
    setIsDeleteWidgetOpened(true);
  };

  const handleOpenDoneWidget = () => {
    setIsDoneWidgetOpened(true);
  };

  const handleObjectiveAction = (e) => {
    e.preventDefault();
    if (isDeleteWidgetOpened) {
      dispatch(objectiveGetDelAction(newObjectiveId, zoneId, handleClose, true));
    }

    if (isDoneWidgetOpened) {
      dispatch(objectiveDoneAction(newObjectiveId, zoneId, handleClose));
    }
  };

  return (
    <DialogCustom
      disabled={objectiveLoading}
      onClose={handleClose}
      onOpen={handleOpen}
      openIcon="cogs"
      openDialogButton={objectiveAdd}
      openDialogButtonIcon={!objectiveAdd}
      openDialogButtonText={openDialogButtonText || t84}
      dialogTitle={isDeleteWidgetOpened ? t82 : isDoneWidgetOpened ? t83 : objectiveAdd ? t84 : t85}
      openBtnClassName={className}
      open={isDialogOpen}
      btnIconClassName="objective-manage-form__btn-icon"
    >
      {objectiveLoading
        ? <Loader /> : (
          <>
            {(isDeleteWidgetOpened || isDoneWidgetOpened) ? (
              <>
                <ConfirmWidget
                  additionalContent={!isDeleteWidgetOpened && <SuccessSvg className="objective-manage-form__success-svg" zoneColor={zoneColor} />}
                  confirmationBtnText={isDeleteWidgetOpened ? t82 : t11}
                  onConfirm={handleObjectiveAction}
                  onCancel={() => isDeleteWidgetOpened ? setIsDeleteWidgetOpened(false) : setIsDoneWidgetOpened(false)}
                  confirmationText={isDeleteWidgetOpened ? t86 : t87}
                  positive={!isDeleteWidgetOpened}
                />
                {errorsObjectiveDone && <Text error>{errorsObjectiveDone}</Text>}
                {errorsObjectiveGetDel && <Text error>{errorsObjectiveGetDel}</Text>}
              </>
            ) : (
              <>
                {(!isDeleteWidgetOpened && !isDoneWidgetOpened && !objectiveAdd) && (
                  <Button onClick={handleOpenDoneWidget} variant="contained" color="primary">{t83}</Button>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="objective-manage-form__form">
                    <InputField
                      autoFocus
                      disabled={objectiveLoading}
                      error={errorsObjectiveAddEdit && errorsObjectiveAddEdit.name}
                      inputId="nameInput"
                      label={t88}
                      maxLength={100}
                      name="name"
                      onChange={(e) => setNewName(e.target.value)}
                      value={newName}
                    />
                    <InputField
                      disabled
                      error={errorsObjectiveAddEdit && errorsObjectiveAddEdit.endDate}
                      inputId="endDate"
                      label={t89}
                      name="endDate"
                      onChange={(e) => setNewEndDate(e.target.value)}
                      value={newEndDate}
                      className="objective-manage-form__date-input"
                    />
                    <CustomCalendar
                      isAlwaysOpened
                      minDate={new Date()}
                      onCalendarChange={(value) => setNewEndDate(value)}
                      startDate={newEndDate || new Date()}
                    />
                  </div>
                  <Button
                    color="primary"
                    disabled={objectiveLoading || !newName || !newEndDate}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >{t90}
                  </Button>
                  {errorsObjectiveAddEdit && <Text error>{errorsObjectiveAddEdit.zone}</Text>}
                  {errorsObjectiveAddEdit && <Text error>{errorsObjectiveAddEdit.nonField}</Text>}
                </form>
              </>
            )}
            {(!isDeleteWidgetOpened && !isDoneWidgetOpened && !objectiveAdd) && (
              <Button onClick={handleOpenConfirmWidget} color="error" fullWidth>{t82}</Button>
            )}
          </>
        )}
    </DialogCustom>
  );
};

ObjectiveManageForm.propTypes = {
  className: PropTypes.string,
  objectiveId: PropTypes.number,
  objectiveAdd: PropTypes.bool,
  name: PropTypes.string,
  openDialogButtonText: PropTypes.string,
  endDate: PropTypes.string,
  zoneId: PropTypes.string,
};

ObjectiveManageForm.defaultProps = {
  className: null,
  objectiveId: null,
  objectiveAdd: false,
  name: '',
  openDialogButtonText: '',
  endDate: '',
  zoneId: '',
};

export default ObjectiveManageForm;

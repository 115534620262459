import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { matchZoneColorGradient } from '../../../helpers';
import { Loader, Text } from '../../atoms';
import { useTr } from '../../../hooks';
import './ZoneWidget.sass';

const ZoneNumberStyled = styled.span`
  color: ${({ color }) => color};
`;

const ZoneWidget = ({
  color,
  name,
  sharedZoneMy,
  sphereId,
  zoneId,
  zoneNo,
}) => {
  const errorsZoneGetDel = useSelector(({ zoneReducer }) => zoneReducer.errorsZoneGetDel);
  const zoneLoading = useSelector(({ zoneReducer }) => zoneReducer.zoneLoading);

  const t126 = useTr(126);
  const t168 = useTr(168);
  const t169 = useTr(169);

  return (
    zoneLoading
      ? <Loader />
      : (
        errorsZoneGetDel
          ? <Text error>{errorsZoneGetDel}</Text>
          : (
            <Link to={`/sfera/${sphereId}/obszar/${zoneId}/wizja`} className="zone-widget">
              <Text size="xxs" weight="weightBold">{t126}</Text>
              <Text size="s" weight="weightBold" className="zone-widget__name">{name}</Text>
              <FontAwesomeIcon
                icon="arrow-circle-right"
                color={matchZoneColorGradient(color)}
                className="zone-widget__link-icon"
              />
              <div className="zone-widget__share-icon">
                <FontAwesomeIcon
                  icon="hands-helping"
                  color={sharedZoneMy ? matchZoneColorGradient(color) : '#d8d8d8'}
                />
                <Text size="xxs" weight="weightBold" colorCustom={sharedZoneMy ? color : '#d8d8d8'}>{sharedZoneMy ? t168 : t169}</Text>
              </div>
              <ZoneNumberStyled color={color} className="zone-widget__number">{zoneNo}</ZoneNumberStyled>
            </Link>
          )
      )
  );
};

ZoneWidget.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  sharedZoneMy: PropTypes.bool,
  sphereId: PropTypes.number,
  zoneId: PropTypes.number,
  zoneNo: PropTypes.number,
};

ZoneWidget.defaultProps = {
  sphereId: null,
  zoneId: null,
  name: null,
  color: '#f5f5f5',
  sharedZoneMy: false,
  zoneNo: null,
};

export default ZoneWidget;

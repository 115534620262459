// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button } from '@mui/material';

import { useFirstRender, useTr } from '../../../hooks';
import { formatDate, globalDateFormat } from '../../../helpers';
import ActionsList from '../ActionsList/ActionsList';
import { ActionsProgress, DialogCustom, CustomCalendar } from '../../molecules';
import {
  BorderedPart,
  Loader,
  Text,
} from '../../atoms';
import WeeklyActions from '../WeeklyActions/WeeklyActions';
import './ActionsListAndCalendar.sass';

import {
  actionWeekRankingAction,
  actionsListGetAction,
  actionsPassedDaysAction,
  setActionsDateAction,
} from '../../../actions';

const ActionsListAndCalendar = () => {
  const firstRender = useFirstRender();
  const dispatch = useDispatch();

  const {
    actionsDate,
    actionsLoading,
    actionsList,
    actionsListGetErrors,
    actionsPassedDaysList,
  } = useSelector(({ actionsReducer }) => actionsReducer);
  const t15 = useTr(15);
  const t59 = useTr(59);
  const t60 = useTr(60);

  const today = moment().format(globalDateFormat);
  const presentWeek = moment().format('WW');
  const presentYear = moment().format('GGGG');
  const [displayedWeek, setDisplayedWeek] = useState(presentWeek);
  const [displayedYear, setDisplayedYear] = useState(presentYear);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (!firstRender) {
      dispatch(actionsListGetAction(actionsDate));
      setDisplayedWeek(moment(actionsDate).format('WW'));
      setDisplayedYear(moment(actionsDate).format('YYYY'));
    }
  }, [actionsDate]);

  useEffect(() => {
    dispatch(actionWeekRankingAction());
  }, [dispatch]);

  const getPassedDays = () => {
    const startDate = moment().subtract(2, 'year').startOf('month').format(globalDateFormat);
    dispatch(actionsPassedDaysAction(startDate, today));
  };

  // Add actions progress to calendar
  const handleTileContent = ({ date, view }) => {
    if (view === 'month' && actionsPassedDaysList) {
      if (formatDate(date) in actionsPassedDaysList) {
        return <ActionsProgress doneCount={actionsPassedDaysList[formatDate(date)] ? 5 : 0} onCalendar />;
      }
      return <ActionsProgress noActionsAdded onCalendar />;
    }
    return null;
  };

  const handleCalendarChange = (date) => {
    dispatch(setActionsDateAction(date));
    getPassedDays(date);
    setIsDialogOpen(false);
  };

  const handleOpen = () => {
    getPassedDays(actionsDate);
    setIsDialogOpen(true);
  };

  const weeklyActionsProps = {
    actionsDate,
    actionsList,
    displayedWeek,
    displayedYear,
    today,
  };

  return (
    <BorderedPart className="actions">
      {actionsLoading && <Loader view />}
      <div className="actions__header">
        <Text weight="weightBold" className={`actions__date-text${actionsDate !== today ? ' actions__date-text--narrow' : ''}`}>{actionsDate === today ? `${t60}, ${actionsDate}` : actionsDate }</Text>
        {actionsDate !== today && <Button onClick={() => dispatch(setActionsDateAction(today))} variant="outlined">{t60}</Button>}
        <DialogCustom
          onClose={() => setIsDialogOpen(false)}
          onOpen={handleOpen}
          onCancel={() => isDialogOpen(false)}
          openDialogButtonIcon
          openDialogButtonText={t15}
          openIcon="calendar-alt"
          dialogTitle={t59}
          open={isDialogOpen}
          disabled={actionsLoading}
          btnIconClassName="actions__btn-icon"
        >
          <CustomCalendar
            actionsCalendar
            isAlwaysOpened
            onCalendarChange={handleCalendarChange}
            onClickMonth={(month) => getPassedDays(month)}
            onTileContent={handleTileContent}
            startDate={actionsDate}
          />
        </DialogCustom>
      </div>
      <WeeklyActions {...weeklyActionsProps} />
      {actionsListGetErrors ? (
        <div className="actions__errors">
          {actionsListGetErrors.date && <Text error>{actionsListGetErrors.date}</Text>}
          {actionsListGetErrors.nonField && <Text error>{actionsListGetErrors.nonField}</Text>}
        </div>
      ) : (
        <ActionsList
          actionsList={actionsList}
          isToday={actionsDate === today}
        />
      )}
    </BorderedPart>
  );
};

export default ActionsListAndCalendar;

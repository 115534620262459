// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { Loader, Text } from '../../../atoms';
import { Pagination } from '../../../molecules';
import HabitManageForm from '../../../organisms/Habits/HabitManageForm/HabitManageForm';
import HabitsListItem from '../HabitsListItem/HabitsListItem';
import {
  habitsListGetAction,
} from '../../../../actions';
import { useTr } from '../../../../hooks';
import './HabitsTable.sass';

const HabitsTable = ({ zoneId }) => {
  const dispatch = useDispatch();

  const {
    habitsList,
    habitLoading,
    habitsListNext,
    habitsListPrevious,
  } = useSelector(({ habitReducer }) => habitReducer);
  const { zoneMode } = useSelector(({ zoneReducer }) => zoneReducer);
  const t77 = useTr(77);
  const t78 = useTr(78);
  const t79 = useTr(79);
  const t80 = useTr(80);
  const t81 = useTr(81);

  const [showDevelopedHabits, setShowDevelopedHabits] = useState(false);

  useEffect(() => {
    dispatch(habitsListGetAction(zoneId, true, showDevelopedHabits));
  }, [dispatch, zoneId, showDevelopedHabits]);

  const handleClickHabitsVariant = () => {
    setShowDevelopedHabits(!showDevelopedHabits);
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    if (habitsListNext) {
      dispatch(habitsListGetAction(zoneId, null, null, habitsListNext));
    }
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    if (habitsListPrevious) {
      dispatch(habitsListGetAction(zoneId, null, null, habitsListPrevious));
    }
  };

  return (
    <div className="habits-table">
      <div className="habits-table__button-container">
        <Button
          className="habits-table__variant-btn"
          onClick={handleClickHabitsVariant}
          width="20rem"
          variant="outlined"
        >
          {showDevelopedHabits ? t77 : t78}
        </Button>
        {zoneMode !== 'RO' && !showDevelopedHabits && <HabitManageForm zoneId={zoneId} habitAdd />}
      </div>
      <section className="habits-table__habits">
        {habitLoading ? <Loader view /> : (
          habitsList && habitsList.length === 0 && <Text info className="habits-table__info-text">{showDevelopedHabits ? t79 : zoneMode === 'RO' ? t80 : t81}</Text>
        )}
        {!habitLoading && habitsList.map((habit) => (
          <HabitsListItem
            key={habit.id}
            habitId={habit.id}
            name={habit.name}
            release={habit.release}
            prize={habit.prize}
            isDeveloped={showDevelopedHabits}
            zoneId={zoneId}
          />
        ))}
      </section>
      {(habitsListNext || habitsListPrevious) && (
        <Pagination
          isNext={habitsListNext !== null}
          isPrev={habitsListPrevious !== null}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
        />
      )}
    </div>
  );
};

HabitsTable.propTypes = {
  zoneId: PropTypes.string,
};

HabitsTable.defaultProps = {
  zoneId: '',
};

export default HabitsTable;

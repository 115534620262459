import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import store from '../store';
import { muiTheme } from '../theme/muiTheme';
import 'aos/dist/aos.css';

import HistoryListener from '../components/routing/HistoryListener';
import MainRoutes from '../components/routing/MainRoutes';
import { ScrollToTop } from '../components/atoms/ScrollToTop/ScrollToTop';
import MainTemplate from '../templates/MainTemplate';

const Root = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="uDream, uPlan, uMaster, goals, habits, objectives, actions" />
        <meta name="description" content="uMaster - aplikacja wspierająca rozwój osobisty, przeznaczona dla osób prywatnych oraz dla firm" />
        <meta property="og:site_name" content="uMaster" />
        <meta property="og:url" content="https://umaster.pl" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="uMaster - realizuj swoją wizję i osiągnij swoje cele" />
      </Helmet>
      <ScrollToTop />
      <Provider store={store}>
        <BrowserRouter>
          <HistoryListener>
            <ThemeProvider theme={muiTheme}>
              <MainTemplate>
                <MainRoutes />
              </MainTemplate>
            </ThemeProvider>
          </HistoryListener>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default Root;

import {
  ACTION_CLEAR_ERRORS,
  ACTION_ADD_EDIT_FAILURE,
  ACTION_ADD_EDIT_REQUEST,
  ACTION_ADD_EDIT_SUCCESS,
  ACTION_DEL_DONE_FAILURE,
  ACTION_DEL_DONE_REQUEST,
  ACTION_DEL_DONE_SUCCESS,
  ACTION_LIST_GET_FAILURE,
  ACTION_LIST_GET_REQUEST,
  ACTION_LIST_GET_SUCCESS,
  ACTION_LIST_GET_TODAY_SUCCESS,
  ACTION_WEEK_RANKING_FAILURE,
  ACTION_WEEK_RANKING_REQUEST,
  ACTION_WEEK_RANKING_SUCCESS,
  ACTION_WEEKLY_DONE_FAILURE,
  ACTION_WEEKLY_DONE_REQUEST,
  ACTION_WEEKLY_DONE_SUCCESS,
  ACTION_PASSED_DAYS_FAILURE,
  ACTION_PASSED_DAYS_REQUEST,
  ACTION_PASSED_DAYS_SUCCESS,
  ACTION_SET_DATE,
} from '../actions/actionTypes';

import { formatDate } from '../helpers';

const initialState = {
  actionAddEditErrors: null,
  actionData: null,
  actionDelDoneErrors: null,
  actionsDate: formatDate(), // today
  actionsLoading: false,
  actionsList: [],
  actionsListGetErrors: null,
  actionsPassedDaysErrors: null,
  actionsPassedDaysList: {},
  actionsWeeklyDoneList: null,
  actionsWeeklyDoneErrors: null,
  actionsWeekRankingErrors: null,
  weekRanking: null,
  todayActionsDone: 0,
};

export const actionsReducer = (state = initialState, action) => {
  const {
    actionData,
    actionsDate,
    actionsList,
    actionsPassedDaysList,
    actionsWeeklyDoneList,
    editedId,
    errors,
    weekRanking,
    todayActionsDone,
    type,
  } = action;

  switch (type) {
    case ACTION_LIST_GET_REQUEST:
      return {
        ...state,
        actionsListGetErrors: null,
        actionsLoading: true,
      };
    case ACTION_LIST_GET_SUCCESS:
      return {
        ...state,
        actionsList,
        actionsLoading: false,
      };
    case ACTION_LIST_GET_TODAY_SUCCESS:
      return {
        ...state,
        todayActionsDone: todayActionsDone ? todayActionsDone.filter((act) => act.done === true).length : 0,
        actionsLoading: false,
      };
    case ACTION_LIST_GET_FAILURE:
      return {
        ...state,
        actionsListGetErrors: {
          date: errors.date || null,
          nonField: errors.nonField || null,
        },
        actionsLoading: false,
      };
    case ACTION_ADD_EDIT_REQUEST:
      return {
        ...state,
        actionAddEditErrors: null,
        actionsLoading: true,
      };
    case ACTION_ADD_EDIT_SUCCESS:
      const list = state.actionsList;

      if (editedId) {
        const editedAction = list.findIndex((act) => (act.id === editedId));
        list[editedAction] = actionData;
      } else {
        list.push(actionData);
      }

      return {
        ...state,
        actionsList: list,
        actionsLoading: false,
      };
    case ACTION_ADD_EDIT_FAILURE:
      return {
        ...state,
        actionAddEditErrors: {
          description: errors.description || null,
          nonField: errors.nonField || null,
        },
        actionsLoading: false,
      };
    case ACTION_DEL_DONE_REQUEST:
      return {
        ...state,
        actionDelDoneErrors: null,
        actionsLoading: true,
      };
    case ACTION_DEL_DONE_SUCCESS:
      return {
        ...state,
        actionData,
        actionsLoading: false,
      };
    case ACTION_DEL_DONE_FAILURE:
      return {
        ...state,
        actionDelDoneErrors: errors.nonField || null,
        actionsLoading: false,
      };
    case ACTION_WEEK_RANKING_REQUEST:
      return {
        ...state,
        actionsWeekRankingErrors: null,
        actionsLoading: true,
      };
    case ACTION_WEEK_RANKING_SUCCESS:
      return {
        ...state,
        weekRanking,
        actionsLoading: false,
      };
    case ACTION_WEEK_RANKING_FAILURE:
      return {
        ...state,
        actionsWeekRankingErrors: errors.nonField || null,
        actionsLoading: false,
      };
    case ACTION_CLEAR_ERRORS:
      return {
        ...state,
        actionAddEditErrors: null,
        actionDelDoneErrors: null,
        actionsListGetErrors: null,
        actionsPassedDaysErrors: null,
        actionsWeekRankingErrors: null,
      };
    case ACTION_WEEKLY_DONE_REQUEST:
      return {
        ...state,
        actionsWeeklyDoneErrors: null,
        actionsLoading: true,
      };
    case ACTION_WEEKLY_DONE_SUCCESS:
      return {
        ...state,
        actionsWeeklyDoneList,
        actionsLoading: false,
      };
    case ACTION_WEEKLY_DONE_FAILURE:
      return {
        ...state,
        actionsWeeklyDoneErrors: {
          year: errors.year || null,
          week: errors.week || null,
          nonField: errors.nonField || null,
        },
        actionsLoading: false,
      };
    case ACTION_PASSED_DAYS_REQUEST:
      return {
        ...state,
        actionsPassedDaysErrors: null,
        actionsLoading: true,
      };
    case ACTION_PASSED_DAYS_SUCCESS:
      return {
        ...state,
        actionsPassedDaysList,
        actionsLoading: false,
      };
    case ACTION_PASSED_DAYS_FAILURE:
      return {
        ...state,
        actionsPassedDaysErrors: {
          from: errors.from || null,
          to: errors.to || null,
        },
        actionsLoading: false,
      };
    case ACTION_SET_DATE:
      return {
        ...state,
        actionsDate,
      };
    default:
      return state;
  }
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button, Tooltip, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config, useSpring, animated } from 'react-spring';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorOutline } from '@mui/icons-material';

import { muiTheme } from '../../theme/muiTheme';
import { routes } from '../../routes';
import ZoneRoutes from '../../components/routing/ZoneRoutes';
import {
  ButtonIcon,
  Loader,
  Text,
  Heading,
  Progress,
} from '../../components/atoms';
import SharedZoneManageForm from '../../components/organisms/SharedZoneManageForm/SharedZoneManageForm';
import ZoneManageForm from '../../components/organisms/ZoneManageForm/ZoneManageForm';
import { feedbackListGetUnreadAction, setMenuItemsAction, zoneManageAction } from '../../actions';
import { MenuItem, sharedZonesListData } from '../../helpers';
import { ReactComponent as IconHome } from '../../assets/icons/home-duotone.svg';
import { ReactComponent as IconVisit } from '../../assets/icons/hands-helping-duotone.svg';
import ZoneBg from './ZoneBg';
import { useTr } from '../../hooks';

import './ZoneView.sass';

const ZoneInfoContainerStyled = styled.div`
  background: ${({ color1, color2, theme }) => `linear-gradient(150deg, ${color1 || theme.colorPrimary} 50%, ${color2 || theme.colorPrimary})`};
`;

const IconStyled = styled(FontAwesomeIcon)`
  color: ${({ theme, accepted }) => accepted ? theme.colorSuccess : theme.colorError};
  font-size: 2.1rem;
`;

const ButtonIconStyled = styled(ButtonIcon)`
  background-color: ${({ theme, bgcolor }) => bgcolor || theme.colorPrimary} !important;
  z-index: 10;
`;

const ZoneView = () => {
  const { url } = useRouteMatch();
  const { id: sphereId, zoneId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const phoneWidth = useMediaQuery('(max-width:700px)');
  const {
    HOME_URL,
    FEEDBACK_URL,
    HABITS_URL,
    OBJECTIVES_URL,
    SPHERE_URL,
    VISION_URL,
    ZONE_URL,
  } = routes;

  const sharedZoneReducerData = useSelector(({ sharedZoneReducer }) => sharedZoneReducer);
  const feedbackUnreadCount = useSelector(({ feedbackReducer }) => feedbackReducer.feedbackUnreadCount);
  const userEmailConfirmed = useSelector(({ newUserReducer }) => newUserReducer.user.email_confirmed);

  const {
    errorsZoneGetDel,
    zoneLoading,
    zone,
    colorGradient,
    zoneMode,
  } = useSelector(({ zoneReducer }) => zoneReducer);

  const t36 = useTr(36);
  const t38 = useTr(38);
  const t145 = useTr(145);
  const t146 = useTr(146);
  const t170 = useTr(170);
  const t183 = useTr(183);
  const t184 = useTr(184);
  const t185 = useTr(185);
  const t186 = useTr(186);
  const t187 = useTr(187);
  const t188 = useTr(188);
  const t189 = useTr(189);
  const t190 = useTr(190);
  const t191 = useTr(191);
  const t192 = useTr(192);
  const t193 = useTr(193);
  const t194 = useTr(194);
  const t195 = useTr(195);
  const t236 = useTr(236);

  const {
    id,
    color,
    name,
    progress,
  } = zone;

  const {
    sharedZonesListMy,
    sharedZonesListToMe,
  } = sharedZoneReducerData;

  const [sharedZonesDataMy, setSharedZonesDataMy] = useState([]);
  const [sharedZoneUserToMe, setSharedZoneUserToMe] = useState(null);
  const [zoneTheme, setZoneTheme] = useState(muiTheme);
  const [isSharedListShown, setIsSharedListShown] = useState(false);
  const [zoneDetailsShown, setZoneDetailsShown] = useState(false);

  useEffect(() => {
    dispatch(zoneManageAction(zoneId));
    dispatch(feedbackListGetUnreadAction(zoneId));
  }, [dispatch, zoneId]);

  useEffect(() => {
    const sharesListMy = sharedZonesListData(sharedZonesListMy, zoneId);

    if (sharesListMy.length) {
      setSharedZonesDataMy(() => [...sharesListMy]);
    }
  }, [sharedZonesListMy, zoneId]);

  useEffect(() => {
    const sharedListToMe = sharedZonesListData(sharedZonesListToMe, zoneId);

    if (sharedListToMe.length) {
      setSharedZoneUserToMe(sharedListToMe.filter((el) => el.zone.id === parseFloat(zoneId))[0].from_user);
    }
  }, [sharedZonesListToMe, zoneId]);

  const slideInStyles = useSpring({
    config: { ...config.gentle },
    height: isSharedListShown && sharedZonesDataMy ? (sharedZonesDataMy.length === 1 ? sharedZonesDataMy.length * 65 : sharedZonesDataMy.length * 52) : 0,
  });

  const slideUpStyles = useSpring({
    config: { ...config.gentle },
    opacity: zoneDetailsShown ? 1 : 0,
    height: zoneDetailsShown ? ((phoneWidth ? 350 : 180) + (isSharedListShown ? sharedZonesDataMy.length * 50 : 0)) : 0,
  });

  useEffect(() => {
    const newTeme = createTheme({
      ...muiTheme,
      palette: {
        primary: {
          main: color || '#930DD4',
        },
      },
    });
    setZoneTheme(newTeme);
  }, [zone, color]);

  useEffect(() => {
    setZoneDetailsShown(url === location.pathname || matchPath(location.pathname, { path: `${SPHERE_URL}${ZONE_URL}${VISION_URL}` }));
  }, [url, location, location.pathname]);

  const isMatchedPath = useMemo(() => matchPath(url, {
    path: `${SPHERE_URL}${ZONE_URL}`,
  }), [url]);

  const spheres = useMemo(() => new MenuItem(
    zoneMode === 'RO' ? HOME_URL : `/sfera/${sphereId}`,
    t170,
    false,
    null,
    null,
    'circle',
    false,
  ), [location, zoneMode]);

  const zoneVision = useMemo(() => new MenuItem(
    `${url}${VISION_URL}`,
    t183,
    false,
    null,
    null,
    'lightbulb',
    matchPath(location.pathname, { path: `${SPHERE_URL}${ZONE_URL}${VISION_URL}` }),
  ), [location]);

  const zoneHabits = useMemo(() => new MenuItem(
    `${url}${HABITS_URL}`,
    t184,
    false,
    null,
    null,
    'bell',
    matchPath(location.pathname, { path: `${SPHERE_URL}${ZONE_URL}${HABITS_URL}` }),
  ), [location]);

  const zoneObjectives = useMemo(() => new MenuItem(
    `${url}${OBJECTIVES_URL}`,
    t185,
    false,
    null,
    null,
    'compass',
    matchPath(location.pathname, { path: `${SPHERE_URL}${ZONE_URL}${OBJECTIVES_URL}` }),
  ), [location]);

  const zoneFeedback = useMemo(() => new MenuItem(
    `${url}${FEEDBACK_URL}`,
    'Feedback',
    feedbackUnreadCount > 0,
    'secondary',
    feedbackUnreadCount > 0 && feedbackUnreadCount,
    'thumbs-up',
    matchPath(location.pathname, { path: `${SPHERE_URL}${ZONE_URL}${FEEDBACK_URL}` }),
  ), [location, feedbackUnreadCount]);

  useEffect(() => {
    if (isMatchedPath) {
      dispatch(setMenuItemsAction([spheres, zoneVision, zoneHabits, zoneObjectives, zoneFeedback]));
    }
  }, [dispatch, url, location, feedbackUnreadCount, zoneMode]);

  const renderContent = () => {
    if (!zoneLoading && errorsZoneGetDel) {
      return <Text error>{errorsZoneGetDel}. {t186}</Text>;
    }
    return (
      <ThemeProvider theme={zoneTheme}>
        <Heading className="zone-view__heading">
          <span className="zone-view__name">{name}</span>
        </Heading>
        <ButtonIcon
          ariaLabel={t236}
          btnClassName="zone-view__close"
          icon={<CloseIcon />}
          title={t236}
          to={zoneMode === 'RO' ? HOME_URL : `/sfera/${sphereId}`}
        />
        {(zoneDetailsShown && id) && (
          <animated.div style={slideUpStyles} className="zone-view__info-container">
            <ZoneInfoContainerStyled color1={color} color2={colorGradient} className="zone-view__icon-container">
              <Progress
                className="zone-view__progress"
                title={t185}
                progressValue={progress * 100}
              />
              {zoneMode === 'RW' && (
                <>
                  <IconHome className="zone-view__icon" fill="#fff" />
                  <Text weight="weightBold" size="xs" colorCustom="#fff" className="zone-view__text">{t187}</Text>
                  <ZoneManageForm
                    edit
                    name={name}
                    color={color}
                    zoneId={id}
                    zoneOpenDialogButtonText={t188}
                    zoneDialogTitle={t188}
                    openBtnClassName="zone-view__edit-form"
                  />
                </>
              )}
              {zoneMode === 'RO' && (
                <>
                  <IconVisit className="zone-view__icon" fill="#fff" />
                  <Text weight="weightBold" size="xs" colorCustom="#fff" className="zone-view__text">
                    {t189}
                    {sharedZoneUserToMe && <span className="zone-view__text--data">{sharedZoneUserToMe.username} ({sharedZoneUserToMe.email})</span>}
                  </Text>
                </>
              )}
            </ZoneInfoContainerStyled>
            {zoneMode === 'RW' && (
              <ZoneInfoContainerStyled color1={color} color2={colorGradient} className="zone-view__share-container">
                <Text weight="weightBold" colorCustom="#fff">{t190}</Text>
                <div className="zone-view__count">
                  <Text
                    size="xxl"
                    weight="weightBold"
                    colorCustom="#fff"
                    className="zone-view__count-number"
                  >
                    {sharedZonesDataMy.length}
                  </Text>
                  <SharedZoneManageForm zoneId={zoneId} className="zone-view__edit-form" />
                </div>
                {isSharedListShown && sharedZonesDataMy.length > 0 && (
                  <animated.ul style={slideInStyles} className="zone-view__shared-list">
                    {sharedZonesDataMy.map((z) => (
                      <li key={z.id}>
                        <Text size="xs" className="zone-view__email">{z.email}</Text>
                        <Tooltip title={z.accepted ? t36 : t38}>
                          <IconStyled accepted={z.accepted} icon={z.accepted ? 'check-circle' : 'times-circle'} />
                        </Tooltip>
                      </li>
                    ))}
                  </animated.ul>
                )}
                {sharedZonesDataMy.length > 0 && (
                  <ButtonIconStyled
                    ariaLabel={isSharedListShown ? t191 : t192}
                    className="zone-view__list-btn"
                    onClick={() => setIsSharedListShown(!isSharedListShown)}
                    icon={<FontAwesomeIcon icon={isSharedListShown ? 'angle-up' : 'angle-down'} color="#fff" />}
                    title={isSharedListShown ? t191 : t192}
                    bgcolor={color}
                  />
                )}
              </ZoneInfoContainerStyled>
            )}
          </animated.div>
        )}
        {id && (
          <>
            { url !== location.pathname && (
              <Button onClick={() => setZoneDetailsShown(!zoneDetailsShown)} varian="outlined" color="primary" fullWidth className="zone-view__show-details-btn">
                <>
                  <FontAwesomeIcon icon={zoneDetailsShown ? 'angle-up' : 'angle-down'} className="zone-view__arrow-icon" />
                  {zoneDetailsShown ? t193 : t194}
                </>
              </Button>
            )}
            {!userEmailConfirmed
              ? (
                <Text warning className="center-text">
                  <ErrorOutline />
                  {t146}<Link to={routes.ACCOUNT_SETTINGS_URL}>{t145}</Link>
                </Text>
              ) : (
                <>
                  {(url === location.pathname && id) && <Text color="colorGreyDark" className="zone-view__info">{t195}</Text>}
                  <ZoneRoutes />
                </>
              )}
            <ZoneBg color={color} className="zone-view__bg-img" aria-hidden="true" />
          </>
        )}
      </ThemeProvider>
    );
  };

  return (
    <div className="zone-view">
      {zoneLoading ? <Loader view /> : renderContent()}
    </div>
  );
};

export default ZoneView;

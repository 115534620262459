import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTr } from '../hooks';

import {
  BorderedPart,
  Heading,
  Text,
} from '../components/atoms';
import { userChangeLanguageAction } from '../actions';

const LanguageSettingView = () => {
  const {
    authProgressOn,
    changeLanguageErrors,
  } = useSelector(({ userReducer }) => userReducer);

  const user = useSelector(({ newUserReducer }) => newUserReducer.user);

  const t201 = useTr(201);
  const t202 = useTr(202);
  const t203 = useTr(203);
  const t204 = useTr(204);

  const dispatch = useDispatch();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [newLanguage, setNewLanguage] = useState(user.language);
  const [isSuccess, setIsSuccess] = useState(false);

  const callbackSuccess = () => {
    setIsSuccess(true);
    setIsFormChanged(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userChangeLanguageAction(newLanguage, callbackSuccess));
  };

  const handleChange = (e) => {
    setNewLanguage(e.target.value);
    setIsFormChanged(true);
    setIsSuccess(false);
  };

  return (
    <BorderedPart className="bordered-part--full-padding">
      <div className="account-setting-view__header-flex">
        <Heading>{t203}</Heading>
        <Text size="xs" color="colorGreyDark" weight="weightBold">{t201} {user.language}</Text>
      </div>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" className="account-setting-view__form">
          <FormLabel component="legend" className="radio-group-label">{t202}</FormLabel>
          <RadioGroup
            aria-label={t202}
            name="language"
            value={newLanguage}
            onChange={handleChange}
          >
            <FormControlLabel disabled={authProgressOn} value="pl" control={<Radio />} label="Polski" />
            <FormControlLabel disabled={authProgressOn} value="en" control={<Radio />} label="English" />
          </RadioGroup>
          <Button
            color={isSuccess ? 'success' : 'primary'}
            disabled={authProgressOn || !isFormChanged}
            type="submit"
            variant="contained"
          >
            {t203}
          </Button>
          {changeLanguageErrors && <Text error>{changeLanguageErrors.nonField}</Text>}
          {isSuccess && <Text success>{t204}</Text>}
        </FormControl>
      </form>
    </BorderedPart>
  );
};

export default LanguageSettingView;

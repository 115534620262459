// Use one of the sizes:
// fontSize: {
//   xxs: '1rem',
//   xs: '1.3rem',
//   s: '1.6rem',
//   m: '2.1rem',
//   l: '2.4rem',
//   xl: '3.2rem',
//   xxl: '8rem',
// },
// fontWeight:
// weightRegular: 400,
// weightBold: 700,
// weightExtraBold: 800,

// colors taken from mainTheme.js

import styled, { css } from 'styled-components';

export const Text = styled.p`
  position: relative;
  font-weight: ${({ theme }) => theme.weightRegular};
  line-height: 1.5;
  z-index: 1;
  color: ${({ colorCustom, color, theme }) => color ? theme[color] : colorCustom};
  white-space: pre-line;
  word-break: break-word;
  max-width: 100%;

  b {
    font-weight: bold;
  }

  ${({ size }) => (
    size ? css`
      font-size: ${({ theme }) => theme.fontSize[size]};
    }` : css`
      font-size: ${({ theme }) => theme.fontSize.s};
    }`
  )};

  ${({ weight }) => (
    weight ? css`
      font-weight: ${({ theme }) => theme[weight]};
    }` : css`
      font-weight: ${({ theme }) => theme.weightRegular};
    }`
  )};

  ${({ centered }) => (
    centered && css`
      margin: 0 auto;
      text-align: center;
    }`
  )};

  ${({ limitedWidth }) => (
    limitedWidth && css`
      max-width: 60rem;
      margin: 0 auto;
    }`
  )};

  ${({
    error,
    success,
    warning,
    info,
  }) => (
    (error || success || warning || info) && css`
      margin: .3rem 0;
      font-size: ${({ theme }) => theme.fontSize.xs};
      font-weight: ${({ theme }) => theme.weightBold};
    }`
  )};

  ${({ error }) => (
    error && css`
      padding-left: 1rem;
      color: ${({ theme }) => theme.colorError};
    }`
  )};

  ${({ success }) => (
    success && css`
      color: ${({ theme }) => theme.colorSuccess};
    }`
  )};

  ${({ warning }) => (
    warning && css`
      color: ${({ theme }) => theme.colorOrange};
    }`
  )};

  ${({ info }) => (
    info && css`
      color: ${({ theme }) => theme.colorGreyDark};
    }`
  )};
`;

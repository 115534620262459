import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../Text/Text';

export const ErrorsList = ({ list }) => (
  <ul className="errors-list">
    {list.map((e, i) => <li className="errors-list__item" key={i}><Text error>{e}</Text></li>)}
  </ul>
);

ErrorsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

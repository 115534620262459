/* eslint-disable */

import React, {Fragment} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const TabContent = [
    {
        name: 'Wizję',
        desc: `Wymyśl swój idealny świat i zapisz go w formie wizji.`,
        list: [
            'Opisz czego pragniesz',
            'Dodaj zdjęcia, które Cię zmotywują',
            'Stwórz swoją tablicę inspiracji',
        ]
    }, 
    {
        name: 'Cele',
        desc: `Wyznacz konkretne, mierzalne cele, aby dokładnie wiedzieć, co chcesz osiągnąć.`,
        list: [
            'Dodaj cele główne i podcele',
            'Wyznacz terminy ich realizacji',
            'Mierz postępy',
        ]
    }, 
    {
        name: 'Nawyki',
        desc: `Według najnowszych badań, stosując pętle nawyków, masz największe szanse na osiągnięcie celu.`,
        list: [
            'Wyznacz nawyki, które pomogą Ci w realizacji celu',
            'Znajdź wyzwalacz nawyku i wyznacz sobie nagrodę',
            'Stosuj pętle nawyków, aby zachować dyscyplinę',
        ]
    }, 
    {
        name: 'Feedback',
        desc: `Nie musisz działać w pojedynkę. Praca zespołowa to super motywacja, przyjemność i większa szansa na sukces!`,
        list: [
            'Udostępnij obszar swoim bliskim',
            'Wspólnie ustalajcie cele i nawyki',
            'Motywujcie się nawzajem',
        ]
    }
];

const FeatureTab = () => {
    return (
        <Fragment>
            <Tabs>
                <TabList className="nav nav-tabs justify-content-between">
                    {TabContent.map((val, i)=>(
                        <Tab key={i} className="nav-item">
                            <button className="nav-link">{val.name}</button>
                        </Tab>
                    ))}
                </TabList>

                {TabContent.map((val, i) => (
                    <TabPanel key={i} className="tab-content">
                        <div className="tab-pane fade active show" id="sp1">
                            <p className="pt-10 pb-10">{val.desc}</p>
                            <ul className="style-none list-item">
                                {val.list.map((list, i)=>(
                                    <li key={i}>{list}</li>
                                ))}
                            </ul>
                        </div>
                    </TabPanel>
                ))}

            </Tabs>
        </Fragment>
    )
}

export default FeatureTab
// Add or edit Sphere form
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

import {
  ButtonIcon,
  Heading,
  Loader,
  Text,
} from '../../atoms';
import { DialogCustom, ColorPickerWidget, InputField } from '../../molecules';
import ConfirmWidget from '../ConfirmWidget/ConfirmWidget';
import './ZoneManageForm.sass';

import {
  clearZoneErrorsAction,
  getSpheresListAction,
  newAddZoneAction,
  zoneManageAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

const ZoneManageForm = ({
  color,
  edit,
  name,
  position,
  submitBtnText,
  zoneOpenDialogButtonText,
  zoneDialogTitle,
  zoneId,
  ...props
}) => {
  const {
    newZoneAddLoading,
    newErrorsZoneAdd,
    zoneLoading,
  } = useSelector(({ zoneReducer }) => zoneReducer);

  const t163 = useTr(163);
  const t164 = useTr(164);
  const t165 = useTr(165);
  const t166 = useTr(166);
  const t167 = useTr(167);
  const t231 = useTr(231);

  const dispatch = useDispatch();
  const { id: sphereId } = useParams();
  const history = useHistory();

  const [newName, setName] = useState(name);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newColor, setNewColor] = useState('');
  const [isConfirmWidgetOpened, setisConfirmWidgetOpened] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
    dispatch(clearZoneErrorsAction());
    setName(name);
    setNewColor('');
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleColorChange = (data) => {
    setNewColor(data);
  };

  const submitCallbackSuccess = () => {
    handleClose();
    if (!edit) {
      dispatch(getSpheresListAction(true));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(zoneManageAction(zoneId, false, edit, newName, (newColor || color)));
    } else {
      dispatch(newAddZoneAction(sphereId, newName, (newColor || color), submitCallbackSuccess));
    }
  };

  const handleOpenConfirmWidget = () => {
    setisConfirmWidgetOpened(true);
  };

  const handleDeleteZone = () => {
    dispatch(zoneManageAction(zoneId, true, false, null, null, () => history.push(`/sfera/${sphereId}`)));
  };

  return (
    <>
      {!edit && (
        <ButtonIcon
          ariaLabel={t163}
          onClick={handleOpen}
          title={t163}
          icon={<FontAwesomeIcon icon="plus" color="#D8D8D8" />}
          className={`zone-manage-form__add-zone-btn zone-manage-form__add-zone-btn--${position}`}
        />
      )}
      <DialogCustom
        onClose={handleClose}
        onOpen={edit ? handleOpen : null}
        openDialogButton={edit}
        openDialogButtonText={edit ? zoneOpenDialogButtonText : null}
        dialogTitle={isConfirmWidgetOpened ? t164 : (zoneDialogTitle || t163)}
        open={isDialogOpen}
        {...props}
      >
        {zoneLoading
          ? <Loader /> : (
            <>
              {isConfirmWidgetOpened ? (
                <ConfirmWidget
                  confirmationBtnText={t164}
                  confirmationText={t165}
                  onConfirm={handleDeleteZone}
                  onCancel={() => setisConfirmWidgetOpened(false)}
                />
              ) : (
                <form onSubmit={handleSubmit}>
                  <Heading>{t167}</Heading>
                  <ColorPickerWidget
                    disabled={zoneLoading}
                    error={newErrorsZoneAdd && newErrorsZoneAdd.color}
                    onColorChange={(e) => handleColorChange(e)}
                    value={newColor || color}
                  />
                  <InputField
                    autoFocus
                    disabled={zoneLoading}
                    error={newErrorsZoneAdd && newErrorsZoneAdd.name}
                    inputId="nameInput"
                    label={t166}
                    maxLength={200}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    value={newName}
                  />
                  <Button
                    color="primary"
                    disabled={newZoneAddLoading || !newName}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {newZoneAddLoading ? <FontAwesomeIcon icon="spinner" spin /> : submitBtnText || t231}
                  </Button>
                  {newErrorsZoneAdd && newErrorsZoneAdd.nonField && <Text error>{newErrorsZoneAdd.nonField}</Text>}
                  {newErrorsZoneAdd && newErrorsZoneAdd.sphere && <Text error>{newErrorsZoneAdd.sphere}</Text>}
                </form>
              )}
              {(edit && !isConfirmWidgetOpened) && <Button onClick={handleOpenConfirmWidget} color="error" fullWidth>{t164}</Button>}
            </>
          )}
      </DialogCustom>
    </>
  );
};

ZoneManageForm.propTypes = {
  color: PropTypes.string,
  edit: PropTypes.bool,
  name: PropTypes.string,
  position: PropTypes.number,
  submitBtnText: PropTypes.string,
  zoneOpenDialogButtonText: PropTypes.string,
  zoneDialogTitle: PropTypes.string,
  zoneId: PropTypes.number,
};

ZoneManageForm.defaultProps = {
  color: '#9F218C',
  edit: false,
  name: '',
  position: null,
  submitBtnText: '',
  zoneOpenDialogButtonText: '',
  zoneDialogTitle: '',
  zoneId: null,
};

export default ZoneManageForm;

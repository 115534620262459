import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  fetchDataWithRedux,
  ZONE_API_URL,
} from '../apiRequests';

import {
  ZONE_MANAGE_FAILURE,
  ZONE_MANAGE_REQUEST,
  ZONE_MANAGE_SUCCESS,
  ZONE_CLEAR_ERRORS,
  ZONE_CLEAR_DATA,
  NEW_ZONE_ADD_REQUEST,
  NEW_ZONE_ADD_SUCCESS,
  NEW_ZONE_ADD_FAILURE,
} from './actionTypes';

// ADD ZONE
const addZoneRequest = () => ({ type: NEW_ZONE_ADD_REQUEST });
const addZoneSuccess = (response) => ({
  type: NEW_ZONE_ADD_SUCCESS,
  newZoneData: response?.data.data,
});
const addZoneFailure = (response) => ({
  type: NEW_ZONE_ADD_FAILURE,
  newErrors: {
    sphere: response?.data.data.sphere ? response?.data.data.sphere[0] : null,
    name: response?.data.data.name ? response?.data.data.name[0] : null,
    color: response?.data.data.color ? response?.data.data.color[0] : null,
    nonField: response?.data.data.non_field_errors ? response?.data.data.non_field_errors[0] : response?.data.data.detail || null,
  },
});

export const newAddZoneAction = (sphere, name, color, callbackSuccess) => fetchDataWithRedux(
  axiosPost,
  `${ZONE_API_URL}/create`,
  {
    sphere,
    name,
    color,
  },
  addZoneRequest,
  addZoneSuccess,
  addZoneFailure,
  callbackSuccess,
);

// OLD DELETE

// ZONE GET/PATCH/DELETE
export const zoneManageAction = (
  zoneId,
  del = false,
  edit = false,
  name = null,
  color = null,
  callbackSuccess = null,
) => (dispatch) => {
  dispatch({ type: ZONE_MANAGE_REQUEST });

  const method = del ? axiosDelete : (edit ? axiosPatch : axiosGet);
  return method(
    `${ZONE_API_URL}/${zoneId}`,
    {
      name,
      color,
    },
  ).then((response) => {
    dispatch({
      type: ZONE_MANAGE_SUCCESS,
      zoneData: response.data.data,
      zoneMode: response.data.meta.mode,
    });

    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: ZONE_MANAGE_FAILURE,
          errorsZoneGetDel: response.data.data.detail,
        });
      } else {
        dispatch({
          type: ZONE_MANAGE_FAILURE,
          errorsZoneGetDel: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearZoneErrorsAction = () => (dispatch) => {
  dispatch({ type: ZONE_CLEAR_ERRORS });
};

// CLEAR ZONE DATA
export const clearZoneData = () => (dispatch) => {
  dispatch({ type: ZONE_CLEAR_DATA });
};

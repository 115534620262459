import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';

import { ACCOUNTS_API_URL } from '../apiRequests';
import { routes } from '../routes';
import { ErrorsList, Loader, Text } from '../components/atoms';
import { Card, ConfirmButton } from '../components/molecules';
import AuthTemplate from '../templates/AuthTemplate';
import { useAxiosPostNew, useTr } from '../hooks';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 9.5rem 1.5rem 1.5rem;
`;

const NewPasswordView = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [setNewPassSuccess, setSetNewPassSuccess] = useState(false);

  const t206 = useTr(206);
  const t207 = useTr(207);
  const t208 = useTr(208);
  const t209 = useTr(209);
  const t210 = useTr(210);

  const { token } = useParams();

  const handleSubmit = (credentials) => {
    setPass1(credentials.password);
    setPass2(credentials.repeatPassword);
    setIsSaving(true);
  };

  const {
    responseError,
    isFetching,
  } = useAxiosPostNew({
    url: `${ACCOUNTS_API_URL}/password-reset/${token}`,
    body: {
      password_1: pass1,
      password_2: pass2,
    },
    isSaving,
    setIsSaving,
    callbackSuccess: () => setSetNewPassSuccess(true),
  });

  if (setNewPassSuccess) {
    return (
      <Wrapper>
        <Card cardHeader={t206}>
          <>
            <Text>{t207}</Text>
            <ConfirmButton
              linkName={t208}
              tag={Link}
              url={routes.LOGIN_URL}
            />
          </>
        </Card>
      </Wrapper>
    );
  }

  return (
    <AuthTemplate
      authFormHeader={t209}
      buttonName={isFetching ? <Loader small /> : t210}
      disabled={isFetching}
      errorPassword={responseError?.password_1 && responseError.password_1[0]}
      errorRepeatPassword={responseError?.password_2 && responseError.password_2[0]}
      errorNonFiled={
        (responseError?.non_field_errors && <ErrorsList list={responseError.non_field_errors} />) || (responseError?.detail && <ErrorsList list={[responseError.detail]} />)
      }
      isEmailField={false}
      isPasswordField
      isRepeatPasswordField
      onSubmit={handleSubmit}
    />
  );
};

export default NewPasswordView;

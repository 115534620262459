import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';

import { globalDateFormat } from '../../../helpers';
import { ButtonIcon } from '../../atoms';
import { useTr } from '../../../hooks';
import './CustomCalendar.sass';

export const CustomCalendar = ({
  actionsCalendar,
  isAlwaysOpened,
  onOpen,
  onCalendarChange,
  onTileContent,
  startDate,
  ...props
}) => {
  const [value, setValue] = useState(new Date(moment(startDate).valueOf()) || new Date());
  const [isOpen, setIsOpen] = useState(isAlwaysOpened);

  const t14 = useTr(14);
  const t15 = useTr(15);
  const t16 = useTr(16);
  const t17 = useTr(17);
  const t18 = useTr(18);
  const t19 = useTr(19);

  useEffect(() => {
    setValue(new Date(moment(startDate).valueOf()));
  }, [startDate]);

  const onChange = (val) => {
    onCalendarChange(moment(val).format(globalDateFormat));
    setIsOpen(false);
  };

  const onCalendarOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen && actionsCalendar) onOpen();
  };

  return (
    <>
      {!isAlwaysOpened && (
        <ButtonIcon
          icon={isOpen ? <CloseIcon /> : <EventIcon />}
          onClick={onCalendarOpen}
          title={isOpen ? t14 : t15}
        />
      )}
      {(isOpen || isAlwaysOpened) && (
        <Calendar
          onChange={onChange}
          value={value}
          tileContent={onTileContent}
          next2Label={<ArrowForwardRoundedIcon fontSize="small" />}
          nextLabel={<ArrowForwardIosRoundedIcon />}
          nextAriaLabel={t16}
          next2AriaLabel={t18}
          prev2Label={<ArrowBackRoundedIcon fontSize="small" />}
          prevLabel={<ArrowBackIosRoundedIcon />}
          prevAriaLabel={t17}
          prev2AriaLabel={t19}
          {...props}
        />
      )}
    </>
  );
};

CustomCalendar.propTypes = {
  actionsCalendar: PropTypes.bool,
  isAlwaysOpened: PropTypes.bool,
  onOpen: PropTypes.func,
  onCalendarChange: PropTypes.func,
  onTileContent: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

CustomCalendar.defaultProps = {
  actionsCalendar: false,
  isAlwaysOpened: false,
  onOpen: null,
  onCalendarChange: null,
  onTileContent: null,
  startDate: null,
};

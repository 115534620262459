export const TRANSLATIONS_DICT = {
  1: ['Wybierz obrazek', 'Choose an image'],
  2: ['Maksymalna wielkość pliku: 10MB', 'Max file size: 10MB'],
  3: ['Trwa ładowanie...', 'Loading...'],
  4: ['Akcja', 'Action name'],
  5: ['Anuluj', 'Cancel'],
  6: ['Zapisz akcję', 'Save action'],
  7: ['Dodaj akcję...', 'Add action...'],
  8: ['Usuń akcję', 'Delete action'],
  9: ['Edytuj akcję', 'Edit action'],
  10: ['Czy na pewno oznaczasz akcję jako wykonaną?', 'Are you sure, you want to set this action as done?'],
  11: ['Tak', 'Yes'],
  12: ['Wybierz kolor Obszaru', 'Choose Zone color'],
  13: ['Kolor', 'Color'],
  14: ['Zamknij kalendarz', 'Close calendar'],
  15: ['Pokaż kalendarz', 'Open calendar'],
  16: ['Następny miesiąc', 'Next month'],
  17: ['Poprzedni miesiąc', 'Previous month'],
  18: ['Następny rok', 'Next year'],
  19: ['Poprzedni rok', 'Previous year'],
  20: ['Zamknij dialog', 'Close dialog'],
  21: ['Otwórz dialog', 'Open dialog'],
  22: ['Wpisz wiadomość', 'Type message'],
  23: ['Ukryj hasło', 'Hide password'],
  24: ['Pokaż hasło', 'Show password'],
  25: ['Cel zrealizowany', 'The goal is done'],
  26: ['Edytuj cel', 'Edit goal'],
  27: ['Planowano zakończenie', 'Planned end date'],
  28: ['Koniec', 'End'],
  29: ['Zrealizowano', 'Done'],
  30: ['Cele cząstkowe', 'Subgoals'],
  31: ['Dodaj cel cząstkowy', 'Add subgoals'],
  32: ['Następna strona', 'Next page'],
  33: ['Poprzenia strona', 'Previous page'],
  34: ['Udostępnione przez:', 'Shared by:'],
  35: ['Udostępnione dla:', 'Shared to:'],
  36: [' ZAAKCEPTOWANE', ' ACCEPTED'],
  37: ['Akceptuj zaproszenie do Obszaru', 'Accept invitation to the Zone'],
  38: ['NIEZAAKCEPTOWANE', 'NOT ACCEPTED'],
  39: ['Usuń z listy udostępnionych obszarów', 'Delete from shared Zones list'],
  40: ['Przestań udostępniać', 'Stop sharing'],
  41: ['Zaproszenie wygasa:', 'Expire at:'],
  42: ['Zaproszenie wygasło', 'Invitation expired'],
  43: ['Przestań udostępniać Obszar', 'Stop sharing the Zone'],
  44: ['Czy na pewno chcesz przestać udostępniać Obszar?', 'Are you sure, you want to stop sharing the Zone?'],
  45: ['Edytuj cel cząstkowy', 'Edit subgoals'],
  46: ['Edytuj', 'Edit'],
  47: ['Wybrany plik jest za duży. Maksymalna wielkość pliku to 10MB', 'The file is too big. Max file size is 10MB'],
  48: ['Zmień obrazek', 'Change image'],
  50: ['Usuń obrazek', 'Delete image'],
  51: ['Czy na pewno chcesz usunąć ten obrazek?', 'Are you sure you want to delete this image?'],
  52: ['Moja wizja', 'My vision'],
  54: ['Dodaj kolejny obrazek', 'Add another image'],
  55: ['Dodaj dzisiejsze akcje!', 'Add todays actions!'],
  56: ['Gratulacje!!!', 'Congratulations!!!'],
  57: ['Świetna robota - Twoje wszystkie dzisiejsze akcje są zaliczone :)', 'Nice job - all of your todays actions are done :)'],
  59: ['Wybierz datę', 'Choose date'],
  60: ['Dzisiaj', 'Today'],
  61: ['Lista wiadomości w tym Obszarze jest pusta', 'Feedback list in this Zone is empty'],
  62: ['Poprzednie', 'Previous'],
  63: ['Następne', 'Next'],
  64: ['Twoje wiadomości trafią do wszystkich użytkowników,', 'Your messages will go to all users,'],
  65: ['którzy współdzielą ten Obszar z użytkownikiem', 'who share this Zone with'],
  66: ['którym udostępniasz ten Obszar. Upewnij się, że zaproszeni użytkownicy zaakceptowali zaproszenie, inaczej Twoje wiadomości do nich nie trafią.', 'who you share this Zone with. Be sure they have accepted your invitation, otherwise, they will not see these messages.'],
  67: ['Usuń nawyk', 'Delete habit'],
  68: ['Oznacz nawyk jako wyuczony', 'Set the habit as worked out'],
  69: ['Stwórz pętlę nawyku', 'Create habit loop'],
  70: ['Edytuj pętlę nawyku', 'Edit habit loop'],
  71: ['Czy jesteś pewien, że chcesz usunąć ten nawyk?', 'Are you sure, you want to delete this habit?'],
  72: ['Czy jesteś pewien, że chcesz oznaczyć ten nawyk jako wyuczony?', 'Are you sure, you want to set this habit as worked out?'],
  73: ['Wyzwalacz', 'Trigger'],
  74: ['Nawyk', 'Habit'],
  75: ['Nagroda', 'Reward'],
  76: ['Nawyk wyuczony', 'Worked out'],
  77: ['Pokaż aktywne nawyki', 'Show active habits'],
  78: ['Pokaż wyuczone nawyki', 'Show worked out habits'],
  79: ['Nie ma jeszcze wyuczonych nawyków', 'There are no worked out habits'],
  80: ['Nie ma żadnych nawyków', 'There are no habits'],
  81: ['Dodaj swój pierwszy nawyk', 'Add your first habit'],
  82: ['Usuń cel', 'Delete goal'],
  83: ['Oznacz cel jako zrealizowany', 'Set goal as done'],
  84: ['Dodaj cel', 'Add goal'],
  85: ['Edytuj cel', 'Edit goal'],
  86: ['Czy jesteś pewien, że chcesz usunąć ten cel?', 'Are you sure, you want to delete this goal?'],
  87: ['Czy ten cel jest zrealizowany?', 'Is this goal done?'],
  88: ['Nazwa celu', 'Goal name'],
  89: ['Do kiedy chcesz zrealizować cel?', 'Goal end date'],
  90: ['Zapisz cel', 'Save goal'],
  91: ['Pokaż aktywne cele', 'Show active goals'],
  92: ['Pokaż zrealizowane cele', 'Show done goals'],
  93: ['Nie ma jeszcze zrealizowanych celów', 'There are no done goals'],
  94: ['Nie ma żadnych celów', 'There are no goals'],
  95: ['Dodaj swój pierwszy cel', 'Add your first goal'],
  96: ['Udostępnij obszar', 'Share this Zone'],
  97: ['Udostępnij obszar innemu użytkownikowi', 'Share this Zone to another user'],
  98: ['E-mail użytkownika', 'User e-mail'],
  99: ['Pokaż Obszary udostępnione przeze mnie', 'Show my Zones shared with other users'],
  100: ['Pokaż Obszary udostępnione mi', 'Show Zones shared with me'],
  101: ['Lista Obszarów udostępnionych mi', 'Zones shared with me'],
  102: ['Lista Obszarów udostępnionych przeze mnie', 'My Zones shared with other users'],
  103: ['Nikt nie udostępnia Ci Obszarów', 'No one shares Zone with you'],
  104: ['Nie udostępniasz żadnego obszaru', 'You dont\'t share any of your Zones'],
  105: ['Nawigacja główna', 'Main nav'],
  106: ['Wystąpił problem z wyświetleniem Sfery', 'There is a problem with showing the Sphere'],
  107: ['Nie masz uprawnień, aby wyświetlić tę Sferę. Wybierz jedną ze swoich Sfer lub dodaj nową.', 'You do not have permission to see this Sphere. Choose one of your Spheres or add the new one.'],
  108: ['Sfera główna', 'Main Sphere'],
  109: ['Edytuj Sferę', 'Edit Sphere'],
  110: ['Edycja Sfery Rozwoju', 'Sphere edition'],
  111: ['Zapisz sferę', 'Save Sphere'],
  112: ['Cele', 'Goals'],
  113: ['Dodaj Sferę', 'Add Sphere'],
  114: ['Usuń Sferę', 'Delete Sphere'],
  115: ['Czy na pewno chcesz usunąć Sferę?', 'Are you sure, you want to delete this Sphere?'],
  116: ['Sfera zostanie usunięta. Dotyczy to również wszystkich jej Obszarów i zapisanych w nich danych.', 'The Sphere will be removed. It refers also to all of its Zones and other Spheres data.'],
  117: ['Obraz Sfery', 'Sphere image'],
  118: ['Zmień obrazek Sfery', 'Change Sphere image'],
  119: ['Dodaj obrazek Sfery', 'Add Sphere image'],
  120: ['Nazwa Sfery', 'Sphere name'],
  121: ['Twoją Sferą główną jest obecnie', 'Your main Sphere is currently'],
  122: ['Jeśli chcesz to zmienić, zaznacz checkbox SFERA GŁOWNA.', 'If you want to change it, check MAIN SPHERE checkbox.'],
  123: ['To jest Twoja Sfera główna. Możesz to zmienić, oznaczając inną Sferę jako główną.', 'This is your main Sphere. You can change it by checking other Sphere as main.'],
  124: ['Nie możesz usunąć swojej Sfery głównej. Jeśli chcesz ją usunąć, oznacz inną Sferę jako główną.', 'You can not delete your main Sphere. If you want to, set other Sphere as main.'],
  125: ['Sfera', 'Sphere'],
  126: ['Obszar', 'Zone'],
  127: ['Moje Sfery', 'My Spheres'],
  128: ['Nie masz żadnej Sfery Rozwoju', 'You do not have any Sphere'],
  129: ['Możesz mieć maksymalnie 3 Sfery Rozwoju. Jeśli chcesz mieć nowe Sfery Rozwoju, usuń wybrane Sfery.', 'You can have max 3 Spheres. If you want to have new Sphere, delete one of existing.'],
  130: ['Usuń cel cząstkowy', 'Delete subgoal'],
  131: ['Oznacz cel cząstkowy jako zrealizowany', 'Set this subgoal as done'],
  132: ['Czy jesteś pewien, że chcesz usunąć ten cel cząstkowy?', 'Are you sure, you want to delete this subgoal?'],
  133: ['Czy ten cel cząstkowy jest zrealizowany?', 'Is this subgoal done?'],
  134: ['Nazwa celu cząstkowego', 'Subgoal name'],
  135: ['Zapisz cel cząstkowy', 'Save subgoal'],
  136: ['Zrealizowane cele cząstkowe', 'Done subgoals'],
  137: ['uMaster Beta. Przejdź na stronę główną.', 'uMaster Beta. Go to home page.'],
  138: ['Zalogowany jako', 'Username'],
  139: ['. Aktywuj konto!', '. Activate your account!'],
  140: ['Wyloguj się', 'Sign out'],
  141: ['Zaloguj się', 'Sign in'],
  142: ['Załóż konto', 'Sign up'],
  143: ['Przejdź do konta użytkownika.', 'Go to users settings.'],
  144: ['Nazwa użytkownika', 'Username'],
  145: ['aktywuj swoje konto', 'activate your account'],
  146: ['Aby w pełni korzystać z aplikacji ', 'To use full features of this app '],
  147: ['Mój', 'My'],
  148: ['Ustawienia konta', 'Account settings'],
  149: ['Pomoc', 'Help'],
  150: ['Przejdź do strony o aplikacji', 'Go to ABOUT page'],
  151: ['O aplikacji', 'About'],
  152: ['Wyślij e-mail', 'Send e-mail'],
  153: ['Opisz swoją wizję dotyczącą tego Obszaru...', 'Describe your vision about this Zone...'],
  154: ['Dodaj opis swojej wizji', 'Add description of your vision'],
  155: ['Edytuj wizję', 'Edit vision'],
  156: ['Usuń wizję', 'Delete vision'],
  157: ['Czy na pewno chcesz usunąć swoją wizję?', 'Are you sure, you want to delete this vision?'],
  158: ['Usunięte zostaną opis oraz zdjęcia Twojej wizji.', 'All photos and description will be removed.'],
  159: ['Twoja wizja', 'Your vision'],
  160: ['Zapisz wizję', 'Save vision'],
  161: ['Poprzedni tydzień', 'Previous week'],
  162: ['Następny tydzień', 'Next week'],
  163: ['Dodaj Obszar', 'Add Zone'],
  164: ['Usuń Obszar', 'Delete Zone'],
  165: ['Czy na pewno chcesz usunąć ten Obszar?', 'Are you sure, you want to delete this Zone?'],
  166: ['Nazwa Obszaru', 'Zone name'],
  167: ['Kolor Obszaru', 'Zone color'],
  168: ['Udostępniam', 'Shared'],
  169: ['Nie udostępniam', 'Not shared'],
  170: ['Start', 'Start'],
  171: ['Zwycięski Dzień', 'Daily actions'],
  172: ['Udostępnione Obszary', 'Shared Zones'],
  173: ['Adres e-mail', 'E-mail'],
  174: ['Hasło', 'Password'],
  175: ['Powtórz hasło', 'Repeat password'],
  176: ['Zgadzam się na regulamin...', 'I agree to the terms of use...'],
  177: ['Zgadzam się na informacje...', 'I agree to marketing info...'],
  178: ['Formularz zawiera błędy', 'There are errors in the form'],
  179: ['Profil', 'Profile'],
  180: ['Language', 'Język'],
  181: ['Lista nawyków w Obszarze', 'Habits list in Zone'],
  182: ['Lista celów w Obszarze', 'Goal list in Zone'],
  183: ['Wizja', 'Vision'],
  184: ['Nawyki', 'Habits'],
  185: ['Cele', 'Goals'],
  186: ['Jeśli masz zaproszenie do tego Obszaru, zaakceptuj je. Jeśli nie, poproś o udostępnienie właściciela tego Obszaru.', 'If you have an invitation to this Zone, accept it. Otherwise ask its owner to send you an invitation.'],
  187: ['Mój obszar', 'My Zone'],
  188: ['Edytuj obszar', 'Edit Zone'],
  189: ['Obszar udostępniony mi przez:', 'Zone shared by:'],
  190: ['Udostępnienia', 'Shares'],
  191: ['Zwiń listę', 'Hide list'],
  192: ['Pokaż listę', 'Show list'],
  193: ['Ukryj szczegóły Obszaru', 'Hide Zone details'],
  194: ['Pokaż szczegóły Obszaru', 'Show Zone details'],
  195: ['Skorzystaj z menu i przejdź do szczegółów Obszaru', 'Use menu to check out Zone details.'],
  196: ['Wystąpił błąd!', 'An error occured!'],
  197: ['Konto zostało aktywowane', 'An account is now active'],
  198: ['Możesz teraz w pełni korzystać ze wszystkich funkcji aplikacji.', 'You can now use all features of this app.'],
  199: ['Przejdź na stronę główną', 'Go to home page'],
  200: ['Nie udostępniasz tego Obszaru, więc nie możesz dodawać feedbacku.', 'You do not share this Zone, so you can\'t give feedback.'],
  201: ['Obecnie:', 'Current:'],
  202: ['Wybierz język:', 'Choose language:'],
  203: ['Zmień język', 'Change language'],
  204: ['Zapisano dane', 'Saved'],
  205: ['Jeśli jeszcze Cię tu nie było', 'If you do not have an account'],
  206: ['Hasło zostało zmienione', 'The password has been changed'],
  207: ['Możesz teraz zalogować się wykorzystując nowe hasło.', 'Use new password to sign in.'],
  208: ['Przejdź do logowania', 'Sign in'],
  209: ['Wprowadź nowe hasło', 'Choose new password'],
  210: ['Potwierdź zmianę', 'Confirm'],
  211: ['Niestety nic nie znaleziono. Sprawdź adres strony lub przejdź do strony głównej.', 'Nothing was found. Check if page url is correct or go to home page.'],
  212: ['Sprawdź e-mail', 'Check your e-mail'],
  213: ['Na podany adres e-mail wysłano wiadomość. Sprawdź ją i postępuj zgodnie z instrukcją. Jeśli chcesz się jednak zalogować na stare hasło, przejdź do logowania.', 'E-mail was sent to your e-mail adress. Check it out and follow the instructions. If you want to sign in with old password, go to sign in page.'],
  214: ['Zresetuj hasło', 'Reset password'],
  215: ['Wyślij', 'Send'],
  216: ['Przypomniałem sobie hasło!', 'I remember my password!'],
  217: ['Zmień hasło', 'Change password'],
  218: ['Stare hasło', 'Old password'],
  219: ['Nowe hasło', 'New password'],
  220: ['Powtórz nowe hasło', 'Repeat new password'],
  221: ['Zapisz nowe hasło', 'Save new password'],
  222: ['Hasło zostało zmienione!', 'Password has been changed!'],
  223: ['Aby w pełni korzystać ze wszystkich funkcji aplikacji, potwierdź swój adres e-mail. Aby to zrobić, kliknij link, który przesłaliśmy Ci na podany przez Ciebie adres e-mail', 'You have to confirm your e-mail to use full features of this app. To do so, click the link, we have sent you to your e-mail adress'],
  224: ['Jeśli e-mail do aktywacji konta do Ciebie nie dotarł', 'I you have not received activation e-mail'],
  225: ['Prześlij link aktywacyjny jeszcze raz', 'Send activation link one more time'],
  226: ['Na podany adres ponownie przesłaliśmy link aktywacyjny. Sprawdź e-mail i aktywuj swoje konto, aby w pełni korzystać z aplikacji.', 'Activation link was sent one more time. Go to ypur e-mail and activate the account.'],
  227: ['Zmień zdjęcie profilowe', 'Change user avatar'],
  228: ['Ustaw zdjęcie profilowe', 'Choose user avatar'],
  229: ['Zmień nazwę użytkownika', 'Change username'],
  230: ['Akceptacja zgód marketingowych', 'Marketing acceptance'],
  231: ['Zapisz', 'Save'],
  232: ['Mam już konto', 'I have the account'],
  233: ['Nie pamiętam hasła', 'I don\'t remember the password'],
  235: ['Dodaj nawyk', 'Add habit'],
  236: ['Wyjdź z Obszaru', 'Leave the Zone'],
  237: ['Dodaj nową Sferę Rozwoju', 'Add new Sphere'],
  238: ['Nikt nie zaakceptował jeszcze Twojego zaproszenia, więc nie możesz dodawać feedbacku. Poproś zaproszonych o akceptację Twoich zaproszeń.', 'No one has accepted your invitation yet, so you can\'t give feedback. Ask invited users to accept your invitation.'],
  239: ['Nowy feedback', 'New feedback'],
  240: ['Hasło musi zawierać co najmniej 8 znaków, cyfry i litery.', 'Password must be at least 8 characters long and contain at least one digit and one letter.'],
};

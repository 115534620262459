import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import HabitsTable from '../../components/organisms/Habits/HabitsTable/HabitsTable';
import { Heading } from '../../components/atoms';
import { useTr } from '../../hooks';

const HabitsView = ({ zoneId }) => {
  const habitsCount = useSelector(({ habitReducer }) => habitReducer.habitsCount);
  const t181 = useTr(181);

  return (
    <section className="view">
      <Heading>{t181} {habitsCount > 0 && `(${habitsCount})`}</Heading>
      <HabitsTable zoneId={zoneId} />
    </section>
  );
};

HabitsView.propTypes = {
  zoneId: PropTypes.string,
};

HabitsView.defaultProps = {
  zoneId: null,
};

export default HabitsView;

// NEW ACTIONS
export const NEW_USER_UPDATE_DATA = 'NEW_USER_UPDATE_DATA';

// OLD ACTIONS
// CLEAR FORMS ERRORS
export const ACTION_CLEAR_ERRORS = 'ACTION_CLEAR_ERRORS';
export const FEEDBACK_CLEAR_ERRORS = 'FEEDBACK_CLEAR_ERRORS';
export const HABIT_CLEAR_ERRORS = 'HABIT_CLEAR_ERRORS';
export const OBJECTIVE_CLEAR_ERRORS = 'OBJECTIVE_CLEAR_ERRORS';
export const SHARED_ZONE_CLEAR_ERRORS = 'SHARED_ZONE_CLEAR_ERRORS';
export const SPHERES_CLEAR_ERRORS = 'SPHERES_CLEAR_ERRORS';
export const SUB_OBJECTIVE_CLEAR_ERRORS = 'SUB_OBJECTIVE_CLEAR_ERRORS';
export const USER_CLEAR_ERRORS = 'USER_CLEAR_ERRORS';
export const ZONE_CLEAR_ERRORS = 'ZONE_CLEAR_ERRORS';

// helpers actions
export const SET_INITIAL_GRID_ACTION = 'SET_INITIAL_GRID_ACTION';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';

// user action types
// AUTHENTICATE - LOGIN or REGISTER
export const USER_AUTHENTICATION_REQUEST = 'USER_AUTHENTICATION_REQUEST';

// UPDATE USER DATA
export const USER_UPDATE_DATA = 'USER_UPDATE_DATA';

// CONFIRM EMAIL
export const USER_CONFIRM_EMAIL_SUCCESS = 'USER_CONFIRM_EMAIL_SUCCESS';
export const USER_CONFIRM_EMAIL_FAILURE = 'USER_CONFIRM_EMAIL_FAILURE';

// CHANGE USER PROFILE DATA
export const USER_CHANGE_PROFILE_SUCCESS = 'USER_CHANGE_PROFILE_SUCCESS';
export const USER_CHANGE_PROFILE_FAILURE = 'USER_CHANGE_PROFILE_FAILURE';

// CHANGE USER LANGUAGE
export const USER_CHANGE_LANGUAGE_SUCCESS = 'USER_CHANGE_LANGUAGE_SUCCESS';
export const USER_CHANGE_LANGUAGE_FAILURE = 'USER_CHANGE_LANGUAGE_FAILURE';

// SPHERES
export const NEW_SPHERES_LIST_GET_REQUEST = 'NEW_SPHERES_LIST_GET_REQUEST';
export const NEW_SPHERES_LIST_GET_SUCCESS = 'NEW_SPHERES_LIST_GET_SUCCESS';
export const NEW_SPHERES_LIST_GET_FAILURE = 'NEW_SPHERES_LIST_GET_FAILURE';

export const NEW_SPHERE_POST_PATCH_REQUEST = 'NEW_SPHERE_POST_PATCH_REQUEST';
export const NEW_SPHERE_POST_PATCH_SUCCESS = 'NEW_SPHERE_POST_PATCH_SUCCESS';
export const NEW_SPHERE_POST_PATCH_FAILURE = 'NEW_SPHERE_POST_PATCH_FAILURE';

export const NEW_SPHERE_DELETE_REQUEST = 'NEW_SPHERE_DELETE_REQUEST';
export const NEW_SPHERE_DELETE_SUCCESS = 'NEW_SPHERE_DELETE_SUCCESS';
export const NEW_SPHERE_DELETE_FAILURE = 'NEW_SPHERE_DELETE_FAILURE';

// ZONE //
// ADD
export const NEW_ZONE_ADD_REQUEST = 'NEW_ZONE_ADD_REQUEST';
export const NEW_ZONE_ADD_SUCCESS = 'NEW_ZONE_ADD_SUCCESS';
export const NEW_ZONE_ADD_FAILURE = 'NEW_ZONE_ADD_FAILURE';

// GET/DELETE
export const ZONE_MANAGE_FAILURE = 'ZONE_MANAGE_FAILURE';
export const ZONE_MANAGE_REQUEST = 'ZONE_MANAGE_REQUEST';
export const ZONE_MANAGE_SUCCESS = 'ZONE_MANAGE_SUCCESS';

// CLEAR DATA
export const ZONE_CLEAR_DATA = 'ZONE_CLEAR_DATA';

// HABIT //
// LIST_GET
export const HABITS_LIST_GET_FAILURE = 'HABITS_LIST_GET_FAILURE';
export const HABITS_LIST_GET_REQUEST = 'HABITS_LIST_GET_REQUEST';
export const HABITS_LIST_GET_SUCCESS = 'HABITS_LIST_GET_SUCCESS';

// ADD / EDIT
export const HABIT_ADD_EDIT_FAILURE = 'HABIT_ADD_EDIT_FAILURE';
export const HABIT_ADD_EDIT_REQUEST = 'HABIT_ADD_EDIT_REQUEST';
export const HABIT_ADD_EDIT_SUCCESS = 'HABIT_ADD_EDIT_SUCCESS';

// GET / DEL
export const HABIT_GET_DEL_FAILURE = 'HABIT_GET_DEL_FAILURE';
export const HABIT_GET_DEL_REQUEST = 'HABIT_GET_DEL_REQUEST';
export const HABIT_GET_DEL_SUCCESS = 'HABIT_GET_DEL_SUCCESS';

// DEVELOP
export const HABIT_DEVELOP_FAILURE = 'HABIT_DEVELOP_FAILURE';
export const HABIT_DEVELOP_REQUEST = 'HABIT_DEVELOP_REQUEST';
export const HABIT_DEVELOP_SUCCESS = 'HABIT_DEVELOP_SUCCESS';

// OBJECTIVE //
// LIST_GET
export const OBJECTIVES_LIST_GET_FAILURE = 'OBJECTIVES_LIST_GET_FAILURE';
export const OBJECTIVES_LIST_GET_REQUEST = 'OBJECTIVES_LIST_GET_REQUEST';
export const OBJECTIVES_LIST_GET_SUCCESS = 'OBJECTIVES_LIST_GET_SUCCESS';

// ADD / EDIT
export const OBJECTIVE_ADD_EDIT_FAILURE = 'OBJECTIVE_ADD_EDIT_FAILURE';
export const OBJECTIVE_ADD_EDIT_REQUEST = 'OBJECTIVE_ADD_EDIT_REQUEST';
export const OBJECTIVE_ADD_EDIT_SUCCESS = 'OBJECTIVE_ADD_EDIT_SUCCESS';

// GET / DEL
export const OBJECTIVE_GET_DEL_FAILURE = 'OBJECTIVE_GET_DEL_FAILURE';
export const OBJECTIVE_GET_DEL_REQUEST = 'OBJECTIVE_GET_DEL_REQUEST';
export const OBJECTIVE_GET_DEL_SUCCESS = 'OBJECTIVE_GET_DEL_SUCCESS';

// DONE
export const OBJECTIVE_DONE_FAILURE = 'OBJECTIVE_DONE_FAILURE';
export const OBJECTIVE_DONE_REQUEST = 'OBJECTIVE_DONE_REQUEST';
export const OBJECTIVE_DONE_SUCCESS = 'OBJECTIVE_DONE_SUCCESS';

// SUBOBJECTIVE //
// LIST_GET
export const SUB_OBJECTIVES_LIST_GET_FAILURE = 'SUB_OBJECTIVES_LIST_GET_FAILURE';
export const SUB_OBJECTIVES_LIST_GET_REQUEST = 'SUB_OBJECTIVES_LIST_GET_REQUEST';
export const SUB_OBJECTIVES_LIST_GET_SUCCESS = 'SUB_OBJECTIVES_LIST_GET_SUCCESS';

// LIST_DONE_GET
export const SUB_OBJECTIVES_LIST_DONE_GET_FAILURE = 'SUB_OBJECTIVES_LIST_DONE_GET_FAILURE';
export const SUB_OBJECTIVES_LIST_DONE_GET_REQUEST = 'SUB_OBJECTIVES_LIST_DONE_GET_REQUEST';
export const SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS = 'SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS';

// ADD / EDIT
export const SUB_OBJECTIVE_ADD_EDIT_FAILURE = 'SUB_OBJECTIVE_ADD_EDIT_FAILURE';
export const SUB_OBJECTIVE_ADD_EDIT_REQUEST = 'SUB_OBJECTIVE_ADD_EDIT_REQUEST';
export const SUB_OBJECTIVE_ADD_EDIT_SUCCESS = 'SUB_OBJECTIVE_ADD_EDIT_SUCCESS';

// GET / DEL
export const SUB_OBJECTIVE_GET_DEL_FAILURE = 'SUB_OBJECTIVE_GET_DEL_FAILURE';
export const SUB_OBJECTIVE_GET_DEL_REQUEST = 'SUB_OBJECTIVE_GET_DEL_REQUEST';
export const SUB_OBJECTIVE_GET_DEL_SUCCESS = 'SUB_OBJECTIVE_GET_DEL_SUCCESS';

// DONE
export const SUB_OBJECTIVE_DONE_FAILURE = 'SUB_OBJECTIVE_DONE_FAILURE';
export const SUB_OBJECTIVE_DONE_REQUEST = 'SUB_OBJECTIVE_DONE_REQUEST';
export const SUB_OBJECTIVE_DONE_SUCCESS = 'SUB_OBJECTIVE_DONE_SUCCESS';

// SHARED ZONE //
// LIST_MY_GET
export const SHARED_ZONES_LIST_MY_GET_FAILURE = 'SHARED_ZONES_LIST_MY_GET_FAILURE';
export const SHARED_ZONES_LIST_GET_REQUEST = 'SHARED_ZONES_LIST_GET_REQUEST';
export const SHARED_ZONES_LIST_MY_GET_SUCCESS = 'SHARED_ZONES_LIST_MY_GET_SUCCESS';

// LIST_TO_ME_GET
export const SHARED_ZONES_LIST_TO_ME_GET_FAILURE = 'SHARED_ZONES_LIST_TO_ME_GET_FAILURE';
export const SHARED_ZONES_LIST_TO_ME_GET_SUCCESS = 'SHARED_ZONES_LIST_TO_ME_GET_SUCCESS';

// ADD
export const SHARED_ZONE_ADD_FAILURE = 'SHARED_ZONE_ADD_FAILURE';
export const SHARED_ZONE_ADD_REQUEST = 'SHARED_ZONE_ADD_REQUEST';
export const SHARED_ZONE_ADD_SUCCESS = 'SHARED_ZONE_ADD_SUCCESS';

// GET / DEL / ACCEPT
export const SHARED_ZONE_GET_DEL_ACCEPT_FAILURE = 'SHARED_ZONE_GET_DEL_ACCEPT_FAILURE';
export const SHARED_ZONE_GET_DEL_ACCEPT_REQUEST = 'SHARED_ZONE_GET_DEL_ACCEPT_REQUEST';
export const SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS = 'SHARED_ZONE_GET_DEL_ACCEPT_SUCCESS';

// FEEDBACK //
// LIST_GET
export const FEEDBACK_LIST_GET_FAILURE = 'FEEDBACK_LIST_GET_FAILURE';
export const FEEDBACK_LIST_GET_REQUEST = 'FEEDBACK_LIST_GET_REQUEST';
export const FEEDBACK_LIST_GET_SUCCESS = 'FEEDBACK_LIST_GET_SUCCESS';

// ADD
export const FEEDBACK_ADD_FAILURE = 'FEEDBACK_ADD_FAILURE';
export const FEEDBACK_ADD_REQUEST = 'FEEDBACK_ADD_REQUEST';
export const FEEDBACK_ADD_SUCCESS = 'FEEDBACK_ADD_SUCCESS';

// GET UNREAD
export const FEEDBACK_UNREAD_COUNT_FAILURE = 'FEEDBACK_UNREAD_COUNT_FAILURE';
export const FEEDBACK_UNREAD_COUNT_REQUEST = 'FEEDBACK_UNREAD_COUNT_REQUEST';
export const FEEDBACK_UNREAD_COUNT_SUCCESS = 'FEEDBACK_UNREAD_COUNT_SUCCESS';

// ACTIONS //
// ADD
export const ACTION_ADD_EDIT_FAILURE = 'ACTION_ADD_EDIT_FAILURE';
export const ACTION_ADD_EDIT_REQUEST = 'ACTION_ADD_EDIT_REQUEST';
export const ACTION_ADD_EDIT_SUCCESS = 'ACTION_ADD_EDIT_SUCCESS';

// EDIT/DEL
export const ACTION_DEL_DONE_FAILURE = 'ACTION_DEL_DONE_FAILURE';
export const ACTION_DEL_DONE_REQUEST = 'ACTION_DEL_DONE_REQUEST';
export const ACTION_DEL_DONE_SUCCESS = 'ACTION_DEL_DONE_SUCCESS';

// LIST_GET
export const ACTION_LIST_GET_FAILURE = 'ACTION_LIST_GET_FAILURE';
export const ACTION_LIST_GET_TODAY_SUCCESS = 'ACTION_LIST_GET_TODAY_SUCCESS';
export const ACTION_LIST_GET_REQUEST = 'ACTION_LIST_GET_REQUEST';
export const ACTION_LIST_GET_SUCCESS = 'ACTION_LIST_GET_SUCCESS';

// WEEK_POSITION
export const ACTION_WEEK_RANKING_FAILURE = 'ACTION_WEEK_RANKING_FAILURE';
export const ACTION_WEEK_RANKING_REQUEST = 'ACTION_WEEK_RANKING_REQUEST';
export const ACTION_WEEK_RANKING_SUCCESS = 'ACTION_WEEK_RANKING_SUCCESS';

// WEEKLY DONE
export const ACTION_WEEKLY_DONE_FAILURE = 'ACTION_WEEKLY_DONE_FAILURE';
export const ACTION_WEEKLY_DONE_REQUEST = 'ACTION_WEEKLY_DONE_REQUEST';
export const ACTION_WEEKLY_DONE_SUCCESS = 'ACTION_WEEKLY_DONE_SUCCESS';

// PASSED DAYS
export const ACTION_PASSED_DAYS_FAILURE = 'ACTION_PASSED_DAYS_FAILURE';
export const ACTION_PASSED_DAYS_REQUEST = 'ACTION_PASSED_DAYS_REQUEST';
export const ACTION_PASSED_DAYS_SUCCESS = 'ACTION_PASSED_DAYS_SUCCESS';

// SET ACTIONS DATE
export const ACTION_SET_DATE = 'ACTION_SET_DATE';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar as AvatarMui } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import './Avatar.sass';

const AvatarStyled = styled.div`
  background-color: ${({ isUrl, theme }) => isUrl ? theme.colorGreyLite : theme.colorPrimary};
  width: ${({ size }) => size || '10rem'};
  height: ${({ size }) => size || '10rem'};
`;

export const Avatar = ({
  url,
  username,
  size,
}) => (
  <AvatarStyled className="avatar__container" isUrl={url} size={size}>
    {url
      ? <AvatarMui alt={`Avatar użytkownika ${username}`} src={url} className="avatar__img" />
      : <AccountCircle className="avatar__icon" />}
  </AvatarStyled>
);

Avatar.propTypes = {
  url: PropTypes.string,
  username: PropTypes.string,
  size: PropTypes.string,
};

Avatar.defaultProps = {
  url: null,
  username: null,
  size: null,
};

import { createTheme } from '@mui/material/styles';

const fontSize = 16; // px
const htmlFontSize = 10;
const coef = fontSize / 16;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#930DD4',
    },
    secondary: {
      main: '#EE024A',
    },
    info: {
      main: '#cccccc',
    },
    warning: {
      main: '#d7ff00',
    },
    error: {
      main: '#ff0000',
    },
  },
  typography: {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    fontFamily: [
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeight: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'block',
          borderRadius: '2rem',
          fontSize: '1.4rem',
          fontWeight: 700,
          textTransform: 'none',
          textAlign: 'center',
        },
        outlinedSecondary: {
          borderColor: '#FF0030',
          color: '#000000',
        },
        containded: {
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            margin: '.8rem 0',
            boxShadow: 'none',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 700,
          textAlign: 'center',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '4.8rem',
          width: '4.8rem',
        },
      },
    },
  },
});

import React from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ErrorOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { useTr } from '../hooks';
import { Text } from '../components/atoms';
import { routes } from '../routes';
import PrivateRoute from '../components/routing/PrivateRoute';
import SphereBig from '../components/organisms/Spheres/SphereBig/SphereBig';
import SpheresList from '../components/organisms/Spheres/SpheresList/SpheresList';

const SphereView = () => {
  const t145 = useTr(145);
  const t146 = useTr(146);

  const newMainSphereId = useSelector(({ spheresReducer }) => spheresReducer.newMainSphereId);
  const userEmailConfirmed = useSelector(({ newUserReducer }) => newUserReducer.user.email_confirmed);

  return (
    <>
      <SpheresList activeSpheresList />
      {!userEmailConfirmed
        && (
          <Text warning className="center-text">
            <ErrorOutline />
            {t146}<Link to={routes.ACCOUNT_SETTINGS_URL}>{t145}</Link>
          </Text>
        )}
      <Switch>
        <PrivateRoute
          exact
          path={routes.SPHERE_URL}
        >
          <SphereBig />
        </PrivateRoute>
        <Route path={routes.HOME_URL}>
          <Redirect to={newMainSphereId ? `/sfera/${newMainSphereId}` : routes.HOME_URL} />
        </Route>
      </Switch>
    </>
  );
};

export default SphereView;

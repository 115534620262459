import {
  SPHERES_CLEAR_ERRORS,
  NEW_SPHERES_LIST_GET_REQUEST,
  NEW_SPHERES_LIST_GET_SUCCESS,
  NEW_SPHERES_LIST_GET_FAILURE,
  NEW_SPHERE_POST_PATCH_REQUEST,
  NEW_SPHERE_POST_PATCH_SUCCESS,
  NEW_SPHERE_POST_PATCH_FAILURE,
  NEW_SPHERE_DELETE_REQUEST,
  NEW_SPHERE_DELETE_SUCCESS,
  NEW_SPHERE_DELETE_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  newSphereDataId: null,
  newSpheresListCount: 0,
  newListNext: null,
  newListPrev: null,
  newListResults: [],
  newMainSphereId: null,
  newSpheresListLoading: false,
  newEditAddSphereLoading: false,
  newDeleteSphereLoading: false,
  newRedirectToHome: false,
  newGetSpheresListError: null,
  newEditAddSphereError: null,
  newEditAddSphereNameError: null,
  newEditAddSpherePhotoUrlError: null,
  newEditAddSphereMainError: null,
  newEditAddSphereNonFieldError: null,
  newDeleteSphereNonFieldError: null,
};

export const spheresReducer = (state = initialState, action) => {
  const {
    newSpheresListData,
    newSphereDataId,
    newGetSpheresListError,
    newDeleteSphereNonFieldError,
    newErrors,
    type,
  } = action;

  switch (type) {
    case NEW_SPHERES_LIST_GET_REQUEST:
      return {
        ...state,
        newGetSpheresListError: null,
        newRedirectToHome: false,
        newSpheresListLoading: true,
      };
    case NEW_SPHERES_LIST_GET_SUCCESS:
      return {
        ...state,
        newSphereDataId: null,
        newSpheresListCount: newSpheresListData.count,
        newListNext: newSpheresListData.next,
        newListPrev: newSpheresListData.previous,
        newListResults: newSpheresListData.results,
        newMainSphereId: newSpheresListData.results.length > 0 ? newSpheresListData.results.find((sp) => sp.is_main === true).id : null,
        newRedirectToHome: newSpheresListData.results.length === 0,
        newSpheresListLoading: false,
      };
    case NEW_SPHERES_LIST_GET_FAILURE:
      return {
        ...state,
        newGetSpheresListError,
        newSpheresListLoading: false,
      };

    case NEW_SPHERE_POST_PATCH_REQUEST:
      return {
        ...state,
        newEditAddSphereError: null,
        newEditAddSphereLoading: true,
      };
    case NEW_SPHERE_POST_PATCH_SUCCESS:
      return {
        ...state,
        newSphereDataId,
        newEditAddSphereLoading: false,
      };
    case NEW_SPHERE_POST_PATCH_FAILURE:
      return {
        ...state,
        newEditAddSphereNameError: newErrors.newEditAddSphereNameError,
        newEditAddSpherePhotoUrlError: newErrors.newEditAddSpherePhotoUrlError,
        newEditAddSphereMainError: newErrors.newEditAddSphereMainError,
        newEditAddSphereNonFieldError: newErrors.newEditAddSphereNonFieldError,
        newEditAddSphereLoading: false,
      };

    case NEW_SPHERE_DELETE_REQUEST:
      return {
        ...state,
        newDeleteSphereNonFieldError: null,
        newDeleteSphereLoading: true,
      };
    case NEW_SPHERE_DELETE_SUCCESS:
      return {
        ...state,
        newSphereDataId: null,
        newDeleteSphereLoading: false,
      };
    case NEW_SPHERE_DELETE_FAILURE:
      return {
        ...state,
        newDeleteSphereNonFieldError,
        newDeleteSphereLoading: false,
      };
    case SPHERES_CLEAR_ERRORS:
      return {
        ...state,
        errorsManageSphere: null,
        newEditAddSphereNameError: null,
        newEditAddSpherePhotoUrlError: null,
        newEditAddSphereMainError: null,
        newEditAddSphereNonFieldError: null,
      };
    default:
      return state;
  }
};

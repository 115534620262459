import moment from 'moment';
import { globalDateFormat } from '../helpers';

import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  ACTIONS_API_URL,
} from '../apiRequests';

import {
  ACTION_CLEAR_ERRORS,
  ACTION_ADD_EDIT_FAILURE,
  ACTION_ADD_EDIT_REQUEST,
  ACTION_ADD_EDIT_SUCCESS,
  ACTION_DEL_DONE_FAILURE,
  ACTION_DEL_DONE_REQUEST,
  ACTION_DEL_DONE_SUCCESS,
  ACTION_LIST_GET_FAILURE,
  ACTION_LIST_GET_REQUEST,
  ACTION_LIST_GET_SUCCESS,
  ACTION_LIST_GET_TODAY_SUCCESS,
  ACTION_WEEK_RANKING_FAILURE,
  ACTION_WEEK_RANKING_REQUEST,
  ACTION_WEEK_RANKING_SUCCESS,
  ACTION_WEEKLY_DONE_FAILURE,
  ACTION_WEEKLY_DONE_REQUEST,
  ACTION_WEEKLY_DONE_SUCCESS,
  ACTION_PASSED_DAYS_FAILURE,
  ACTION_PASSED_DAYS_REQUEST,
  ACTION_PASSED_DAYS_SUCCESS,
  ACTION_SET_DATE,
} from './actionTypes';

const today = moment().format(globalDateFormat);

// ACTIONS LIST GET
export const actionsListGetAction = (date = today) => (dispatch) => {
  dispatch({ type: ACTION_LIST_GET_REQUEST });

  return axiosGet(
    `${ACTIONS_API_URL}/for-day?date=${date}`,
    { },
  ).then((response) => {
    dispatch({
      type: ACTION_LIST_GET_SUCCESS,
      actionsList: response.data.data,
    });

    if (!date || date === today) {
      dispatch({
        type: ACTION_LIST_GET_TODAY_SUCCESS,
        todayActionsDone: response.data.data,
      });
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: ACTION_LIST_GET_FAILURE,
          errors: {
            date: response.data.data.date ? response.data.data.date : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors : null,
          },
        });
      } else if (response.status === 403) {
        dispatch({
          type: ACTION_LIST_GET_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_LIST_GET_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// ACTION ADD/EDIT
export const actionAddEditAction = (description, date, id = null, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: ACTION_ADD_EDIT_REQUEST });

  const method = id ? axiosPatch : axiosPost;

  return method(
    `${ACTIONS_API_URL}${id ? `/${id}` : '/create'}`,
    { description, date, id },
  ).then((response) => {
    dispatch({
      type: ACTION_ADD_EDIT_SUCCESS,
      actionData: response && response.data.data,
      editedId: id,
    });
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: ACTION_ADD_EDIT_FAILURE,
          errors: {
            description: response.data.data.description ? response.data.data.description : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors : null,
          },
        });
      } else if (response && (response.status === 403 || response.status === 404)) {
        dispatch({
          type: ACTION_ADD_EDIT_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_ADD_EDIT_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response && response.status}.` },
        });
      }
    }
  });
};

// ACTION DEL/DONE
export const actionDelDoneAction = (
  id,
  date = today,
  done = false,
) => (dispatch) => {
  dispatch({ type: ACTION_DEL_DONE_REQUEST });

  const method = done ? axiosPatch : axiosDelete;

  return method(
    `${ACTIONS_API_URL}/${id}${done ? '/done' : ''}`,
    {},
  ).then((response) => {
    dispatch({
      type: ACTION_DEL_DONE_SUCCESS,
      actionData: response.data ? response.data.data : null,
    });
    actionsListGetAction(date)(dispatch);
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: ACTION_DEL_DONE_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_DEL_DONE_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// ACTION WEEK RANKING
export const actionWeekRankingAction = () => (dispatch) => {
  dispatch({ type: ACTION_WEEK_RANKING_REQUEST });

  return axiosGet(
    `${ACTIONS_API_URL}/week-ranking`,
    {},
  ).then((response) => {
    dispatch({
      type: ACTION_WEEK_RANKING_SUCCESS,
      weekRanking: response.data ? response.data.data : null,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403) {
        dispatch({
          type: ACTION_WEEK_RANKING_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_WEEK_RANKING_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// ACTIONS WEEKLY DONE
export const actionWeeklyDoneAction = (year = null, week = null) => (dispatch) => {
  dispatch({ type: ACTION_WEEKLY_DONE_REQUEST });

  return axiosGet(
    `${ACTIONS_API_URL}/week-actions?year=${year}&week=${week}`,
    {},
  ).then((response) => {
    dispatch({
      type: ACTION_WEEKLY_DONE_SUCCESS,
      actionsWeeklyDoneList: response.data.data,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: ACTION_WEEKLY_DONE_FAILURE,
          errors: {
            year: response.data.data.year ? response.data.data.year[0] : null,
            week: response.data.data.week ? response.data.data.week[0] : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors[0] : null,
          },
        });
      } else if (response.status === 403) {
        dispatch({
          type: ACTION_WEEKLY_DONE_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else if (response.status === 404) {
        dispatch({
          type: ACTION_WEEKLY_DONE_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_WEEKLY_DONE_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// ACTIONS PASSED DAYS
export const actionsPassedDaysAction = (from, to) => (dispatch) => {
  dispatch({ type: ACTION_PASSED_DAYS_REQUEST });

  return axiosGet(
    `${ACTIONS_API_URL}/passed-days`,
    {
      from,
      to,
    },
  ).then((response) => {
    dispatch({
      type: ACTION_PASSED_DAYS_SUCCESS,
      actionsPassedDaysList: response.data.data,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: ACTION_PASSED_DAYS_FAILURE,
          errors: {
            from: response.data.data.from ? response.data.data.year : null,
            to: response.data.data.to ? response.data.data.week : null,
          },
        });
      } else if (response.status === 403) {
        dispatch({
          type: ACTION_PASSED_DAYS_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else if (response.status === 404) {
        dispatch({
          type: ACTION_PASSED_DAYS_FAILURE,
          errors: {
            nonField: response.data.data.detail ? response.data.data.detail : null,
          },
        });
      } else {
        dispatch({
          type: ACTION_PASSED_DAYS_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearActionsErrorsAction = () => (dispatch) => {
  dispatch({ type: ACTION_CLEAR_ERRORS });
};

// SET ACTIONS DATE
export const setActionsDateAction = (actionsDate) => (dispatch) => {
  dispatch({
    type: ACTION_SET_DATE,
    actionsDate,
  });
};

/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export const SrOnly = styled.span`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  clip: rect(0, 0);
  opacity: 0;
  overflow: hidden;
`;

import {
  SUB_OBJECTIVES_LIST_GET_FAILURE,
  SUB_OBJECTIVES_LIST_GET_REQUEST,
  SUB_OBJECTIVES_LIST_GET_SUCCESS,
  SUB_OBJECTIVES_LIST_DONE_GET_FAILURE,
  SUB_OBJECTIVES_LIST_DONE_GET_REQUEST,
  SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS,
  SUB_OBJECTIVE_ADD_EDIT_FAILURE,
  SUB_OBJECTIVE_ADD_EDIT_REQUEST,
  SUB_OBJECTIVE_ADD_EDIT_SUCCESS,
  SUB_OBJECTIVE_GET_DEL_REQUEST,
  SUB_OBJECTIVE_GET_DEL_SUCCESS,
  SUB_OBJECTIVE_GET_DEL_FAILURE,
  SUB_OBJECTIVE_DONE_REQUEST,
  SUB_OBJECTIVE_DONE_SUCCESS,
  SUB_OBJECTIVE_DONE_FAILURE,
  SUB_OBJECTIVE_CLEAR_ERRORS,
} from '../actions/actionTypes';

const subObjective = {
  id: null,
  name: null,
  done: false,
  is_active: true,
  done_at: null,
  objective: null,
};

const initialState = {
  subObjective,
  subObjectiveMode: null,
  subObjectivesCount: null,
  subObjectivesDoneCount: null,
  subObjectivesList: {},
  subObjectivesListDone: {},
  errorsSubObjectiveAddEdit: null,
  errorsSubObjectiveGetDel: null,
  errorsSubObjectivesListGet: null,
  errorsSubObjectivesListDoneGet: null,
  errorsSubObjectiveDone: null,
  subObjectivesListNext: null,
  subObjectivesListDoneNext: null,
  subObjectivesListPrevious: null,
  subObjectivesListDonePrevious: null,
  subObjectiveLoading: false,
};

export const subObjectiveReducer = (state = initialState, action) => {
  const {
    errors,
    errorsSubObjectivesListGet,
    errorsSubObjectivesListDoneGet,
    errorsSubObjectiveGetDel,
    errorsSubObjectiveDone,
    objectiveId,
    subObjectiveData,
    subObjectiveMode,
    subObjectivesResultsList,
    subObjectivesResultsListDone,
    subObjectivesCount,
    subObjectivesDoneCount,
    subObjectivesListNext,
    subObjectivesListDoneNext,
    subObjectivesListPrevious,
    subObjectivesListDonePrevious,
    type,
  } = action;

  switch (type) {
    case SUB_OBJECTIVES_LIST_GET_REQUEST:
      return {
        ...state,
        errorsSubObjectivesListGet: null,
        subObjectiveLoading: true,
      };
    case SUB_OBJECTIVES_LIST_DONE_GET_REQUEST:
      return {
        ...state,
        errorsSubObjectivesListDoneGet: null,
        subObjectiveLoading: true,
      };
    case SUB_OBJECTIVES_LIST_GET_SUCCESS:
      return {
        ...state,
        subObjectivesList: {
          ...state.subObjectivesList,
          ...{ [objectiveId]: subObjectivesResultsList },
        },
        subObjectivesCount: {
          ...state.subObjectivesCount,
          ...{ [objectiveId]: subObjectivesCount },
        },
        subObjectivesListNext: {
          ...state.subObjectivesListNext,
          ...{ [objectiveId]: subObjectivesListNext },
        },
        subObjectivesListPrevious: {
          ...state.subObjectivesListPrevious,
          ...{ [objectiveId]: subObjectivesListPrevious },
        },
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVES_LIST_DONE_GET_SUCCESS:
      return {
        ...state,
        subObjectivesListDone: {
          ...state.subObjectivesListDone,
          ...{ [objectiveId]: subObjectivesResultsListDone },
        },
        subObjectivesDoneCount: {
          ...state.subObjectivesDoneCount,
          ...{ [objectiveId]: subObjectivesDoneCount },
        },
        subObjectivesListDoneNext: {
          ...state.subObjectivesListDoneNext,
          ...{ [objectiveId]: subObjectivesListDoneNext },
        },
        subObjectivesListDonePrevious: {
          ...state.subObjectivesListDonePrevious,
          ...{ [objectiveId]: subObjectivesListDonePrevious },
        },
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVES_LIST_GET_FAILURE:
      return {
        ...state,
        errorsSubObjectivesListGet,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVES_LIST_DONE_GET_FAILURE:
      return {
        ...state,
        errorsSubObjectivesListDoneGet,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_ADD_EDIT_REQUEST:
      return {
        ...state,
        errorsSubObjectiveAddEdit: null,
        subObjectiveLoading: true,
      };
    case SUB_OBJECTIVE_ADD_EDIT_SUCCESS:
      return {
        ...state,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_ADD_EDIT_FAILURE:
      return {
        ...state,
        errorsSubObjectiveAddEdit: {
          objective: errors.objective || null,
          name: errors.name || null,
          nonField: errors.nonField || null,
        },
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_GET_DEL_REQUEST:
      return {
        ...state,
        errorsSubObjectiveGetDel: null,
        subObjectiveLoading: true,
      };
    case SUB_OBJECTIVE_GET_DEL_SUCCESS:
      return {
        ...state,
        subObjective: { ...subObjectiveData },
        subObjectiveMode,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_GET_DEL_FAILURE:
      return {
        ...state,
        errorsSubObjectiveGetDel,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_DONE_REQUEST:
      return {
        ...state,
        errorsSubObjectiveDone: null,
        subObjectiveLoading: true,
      };
    case SUB_OBJECTIVE_DONE_SUCCESS:
      return {
        ...state,
        subObjective: { ...subObjectiveData },
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_DONE_FAILURE:
      return {
        ...state,
        errorsSubObjectiveDone,
        subObjectiveLoading: false,
      };
    case SUB_OBJECTIVE_CLEAR_ERRORS:
      return {
        ...state,
        errorsSubObjectiveAddEdit: null,
        errorsSubObjectiveGetDel: null,
        errorsSubObjectiveDone: null,
        errorsSubObjectivesListGet: null,
        errorsSubObjectivesListDoneGet: null,
      };
    default:
      return state;
  }
};

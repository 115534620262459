import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ObjectivesTable from '../../components/organisms/ObjectivesTable/ObjectivesTable';
import { Heading } from '../../components/atoms';
import { useTr } from '../../hooks';

const ObjectivesView = ({ zoneId }) => {
  const objectivesCount = useSelector(({ objectiveReducer }) => objectiveReducer.objectivesCount);
  const t182 = useTr(182);

  return (
    <section className="view">
      <Heading>{t182} {objectivesCount > 0 && `(${objectivesCount})`}</Heading>
      <ObjectivesTable zoneId={zoneId} />
    </section>
  );
};

ObjectivesView.propTypes = {
  zoneId: PropTypes.string,
};

ObjectivesView.defaultProps = {
  zoneId: null,
};

export default ObjectivesView;

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
    ${reset}

    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    * {
        outline-color: #EE024A;
    }

    html {
        font-size: 62.5%; // 1rem === 16px;
        scroll-behavior: smooth;
    }

    body {
        min-height: 100vh;
        font-family: "Montserrat";
        font-size: 1.6rem; // happy rems - the base is still 16px;
    }

    a {
        text-decoration: none;
    }

    .btn-container {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: flex-end;
        margin: 1rem 0;
    }
`;

export default GlobalStyle;

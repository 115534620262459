import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Loader,
  Text,
} from '../components/atoms';
import { VisionManagePhotos } from '../components/molecules';
import VisionManageForm from '../components/organisms/VisionManageForm/VisionManageForm';
import { useAxios, useTr } from '../hooks';
import {
  ZONE_API_URL,
  VISION_API_URL,
  axiosDelete,
  axiosGet,
} from '../apiRequests';

const VisionView = ({ zoneId }) => {
  const t183 = useTr(183);

  const {
    isLoading,
    axiosFetch: axiosFetchGet,
    responseData: responseDataGet,
    responseError: responseErrorGet,
  } = useAxios({
    fetchOnMount: true,
    method: axiosGet,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}`,
    data: { zone_id: parseFloat(zoneId) },
  });

  const deleteVisionCallbackSuccess = () => {
    axiosFetchGet();
  };

  const {
    axiosFetch: axiosFetchDel,
    responseError: responseErrorDel,
  } = useAxios({
    method: axiosDelete,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}`,
    data: { zone_id: parseFloat(zoneId) },
    callbackSuccess: deleteVisionCallbackSuccess,
  });

  const handleDeleteVision = () => {
    axiosFetchDel();
  };

  return (
    <>
      <Heading>{t183}</Heading>
      {isLoading ? <Loader view /> : (
        <>
          {responseErrorGet && <Text error>{responseErrorGet.detail}</Text>}
          {responseDataGet && (
            <>
              <VisionManageForm
                handleDeleteVision={handleDeleteVision}
                responseErrorDel={responseErrorDel && <Text error>{responseErrorDel.detail}</Text>}
                visionText={responseDataGet.data.text || ''}
                zoneId={zoneId}
              />
              <VisionManagePhotos photosList={responseDataGet.data.photos || null} zoneId={zoneId} />
            </>
          )}
        </>
      )}
    </>
  );
};

VisionView.propTypes = {
  zoneId: PropTypes.string,
};

VisionView.defaultProps = {
  zoneId: null,
};

export default VisionView;

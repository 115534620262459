import { useEffect, useState } from 'react';

import { fetchData } from '../apiRequests';

export const useAxiosGetNew = ({
  method = 'GET',
  url,
  body = {},
  skipRequest = false,
  callbackSuccess = null,
  callbackError = null,
} = {}) => {
  const [responseData, setResponseData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [isFetching, setIsFetching] = useState(!skipRequest);

  useEffect(() => {
    if (skipRequest) return;

    setIsFetching(true);
    setResponseError(null);

    fetchData({
      method,
      url,
      body,
      callbackSuccess: ({ data: data_, status }) => {
        setResponseData(data_);
        setIsFetching(false);
        if (callbackSuccess) {
          callbackSuccess({ data_, status });
        }
      },
      callbackError: ({ data: data_, status }) => {
        setResponseData({});
        setResponseError(data_);
        setIsFetching(false);
        if (callbackError) {
          callbackError({ data_, status });
        }
      },
    });
  }, [url, skipRequest]);

  return {
    responseData,
    responseError,
    isFetching,
  };
};

export const useAxiosPostNew = ({
  method = 'POST',
  url,
  body = {},
  isSaving,
  setIsSaving,
  callbackSuccess = null,
  callbackError = null,
} = {}) => {
  const [responseData, setResponseData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isSaving) return;

    setResponseError(null);
    setIsFetching(true);

    fetchData({
      method,
      url,
      body,
      callbackSuccess: ({ data: data_, status }) => {
        setResponseData(data_);
        setIsFetching(false);
        setIsSaving(false);
        if (callbackSuccess) {
          callbackSuccess({ data_, status });
        }
      },
      callbackError: ({ data: data_, status }) => {
        setResponseData({});
        setResponseError(data_);
        setIsFetching(false);
        setIsSaving(false);
        if (callbackError) {
          callbackError({ data_, status });
        }
      },
    });
  }, [isSaving]);

  return {
    responseData,
    responseError,
    isFetching,
  };
};

import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import { routes } from '../../routes';
import PrivateRoute from './PrivateRoute';
import LanguageSettingView from '../../views/LanguageSettingView';
import PasswordSettingView from '../../views/PasswordSettingView';
import ProfileSettingView from '../../views/ProfileSettingView';

const AccountRoutes = () => {
  const { url } = useRouteMatch();

  const {
    ACCOUNT_SETTINGS_URL,
    ACCOUNT_SETTINGS_PROFILE_URL,
    ACCOUNT_SETTINGS_PASSWORD_URL,
    ACCOUNT_SETTINGS_LANGUAGE_URL,
  } = routes;

  return (
    <Route path={ACCOUNT_SETTINGS_URL}>
      <Switch>
        <PrivateRoute
          exact
          path={`${url}${ACCOUNT_SETTINGS_PROFILE_URL}`}
        >
          <ProfileSettingView />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${url}${ACCOUNT_SETTINGS_PASSWORD_URL}`}
        >
          <PasswordSettingView />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${url}${ACCOUNT_SETTINGS_LANGUAGE_URL}`}
        >
          <LanguageSettingView />
        </PrivateRoute>
        <Route exact path={`${url}/`}>
          <Redirect to={`${url}${ACCOUNT_SETTINGS_PROFILE_URL}`} />
        </Route>
        <Route path={`${url}/*`}>
          <Redirect to={`${url}${ACCOUNT_SETTINGS_PROFILE_URL}`} />
        </Route>
      </Switch>
    </Route>
  );
};

export default AccountRoutes;

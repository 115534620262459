import {
  HABITS_LIST_GET_FAILURE,
  HABITS_LIST_GET_REQUEST,
  HABITS_LIST_GET_SUCCESS,
  HABIT_ADD_EDIT_FAILURE,
  HABIT_ADD_EDIT_REQUEST,
  HABIT_ADD_EDIT_SUCCESS,
  HABIT_GET_DEL_REQUEST,
  HABIT_GET_DEL_SUCCESS,
  HABIT_GET_DEL_FAILURE,
  HABIT_DEVELOP_REQUEST,
  HABIT_DEVELOP_SUCCESS,
  HABIT_DEVELOP_FAILURE,
  HABIT_CLEAR_ERRORS,
} from '../actions/actionTypes';

const habit = {
  id: null,
  name: null,
  release: null,
  prize: null,
  is_developed: false,
  is_active: true,
  remind_at: null,
  zone: null,
};

const initialState = {
  habit,
  habitMode: null,
  habitsCount: null,
  habitsList: [],
  errorsHabitAddEdit: null,
  errorsHabitGetDel: null,
  errorsHabitsListGet: null,
  errorsHabitDevelop: null,
  habitsListNext: null,
  habitsListPrevious: null,
  habitLoading: false,
};

export const habitReducer = (state = initialState, action) => {
  const {
    errors,
    errorsHabitsListGet,
    errorsHabitGetDel,
    errorsHabitDevelop,
    habitData,
    habitMode,
    habitsList,
    habitsCount,
    habitsListNext,
    habitsListPrevious,
    type,
  } = action;

  switch (type) {
    case HABITS_LIST_GET_REQUEST:
      return {
        ...state,
        errorsHabitsListGet: null,
        habitLoading: true,
      };
    case HABITS_LIST_GET_SUCCESS:
      return {
        ...state,
        habitsList,
        habitsCount,
        habitsListNext,
        habitsListPrevious,
        habitLoading: false,
      };
    case HABITS_LIST_GET_FAILURE:
      return {
        ...state,
        errorsHabitsListGet,
        habitLoading: false,
      };
    case HABIT_ADD_EDIT_REQUEST:
      return {
        ...state,
        errorsHabitAddEdit: null,
        habitLoading: true,
      };
    case HABIT_ADD_EDIT_SUCCESS:
      return {
        ...state,
        habitLoading: false,
      };
    case HABIT_ADD_EDIT_FAILURE:
      return {
        ...state,
        errorsHabitAddEdit: {
          zone: errors.zone || null,
          name: errors.name || null,
          release: errors.release || null,
          prize: errors.prize || null,
          nonField: errors.nonField || null,
        },
        habitLoading: false,
      };
    case HABIT_GET_DEL_REQUEST:
      return {
        ...state,
        errorsHabitGetDel: null,
        habitLoading: true,
      };
    case HABIT_GET_DEL_SUCCESS:
      return {
        ...state,
        habit: { ...habitData },
        habitMode,
        habitLoading: false,
      };
    case HABIT_GET_DEL_FAILURE:
      return {
        ...state,
        errorsHabitGetDel,
        habitLoading: false,
      };
    case HABIT_DEVELOP_REQUEST:
      return {
        ...state,
        errorsHabitDevelop: null,
        habitLoading: true,
      };
    case HABIT_DEVELOP_SUCCESS:
      return {
        ...state,
        habit: { ...habitData },
        habitLoading: false,
      };
    case HABIT_DEVELOP_FAILURE:
      return {
        ...state,
        errorsHabitDevelop,
        habitLoading: false,
      };
    case HABIT_CLEAR_ERRORS:
      return {
        ...state,
        errorsHabitAddEdit: null,
        errorsHabitGetDel: null,
        errorsHabitDevelop: null,
        errorsHabitsListGet: null,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Heading, Text } from '../../atoms';

const Wrapper = styled.section`
  max-width: 55rem;
  border-radius: 1rem;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 10px 30px -10px rgba(0,0,0,.1);
  background-color: #fff;
`;

const HeaderWrapper = styled.div`
  padding: 1.6rem 3.2rem;
  background: linear-gradient(120deg, ${({ theme }) => theme.colorPrimary} 65%, ${({ theme }) => theme.colorSecondary});
`;

const InnerWrapper = styled.div`
  padding: 3.2rem;
  background-color: ${({ theme }) => theme.colorWhite};

  ${({ flex }) => flex && css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
`;

const CardHeading = styled(Heading)`
  margin: .5rem auto 0;
  text-align: center;
  color: #fff;
`;

const CardSubHeading = styled(Text)`
  margin: .5rem auto 0;
  text-align: center;
`;

export const Card = ({
  cardHeader,
  cardSubHeader,
  cardType,
  children,
}) => (
  <Wrapper>
    <HeaderWrapper activeColor={cardType}>
      <CardHeading>{cardHeader}</CardHeading>
      <CardSubHeading size="xs" weight="weightBold" color="colorWhite">{cardSubHeader}</CardSubHeading>
    </HeaderWrapper>
    <InnerWrapper flex>
      {children}
    </InnerWrapper>
  </Wrapper>
);

Card.propTypes = {
  cardHeader: PropTypes.string.isRequired,
  cardSubHeader: PropTypes.string,
  cardType: PropTypes.oneOf(['pink', 'blue', 'green']),
  children: PropTypes.element.isRequired,
};

Card.defaultProps = {
  cardType: 'pink',
  cardSubHeader: '',
};

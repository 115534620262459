/* eslint-disable */

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Tilt from 'react-parallax-tilt'

import HeroBannerThree from './components/HeroBannerThree';
import FancyFeatureEight from './components/FancyFeatureEight';
import FeatureTab from './components/FeatureTab';
import FancyFeatureEleven from './components/FancyFeatureEleven';
import CommonCounterTwo from './components/CommonCounterTwo'
import FancyFeatureTwelve from './components/FancyFeatureTwelve';
import BannerTwo from './components/BannerTwo';
import BannerFive from './components/BannerFive';
import FooterThree from './components/FooterThree';
import CopyRightFour from './components/CopyRightFour';
import img1 from './assets/images/assets/ils_07.svg';
import img2 from './assets/images/assets/ils_08.svg';
import img3 from './assets/images/assets/ils_08_1.svg';
import img4 from './assets/images/shape/shape_20.svg';
import img5 from './assets/images/shape/shape_21.svg';
import img6 from './assets/images/shape/shape_22.png';
import img7 from './assets/images/shape/shape_23.png';
import img8 from './assets/images/assets/ils_12_3.svg';
import img9 from './assets/images/shape/shape_25.svg';
import img10 from './assets/images/shape/shape_28.png';
import img11 from './assets/images/shape/shape_29.png';
import img12 from './assets/images/shape/shape_30.png';

import './assets/main.sass';

const LandingPage = () => {
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const backendConnectionError = useSelector(({ userReducer }) => userReducer.backendConnectionError);

  return (
    <Fragment>
      <div className="main-page-wrapper">
        <HeroBannerThree backendConnectionError={backendConnectionError} userIsAuthenticated={user.is_authenticated}  />

        <div className="fancy-feature-eight position-relative">
          <div className="container">
            <FancyFeatureEight />
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-feature-eight */}


        <div className="fancy-feature-ten mt-190 lg-mt-110">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="block-style-two md-mb-50" data-aos="fade-right">
                  <div className="title-style-one">
                    <div className="sc-title-four">CZYM JEST UMASTER?</div>
                    <h2 className="main-title">uMaster to aplikacja wspierająca Twój rozwój.</h2>
                  </div>
                  {/* /.title-style-one */}
                  <p className="pt-25 pb-30 lg-pt-20 lg-pb-10">Do Twojej dyspozycji oddamy Sfery Rozwoju, które możesz podzielić na konkretne Obszary, reprezentujące ważne aspekty Twojego życia.</p>
                </div>
                {/* /.block-style-two */}
              </div>
              <div className="col-xl-6 col-lg-7 col-md-6 ms-auto" data-aos="fade-left">
                <div
                  className="screen-holder-one d-flex align-items-center justify-content-center">
                  <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                    <img src={img1} alt="" className="main-illustration transform-img-meta ms-auto" />
                  </Tilt>
                </div>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-feature-ten */}

        <div className="fancy-feature-nine mt-190 lg-mt-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-5 col-lg-6 ms-auto order-lg-last">
                <div className="block-style-two md-mb-50" data-aos="fade-left">
                  <div className="title-style-one">
                    <div className="sc-title-four">Każda Sfera dzieli się na Obszary</div>
                    <h2 className="main-title">W każdym obszarze masz do dyspozycji:</h2>
                  </div>
                  {/* /.title-style-one */}

                  <FeatureTab /> {/* /.tab-content */}
                </div>
                {/* /.block-style-two */}

              </div>
              <div
                className="col-lg-6 order-lg-first text-center text-lg-start"
                data-aos="fade-right">
                <div className="illustration-holder d-inline-block ms-xxl-5 mt-40 lg-mt-10">
                  <Tilt>
                    <img src={img2} alt="" className="transform-img-meta" />
                  </Tilt>
                  <img src={img3} alt="" className="shapes shape-one" />
                </div>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-feature-nine */}

        <div className="fancy-feature-eleven mt-225 lg-mt-120">
          <div className="container">
            <div
              className="title-style-one white-vr text-center mb-55 lg-mb-30"
              data-aos="fade-up">
              <h2 className="main-title">Graj w Zwycięski Dzień</h2>
              <p className="sub-title">Zrealizuj 5 prostych zadań dziennie, które przybliżą Cię do osiągnięcia Twoich celów</p>
            </div>
            {/* /.title-style-one */}
            <div className="row">
              <div className="col-xxl-11 m-auto">
                <FancyFeatureEleven />
              </div>
            </div>
          </div>
          {/* /.container */}
          <img src={img4} alt="" className="shapes shape-one" />
          <img src={img5} alt="" className="shapes shape-two" />
          <img src={img6} alt="" className="shapes shape-three" />
          <img src={img7} alt="" className="shapes shape-four" />
        </div>

        {/* <div className="counter-section-one top-transform">
          <div className="container">
            <div className="inner-container">
              <CommonCounterTwo />
            </div>
          </div>
        </div> */}

        <div className="fancy-feature-twelve mt-170 lg-mt-100">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-md-6 order-md-last">
                <FancyFeatureTwelve />
              </div>
              <div className="col-xl-7 col-md-6 order-md-first" data-aos="fade-right">
                <div
                  className="illustration-holder position-relative d-inline-block ms-5 sm-mt-30">
                  <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                    <img src={img8} alt="" className="transform-img-meta" />
                  </Tilt>
                  <div
                    className="card-one shapes d-flex align-items-center justify-content-center">
                    <div className="icon"><i className="bi bi-check-lg" /></div>
                    <h6>Dobra robota!</h6>
                  </div>
                  {/* /.card-one */}
                  <div className="card-two shapes text-center">
                    <div className="icon"><i className="bi bi-check-lg" /></div>
                    <div className="main-count">
                      <span className="counter">Cel osiągnięty</span></div>
                    <ul className="style-none d-flex justify-content-center rating">
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                      <li><i className="bi bi-star-fill" /></li>
                    </ul>
                  </div>
                  {/* /.card-two */}
                </div>
              </div>
            </div>
          </div>
          <img src={img9} alt="" className="shapes bg-shape" />
        </div>
        {/* /.fancy-feature-twelve */}

        <div className="fancy-short-banner-two">
          <div className="bg-wrapper">
            <div className="container">
              <BannerTwo />
            </div>
            {/* /.container */}
            <img src={img10} alt="" className="shapes shape-two" />
            <img src={img11} alt="" className="shapes shape-three" />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.fancy-short-banner-two */}

        <div className="fancy-short-banner-five">
          <div className="container">
            <div className="bg-wrapper" data-aos="fade-up">
              <BannerFive />
            </div>
          </div>
        </div>
        {/* /.address-section-one */}

        <div className="footer-style-three theme-basic-footer">
          <img src={img12} alt="" className="shapes shape-one" />
          <img src={img10} alt="" className="shapes shape-two" />
          <img src={img11} alt="" className="shapes shape-three" />
          <div className="container">
            <div className="inner-wrapper">
              <FooterThree />
              <div className="bottom-footer">
                <CopyRightFour />
              </div>
            </div>
            {/* /.inner-wrapper */}
          </div>
        </div>
        {/* /.footer-style-three */}

      </div>
    </Fragment>
  );
};

export default LandingPage;

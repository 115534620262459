import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

import { InputField } from '../InputField/InputField';
import {
  clearFeedbackErrorsAction,
  feedbackAddAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

export const FeedbackForm = ({
  zoneId,
}) => {
  const dispatch = useDispatch();

  const {
    feedbackLoading,
    errorsFeedbackAdd,
  } = useSelector(({ feedbackReducer }) => feedbackReducer);

  const [text, setText] = useState('');
  const t22 = useTr(22);
  const t215 = useTr(215);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearFeedbackErrorsAction());
    dispatch(feedbackAddAction(zoneId, text, () => setText('')));
  };
  return (
    <form onSubmit={handleSubmit} className="feedback-list__content">
      <InputField
        disabled={feedbackLoading}
        error={errorsFeedbackAdd && errorsFeedbackAdd}
        inputId="nameInput"
        label={t22}
        name="name"
        onChange={(e) => setText(e.target.value)}
        textarea
        maxLength={400}
        value={text}
      />
      <Button
        color="primary"
        disabled={feedbackLoading || !text}
        fullWidth
        type="submit"
        variant="contained"
      >
        {t215}
      </Button>
    </form>
  );
};

FeedbackForm.propTypes = {
  zoneId: PropTypes.string,
};

FeedbackForm.defaultProps = {
  zoneId: '',
};

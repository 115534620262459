import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ACCOUNTS_API_URL } from '../apiRequests';
import { routes } from '../routes';
import { ErrorsList, Loader, Text } from '../components/atoms';
import { Card, ConfirmButton } from '../components/molecules';
import AuthTemplate from '../templates/AuthTemplate';
import { useAxiosPostNew, useTr } from '../hooks';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 9.5rem 1.5rem 1.5rem;
`;

const PasswordResetView = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const t208 = useTr(208);
  const t212 = useTr(212);
  const t213 = useTr(213);
  const t214 = useTr(214);
  const t215 = useTr(215);
  const t216 = useTr(216);

  const handleSubmit = (credentials) => {
    setEmail(credentials.email);
    setIsSaving(true);
  };

  const {
    responseError,
    isFetching,
  } = useAxiosPostNew({
    url: `${ACCOUNTS_API_URL}/password-reset/send`,
    body: { email },
    isSaving,
    setIsSaving,
    callbackSuccess: () => setEmailSent(true),
  });

  if (emailSent) {
    return (
      <Wrapper>
        <Card cardHeader={t212}>
          <>
            <Text>{t213}</Text>
            <ConfirmButton
              linkName={t208}
              tag={Link}
              url={routes.LOGIN_URL}
            />
          </>
        </Card>
      </Wrapper>
    );
  }

  return (
    <AuthTemplate
      authFormHeader={t214}
      buttonName={isFetching ? <Loader small /> : t215}
      disabled={isFetching}
      errorEmail={responseError?.email && responseError.email[0]}
      errorNonFiled={(responseError?.email_sent && <ErrorsList list={responseError.email_sent} />) || (responseError?.detail && <ErrorsList list={[responseError.detail]} />)}
      isPasswordField={false}
      onSubmit={handleSubmit}
    >
      <ConfirmButton
        confirmNote={t216}
        linkName={t208}
        tag={Link}
        url={routes.LOGIN_URL}
      />
    </AuthTemplate>
  );
};

export default PasswordResetView;

// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { Loader, Text } from '../../atoms';
import { Pagination, ObjectivesListItem } from '../../molecules';
import ObjectiveManageForm from '../../organisms/ObjectiveManageForm/ObjectiveManageForm';

import {
  objectivesListGetAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

import './ObjectivesTable.sass';

const ObjectiveTable = ({ zoneId }) => {
  const dispatch = useDispatch();

  const {
    objectivesList,
    objectiveLoading,
    objectivesListNext,
    objectivesListPrevious,
  } = useSelector(({ objectiveReducer }) => objectiveReducer);
  const { zoneMode } = useSelector(({ zoneReducer }) => zoneReducer);

  const t91 = useTr(91);
  const t92 = useTr(92);
  const t93 = useTr(93);
  const t94 = useTr(94);
  const t95 = useTr(95);

  const [showDoneObjectives, setShowDoneObjectives] = useState(false);

  useEffect(() => {
    dispatch(objectivesListGetAction(zoneId));
  }, [dispatch, zoneId]);

  const handleClickObjectivesVariant = () => {
    setShowDoneObjectives(!showDoneObjectives);

    if (!showDoneObjectives) {
      dispatch(objectivesListGetAction(zoneId, true, true));
    } else {
      dispatch(objectivesListGetAction(zoneId));
    }
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    if (objectivesListNext) {
      dispatch(objectivesListGetAction(zoneId, null, null, objectivesListNext));
    }
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    if (objectivesListPrevious) {
      dispatch(objectivesListGetAction(zoneId, null, null, objectivesListPrevious));
    }
  };

  return (
    <div className="objectives-table">
      <div className="objectives-table__button-container">
        <Button
          className="objectives-table__variant-btn"
          onClick={handleClickObjectivesVariant}
          width="20rem"
          variant="outlined"
        >
          {showDoneObjectives ? t91 : t92}
        </Button>
        {zoneMode !== 'RO' && !showDoneObjectives && <ObjectiveManageForm zoneId={zoneId} objectiveAdd />}
      </div>
      <section className="objectives-table__objective">
        {objectiveLoading ? <Loader view /> : (
          objectivesList && objectivesList.length === 0 && <Text info className="objectives-table__info-text">{showDoneObjectives ? t93 : zoneMode === 'RO' ? t94 : t95}</Text>
        )}
        {!objectiveLoading && objectivesList.map((objective) => (
          <ObjectivesListItem
            key={objective.id}
            objectiveId={objective.id}
            name={objective.name}
            endDate={objective.end_date}
            done={showDoneObjectives}
            zoneId={zoneId}
          />
        ))}
      </section>
      {(objectivesListNext || objectivesListPrevious) && (
        <Pagination
          isNext={objectivesListNext !== null}
          isPrev={objectivesListPrevious !== null}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
        />
      )}
    </div>
  );
};

ObjectiveTable.propTypes = {
  zoneId: PropTypes.string,
};

ObjectiveTable.defaultProps = {
  zoneId: '',
};

export default ObjectiveTable;

// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ErrorOutline } from '@mui/icons-material';
import { Button } from '@mui/material';

import {
  Heading,
  Loader,
  Text,
} from '../../atoms';
import { Pagination, SharedZonesListItem } from '../../molecules';
import { sharedZonesListGetAction } from '../../../actions';
import { useTr } from '../../../hooks';
import { routes } from '../../../routes';
import './SharedZonesTable.sass';

const SharedZonesTable = () => {
  const dispatch = useDispatch();
  const [sharedZonesToMe, setSharedZonesToMe] = useState(true);
  const [sharedZonesListVariant, setSharedZonesListVariant] = useState(null);
  const [sharedZonesListNextVariant, setSharedZonesListNextVariant] = useState(null);
  const [sharedZonesListPrevVariant, setSharedZonesListPrevVariant] = useState(null);

  const userEmailConfirmed = useSelector(({ newUserReducer }) => newUserReducer.user.email_confirmed);

  const t99 = useTr(99);
  const t100 = useTr(100);
  const t101 = useTr(101);
  const t102 = useTr(102);
  const t103 = useTr(103);
  const t104 = useTr(104);
  const t145 = useTr(145);
  const t146 = useTr(146);

  const {
    errorsSharedZonesListMyGet,
    errorsSharedZonesListToMeGet,
    sharedZonesCountMy,
    sharedZonesCountToMe,
    sharedZonesListMy,
    sharedZonesListToMe,
    sharedZoneLoading,
    sharedZonesListMyNext,
    sharedZonesListToMeNext,
    sharedZonesListMyPrevious,
    sharedZonesListToMePrevious,
  } = useSelector(({ sharedZoneReducer }) => sharedZoneReducer);

  useEffect(() => {
    if (sharedZonesToMe) {
      setSharedZonesListVariant(sharedZonesListToMe);
      setSharedZonesListNextVariant(sharedZonesListToMeNext);
      setSharedZonesListPrevVariant(sharedZonesListToMePrevious);
    } else {
      setSharedZonesListVariant(sharedZonesListMy);
      setSharedZonesListNextVariant(sharedZonesListMyNext);
      setSharedZonesListPrevVariant(sharedZonesListMyPrevious);
    }
  }, [
    sharedZonesListToMe,
    sharedZonesListMy,
    sharedZonesToMe,
    sharedZonesListMyNext,
    sharedZonesListMyPrevious,
    sharedZonesListToMeNext,
    sharedZonesListToMePrevious,
  ]);

  const handleClickSharedZonesVariant = () => {
    setSharedZonesToMe(!sharedZonesToMe);
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    if (sharedZonesListNextVariant) {
      dispatch(sharedZonesListGetAction(sharedZonesToMe, sharedZonesListNextVariant));
    }
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    if (sharedZonesListPrevVariant) {
      dispatch(sharedZonesListGetAction(sharedZonesToMe, sharedZonesListPrevVariant));
    }
  };

  return (
    <>
      <Button
        className="share-variant-button"
        onClick={handleClickSharedZonesVariant}
        variant="outlined"
      >
        {sharedZonesToMe ? t99 : t100}
      </Button>
      <Heading>{sharedZonesToMe ? t101 : t102} ({sharedZonesToMe ? sharedZonesCountToMe : sharedZonesCountMy})</Heading>
      {!userEmailConfirmed
        ? (
          <Text warning className="center-text">
            <ErrorOutline />
            {t146}<Link to={routes.ACCOUNT_SETTINGS_URL}>{t145}</Link>
          </Text>
        ) : (
          <>
            {sharedZoneLoading ? <Loader view />
              : (errorsSharedZonesListMyGet || errorsSharedZonesListToMeGet)
                ? <Text error>{sharedZonesToMe ? errorsSharedZonesListToMeGet : errorsSharedZonesListMyGet}</Text>
                : sharedZonesListVariant && sharedZonesListVariant.length === 0 && <Text info>{sharedZonesToMe ? t103 : t104}</Text>}

            {(sharedZonesListVariant && sharedZonesListVariant.length > 0) && sharedZonesListVariant.map((sharedZone) => (
              <SharedZonesListItem
                key={sharedZone.id}
                name={sharedZone.zone.sphere.name}
                photo_url={sharedZone.zone.sphere.photo_url}
                singleZoneId={sharedZone.zone.id}
                singleZoneColor={sharedZone.zone.color}
                singleZoneName={sharedZone.zone.name}
                expireAt={sharedZone.expire_at}
                isAccepted={sharedZone.accepted}
                sharedZoneId={sharedZone.id}
                userEmail={sharedZonesToMe ? sharedZone.from_user.email : sharedZone.email}
                userName={sharedZonesToMe ? sharedZone.from_user.username : (sharedZone.user ? sharedZone.user.username : null)}
                sphereMiniHref={`/sfera/${sharedZone.zone.sphere.id}/obszar/${sharedZone.zone.id}`}
                isLinkActive={!sharedZonesToMe || sharedZone.accepted}
                singleZone
                sharedZonesToMe={sharedZonesToMe}
                unreadFeedbackCount={sharedZone.unread_feedback_count}
              />
            ))}

            <Pagination
              isNext={sharedZonesListNextVariant !== null}
              isPrev={sharedZonesListPrevVariant !== null}
              onClickPrev={handleClickPrev}
              onClickNext={handleClickNext}
            />
          </>
        )}
    </>
  );
};

export default SharedZonesTable;

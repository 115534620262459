import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  clearUserErrorsAction,
  clearZoneData,
  setInitialGridAction,
} from '../../actions';
import { Loader } from '../atoms';

import { routes } from '../../routes';

const HistoryListener = ({ children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(clearUserErrorsAction());
    setIsFirstRender(false);

    return history.listen(() => {
      dispatch(clearUserErrorsAction());
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (!location.pathname.includes('obszar')) {
      dispatch(clearZoneData());
    }
  }, [location]);

  useEffect(() => {
    dispatch(setInitialGridAction(location.pathname === routes.ABOUT_URL));
  }, [location]);

  // if is the first time render, show loading
  if (isFirstRender) {
    return <Loader view />;
  }

  return children;
};

HistoryListener.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HistoryListener;

// Add or edit Sphere form
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { Text } from '../../atoms';
import { useTr } from '../../../hooks';
import './ConfirmWidget.sass';

const ConfirmWidget = ({
  additionalContent,
  cancelBtnText,
  className,
  confirmationBtnText,
  confirmationText,
  confirmationInfo,
  onCancel,
  onConfirm,
  positive,
}) => {
  const t5 = useTr(5);
  const t11 = useTr(11);

  return (
    <div className={className}>
      {additionalContent}
      <Text className="confirm-widget__text warning" warning>{confirmationText}</Text>
      <Text className="confirm-widget__text" info>{confirmationInfo}</Text>
      <div className="btn-container">
        {onCancel && <Button onClick={onCancel} color="info">{cancelBtnText || t5}</Button>}
        <Button onClick={onConfirm} variant="contained" color={positive ? 'primary' : 'error'}>{confirmationBtnText || t11}</Button>
      </div>
    </div>
  );
};

ConfirmWidget.propTypes = {
  additionalContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  cancelBtnText: PropTypes.string,
  className: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  confirmationText: PropTypes.string,
  confirmationInfo: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  positive: PropTypes.bool,
};

ConfirmWidget.defaultProps = {
  additionalContent: null,
  cancelBtnText: '',
  className: null,
  confirmationBtnText: '',
  confirmationInfo: null,
  onCancel: null,
  confirmationText: null,
  positive: null,
};

export default ConfirmWidget;

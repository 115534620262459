import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPatch,
  HABIT_API_URL,
  ZONE_API_URL,
} from '../apiRequests';

import {
  HABIT_ADD_EDIT_FAILURE,
  HABIT_ADD_EDIT_REQUEST,
  HABIT_ADD_EDIT_SUCCESS,
  HABITS_LIST_GET_FAILURE,
  HABITS_LIST_GET_REQUEST,
  HABITS_LIST_GET_SUCCESS,
  HABIT_GET_DEL_REQUEST,
  HABIT_GET_DEL_SUCCESS,
  HABIT_GET_DEL_FAILURE,
  HABIT_DEVELOP_REQUEST,
  HABIT_DEVELOP_SUCCESS,
  HABIT_DEVELOP_FAILURE,
  HABIT_CLEAR_ERRORS,
} from './actionTypes';

// HABITS LIST GET
export const habitsListGetAction = (zoneId, isActive = null, isDeveloped = null, fetchUrl = `${ZONE_API_URL}/${zoneId}/habits`) => (dispatch) => {
  dispatch({ type: HABITS_LIST_GET_REQUEST });

  return axiosGet(
    fetchUrl,
    {
      zone_id: zoneId,
      is_active: isActive,
      is_developed: isDeveloped,
    },
  ).then((response) => {
    dispatch({
      type: HABITS_LIST_GET_SUCCESS,
      habitsList: response.data.data.results.reverse(),
      habitsCount: response.data.data.count,
      habitsListNext: response.data.data.next || null,
      habitsListPrevious: response.data.data.previous || null,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: HABITS_LIST_GET_FAILURE,
          errorsHabitsListGet: response.data ? response.data.data.detail : null,
        });
      } else {
        dispatch({
          type: HABITS_LIST_GET_FAILURE,
          errorsHabitsListGet: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// HABIT ADD / EDIT
export const habitAddEditAction = (zoneId, habitId = null, name, release, prize, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: HABIT_ADD_EDIT_REQUEST });

  const method = habitId ? axiosPatch : axiosPost;

  return method(
    `${HABIT_API_URL}/${habitId || 'create'}`,
    {
      zone: zoneId,
      habit_id: habitId,
      name,
      release,
      prize,
      // for now, when there are no notifications enabled
      remind_at: null,
    },
  ).then(() => {
    dispatch({
      type: HABIT_ADD_EDIT_SUCCESS,
    });
    habitsListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: HABIT_ADD_EDIT_FAILURE,
          errors: {
            zone: response.data.data.zone ? response.data.data.zone[0] : null,
            name: response.data.data.name ? response.data.data.name[0] : null,
            release: response.data.data.release ? response.data.data.release[0] : null,
            prize: response.data.data.prize ? response.data.data.prize[0] : null,
            remindAt: response.data.data.remind_at ? response.data.data.remind_at[0] : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors[0] : null,
          },
        });
      } else if (response.status === 403 || response.status === 404) {
        dispatch({
          type: HABIT_ADD_EDIT_FAILURE,
          errors: { nonField: response.data && response.data.data.detail },
        });
      } else {
        dispatch({
          type: HABIT_ADD_EDIT_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// SINGLE HABIT GET / DEL
export const habitGetDelAction = (habitId, zoneId, callbackSuccess = null, habitDelete = false) => (dispatch) => {
  dispatch({ type: HABIT_GET_DEL_REQUEST });

  const method = habitDelete ? axiosDelete : axiosGet;

  return method(
    `${HABIT_API_URL}/${habitId}`,
    { habit_id: habitId },
  ).then((response) => {
    dispatch({
      type: HABIT_GET_DEL_SUCCESS,
      habitData: response.data.data,
      habitMode: response.data.meta.mode,
    });
    habitsListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: HABIT_GET_DEL_FAILURE,
          errorsHabitGetDel: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: HABIT_GET_DEL_FAILURE,
          errorsHabitGetDel: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// SINGLE HABIT DEVELOP
export const habitDevelopAction = (habitId, zoneId, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: HABIT_DEVELOP_REQUEST });

  return axiosPatch(
    `${HABIT_API_URL}/${habitId}/developed`,
    { habit_id: habitId },
  ).then((response) => {
    dispatch({
      type: HABIT_DEVELOP_SUCCESS,
      habitData: response.data.data,
    });
    habitsListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: HABIT_DEVELOP_FAILURE,
          errorsHabitDevelop: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: HABIT_DEVELOP_FAILURE,
          errorsHabitDevelop: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearHabitErrorsAction = () => (dispatch) => {
  dispatch({ type: HABIT_CLEAR_ERRORS });
};

// Add or edit Sphere form
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

import { Text } from '../../atoms';
import { DialogCustom, InputField } from '../../molecules';

import {
  clearSharedZoneErrorsAction,
  sharedZoneAddAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

const SharedZoneManageForm = ({ zoneId, className }) => {
  const errors = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.errorsSharedZoneAdd);
  const sharedZoneLoading = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.sharedZoneLoading);
  const dispatch = useDispatch();

  const t96 = useTr(96);
  const t97 = useTr(97);
  const t98 = useTr(98);

  const [email, setEmail] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
    setEmail('');
    dispatch(clearSharedZoneErrorsAction());
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearSharedZoneErrorsAction());
    dispatch(sharedZoneAddAction(
      zoneId,
      email,
      handleClose,
    ));
  };

  return (
    <DialogCustom
      onClose={handleClose}
      onOpen={handleOpen}
      openDialogButton
      openDialogButtonText={t96}
      dialogTitle={t97}
      open={isDialogOpen}
      openBtnClassName={className}
    >
      <form onSubmit={handleSubmit}>
        <InputField
          autoFocus
          disabled={sharedZoneLoading}
          error={errors && errors.email}
          inputId="nameInput"
          label={t98}
          name="name"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button
          color="primary"
          disabled={sharedZoneLoading || !email}
          fullWidth
          type="submit"
          variant="contained"
        >
          {sharedZoneLoading ? <FontAwesomeIcon icon="spinner" spin /> : t96}
        </Button>
        {errors && <Text error>{errors.zone}</Text>}
        {errors && <Text error>{errors.nonField}</Text>}
      </form>
    </DialogCustom>
  );
};

SharedZoneManageForm.propTypes = {
  className: PropTypes.string,
  zoneId: PropTypes.string,
};

SharedZoneManageForm.defaultProps = {
  className: null,
  zoneId: '',
};

export default SharedZoneManageForm;

import React, { useEffect, useMemo } from 'react';
import {
  matchPath,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setMenuItemsAction } from '../../actions';
import { MenuItem } from '../../helpers';
import { routes } from '../../routes';
import AccountRoutes from '../../components/routing/AccountRoutes';
import { useTr } from '../../hooks';
import './AccountSettingsView.sass';

const AccountSettingsView = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();

  const t170 = useTr(170);
  const t174 = useTr(174);
  const t179 = useTr(179);
  const t180 = useTr(180);

  const {
    ACCOUNT_SETTINGS_URL,
    ACCOUNT_SETTINGS_PROFILE_URL,
    ACCOUNT_SETTINGS_PASSWORD_URL,
    ACCOUNT_SETTINGS_LANGUAGE_URL,
    HOME_URL,
  } = routes;

  const isMatchedPath = useMemo(() => matchPath(url, {
    path: ACCOUNT_SETTINGS_URL,
  }), [url]);

  const spheres = useMemo(() => new MenuItem(
    HOME_URL,
    t170,
    false,
    null,
    null,
    'circle',
    false,
  ), []);

  const profile = useMemo(() => new MenuItem(
    `${url}${ACCOUNT_SETTINGS_PROFILE_URL}`,
    t179,
    false,
    null,
    null,
    'address-card',
    matchPath(location.pathname, { path: `${ACCOUNT_SETTINGS_URL}${ACCOUNT_SETTINGS_PROFILE_URL}` }),
  ), [location]);

  const password = useMemo(() => new MenuItem(
    `${url}${ACCOUNT_SETTINGS_PASSWORD_URL}`,
    t174,
    false,
    null,
    null,
    'eye-slash',
    matchPath(location.pathname, { path: `${ACCOUNT_SETTINGS_URL}${ACCOUNT_SETTINGS_PASSWORD_URL}` }),
  ), [location]);

  const language = useMemo(() => new MenuItem(
    `${url}${ACCOUNT_SETTINGS_LANGUAGE_URL}`,
    t180,
    false,
    null,
    null,
    'flag',
    matchPath(location.pathname, { path: `${ACCOUNT_SETTINGS_URL}${ACCOUNT_SETTINGS_LANGUAGE_URL}` }),
  ), [location]);

  useEffect(() => {
    if (isMatchedPath) {
      dispatch(setMenuItemsAction([spheres, profile, password, language]));
    }
  }, [dispatch, url, location]);

  return (
    <main className="account-setting-view">
      <AccountRoutes />
    </main>
  );
};

export default AccountSettingsView;

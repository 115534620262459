import { library } from '@fortawesome/fontawesome-svg-core';
// to import whole package of icons use this import:
// import { fas } from '@fortawesome/free-solid-svg-icons';

// Single icons import:
import {
  faAngleDown,
  faAngleUp,
  faArrowCircleRight,
  faAt,
  faEllipsisH,
  faCalendarAlt,
  faCrown,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faInfoCircle,
  faHandsHelping,
  faPlus,
  faPlusCircle,
  faSpinner,
  faThumbsUp as faThumbsUpSolid,
  faTools,
  faUserCircle,
  faPenAlt,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';

import {
  faAddressCard,
  faBell,
  faCalendarCheck,
  faCircle,
  faCompass,
  faEyeSlash,
  faFlag,
  faHandshake,
  faLightbulb,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAddressCard,
  faAt,
  faAngleDown,
  faAngleUp,
  faArrowCircleRight,
  faBell,
  faCalendarCheck,
  faCalendarAlt,
  faCircle,
  faCheckCircle,
  faCrown,
  faTimesCircle,
  faCheck,
  faCompass,
  faEyeSlash,
  faEllipsisH,
  faFlag,
  faCogs,
  faHandshake,
  faHandsHelping,
  faInfoCircle,
  faLightbulb,
  faPenAlt,
  faPlus,
  faPlusCircle,
  faSpinner,
  faThumbsUp,
  faThumbsUpSolid,
  faTools,
  faUserCircle,
  // fas,
);

/* eslint import/no-unresolved: 2 */
import React from 'react';
import { Text } from '../components/atoms';
import { useTr } from '../hooks';

import './Views.sass';

const NotFoundView = () => (
  <Text className="not-found-page" warning>{useTr(211)}</Text>
);

export default NotFoundView;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import { Text } from '../../atoms';

const TextStyled = styled(Text)`
  margin: 2rem auto -.5rem;
`;

export const ConfirmButton = ({
  confirmNote,
  linkName,
  tag,
  url,
}) => (
  <>
    <TextStyled size="xxs" weight="weightBold">{confirmNote}</TextStyled>
    <Button component={tag} to={url}>{linkName}</Button>
  </>
);

ConfirmButton.propTypes = {
  confirmNote: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  tag: PropTypes.elementType,
  url: PropTypes.string.isRequired,
};

ConfirmButton.defaultProps = {
  confirmNote: null,
  tag: null,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ButtonIcon, Loader, Text } from '../../atoms';

import ConfirmWidget from '../../organisms/ConfirmWidget/ConfirmWidget';
import { DialogCustom } from '../DialogCustom/DialogCustom';
import { useAxios, useTr } from '../../../hooks';
import {
  ZONE_API_URL,
  VISION_API_URL,
  axiosDelete,
} from '../../../apiRequests';

const SinglePhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat: ;
`;

const DeleteBtnStyled = styled(ButtonIcon)`
  position: absolute;
  top: .5rem;
  right: .5rem;
  border-radius: 100%;
  background-color: rgba(255,255,255,.6);
`;

export const VisionImgWidget = ({
  photoUrl,
  zoneId,
  updatePhotoList,
}) => {
  const [openedPhotoDeleteDialog, setOpenedPhotoDeleteDialog] = useState(false);
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);
  const t50 = useTr(50);
  const t51 = useTr(51);

  const deletePhotoSuccessCallback = () => {
    updatePhotoList();
    setOpenedPhotoDeleteDialog(false);
  };

  // Delete single photo
  const {
    axiosFetch,
    isLoading,
    responseError,
  } = useAxios({
    method: axiosDelete,
    url: `${ZONE_API_URL}/${zoneId}${VISION_API_URL}/photo`,
    data: {
      zone_id: parseFloat(zoneId),
      photo_url: photoUrl,
    },
    callbackSuccess: deletePhotoSuccessCallback,
  });

  const handleDeletePhoto = () => {
    axiosFetch();
  };

  return (
    <SinglePhotoContainer imageSrc={photoUrl}>
      {isLoading && <Loader />}
      {zoneMode === 'RW' && (
        <>
          <DeleteBtnStyled
            ariaLabel={t50}
            onClick={() => setOpenedPhotoDeleteDialog(true)}
            title={t50}
            icon={<DeleteForeverIcon color="secondary" />}
            disabled={isLoading}
            btnClassName="vision-manage-photos__delete-btn"
          />
          {openedPhotoDeleteDialog && (
            <DialogCustom
              open={openedPhotoDeleteDialog}
              dialogTitle={t50}
              onClose={() => setOpenedPhotoDeleteDialog(false)}
            >
              <>
                <ConfirmWidget
                  confirmationBtnText={t50}
                  onCancel={() => setOpenedPhotoDeleteDialog(false)}
                  onConfirm={handleDeletePhoto}
                  confirmationText={t51}
                />
                {responseError && <Text error>{responseError.detail}</Text>}
              </>
            </DialogCustom>
          )}
        </>
      )}
    </SinglePhotoContainer>
  );
};

VisionImgWidget.propTypes = {
  photoUrl: PropTypes.string,
  updatePhotoList: PropTypes.func,
  zoneId: PropTypes.string,
};

VisionImgWidget.defaultProps = {
  photoUrl: null,
  updatePhotoList: null,
  zoneId: null,
};

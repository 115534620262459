import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '../../atoms';
import SubObjectiveManageForm from '../../organisms/SubObjectiveManageForm/SubObjectiveManageForm';
import { useTr } from '../../../hooks';
import './SubObjectivesListItem.sass';

export const SubObjectivesListItem = ({
  subObjectiveId,
  name,
  done,
  objectiveId,
  objectiveDone,
}) => {
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);
  const t45 = useTr(45);
  const t46 = useTr(46);

  return (
    <li className="sub-obj-list-item">
      <Text size="xs">{name}</Text>
      {done && <FontAwesomeIcon icon="check-circle" className="sub-obj-list-item__done-icon" />}
      {!done && !objectiveDone && zoneMode === 'RW' && (
        <SubObjectiveManageForm
          formTitle={t45}
          name={name}
          openDialogButtonText={t46}
          subObjectiveId={subObjectiveId}
          objectiveId={objectiveId}
        />
      )}
    </li>
  );
};

SubObjectivesListItem.propTypes = {
  objectiveDone: PropTypes.bool,
  subObjectiveId: PropTypes.number,
  name: PropTypes.string,
  done: PropTypes.bool,
  objectiveId: PropTypes.number,
};

SubObjectivesListItem.defaultProps = {
  objectiveDone: false,
  subObjectiveId: null,
  name: '',
  done: false,
  objectiveId: null,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { ButtonIcon, Text } from '../../atoms';
import { InputField } from '../InputField/InputField';
import { useTr } from '../../../hooks';
import './ActionAddForm.sass';

import {
  actionAddEditAction,
  clearActionsErrorsAction,
} from '../../../actions';

export const ActionAddForm = ({
  actionText,
  actionId,
  callbackSuccess,
  date,
  handleCancelForm,
}) => {
  const dispatch = useDispatch();
  const { actionAddEditErrors, actionsLoading } = useSelector(({ actionsReducer }) => actionsReducer);
  const [newActionText, setNewActionText] = useState(actionText);

  const t4 = useTr(4);
  const t5 = useTr(5);
  const t6 = useTr(6);

  const handleCancelClick = () => {
    handleCancelForm();
    setNewActionText('');
    dispatch(clearActionsErrorsAction());
  };

  const handleSaveAction = (e) => {
    e.preventDefault();
    dispatch(actionAddEditAction(newActionText, date, actionId, callbackSuccess));
  };

  return (
    <li className="action-form">
      <form onSubmit={handleSaveAction} className="action-form__form">
        <InputField
          className="action-form__name"
          autoFocus
          disabled={actionsLoading}
          error={actionAddEditErrors && actionAddEditErrors.description}
          inputId="nameInput"
          label={t4}
          maxLength={128}
          name="actionName"
          onChange={(e) => setNewActionText(e.target.value)}
          value={newActionText}
        />
        <div className="action-form__flex">
          <ButtonIcon
            ariaLabel={t5}
            disabled={actionsLoading}
            title={t5}
            icon={<HighlightOffIcon />}
            onClick={handleCancelClick}
            color="#5e5e5e"
          />
          <ButtonIcon
            ariaLabel={t6}
            disabled={!newActionText || actionsLoading}
            title={t6}
            icon={<CheckCircleIcon />}
            submit
            btnClassName="action-form__submit"
            color="#930DD4"
          />
        </div>
      </form>
      {actionAddEditErrors && actionAddEditErrors.nonField && <Text error>{actionAddEditErrors.nonField}</Text>}
    </li>
  );
};

ActionAddForm.propTypes = {
  actionId: PropTypes.number,
  actionText: PropTypes.string,
  callbackSuccess: PropTypes.func,
  date: PropTypes.string,
  handleCancelForm: PropTypes.func,
};

ActionAddForm.defaultProps = {
  actionId: null,
  actionText: '',
  callbackSuccess: null,
  date: null,
  handleCancelForm: null,
};

/* eslint-disable */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import logoFull from '../assets/images/logo/logo-full.svg';
import shape2 from '../assets/images/shape/shape_19.png';


const HeroBannerThree = ({ backendConnectionError, userIsAuthenticated }) => {
    return (
        <Fragment>
            <div className="hero-banner-three">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="hero-heading">
                                <span>uDream</span>
                                <span>uPlan</span>
                                <span>uMaster</span>
                            </h1>
                            <p className="text-lg mb-50 lg-mb-40">Jeżeli chcesz osiągnąć swoje cele szybciej, lepiej zarządzać rozwojem kariery, jeżeli chcesz w końcu spełnić swoje postanowienia i zrealizować marzenia, uMaster jest właśnie dla Ciebie!</p>
                            <ul className="style-none button-group d-lg-flex align-items-center">
                                {!backendConnectionError && <li className="me-4">
                                    {userIsAuthenticated
                                        ? <Link to="/" className="btn-one ripple-btn">Przejdź do aplikacji</Link>
                                        : <Link to="/rejestracja" className="btn-one ripple-btn">Załóż bezpłatne konto</Link>
                                    }
                                </li>}
                                <li className="help-btn">Masz pytania? <Link to="/contact">Napisz do nas</Link></li>
                            </ul>
                        </div>
                    </div>
                </div> {/* /.container */}
                    <div className="illustration-holder sm-mt-50">
                        <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                        <img src={logoFull} alt="" className="main-illustration transform-img-meta ms-auto" />
                        </Tilt>
                        <img src={shape2} alt="" className="shapes shape-one" />
                    </div>
            </div>
        </Fragment>
    )
}

export default HeroBannerThree
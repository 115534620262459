import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Badge, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { useTr } from '../../../hooks';
import './Sidebar.sass';

const Wrapper = styled.nav`
  ${({ activeColor, color1, color2 }) => (
    (activeColor === 'landingPage') ? css`
      display: none;
    ` : (activeColor === 'app' && color1 && color2) ? css`
      background: linear-gradient(150deg, ${color1} 50%, ${color2});
    ` : css`
      background: linear-gradient(150deg, ${({ theme }) => theme.colorPrimary} 60%, ${({ theme }) => theme.colorSecondary});
    `
  )};
`;

const LinkTextStyled = styled.span`
  color: ${({ active, theme, color }) => (active && color) ? color : active ? theme.colorPrimary : '#fff'};
  background-color: ${({ active }) => active && 'rgba(255,255,255,0.8)'};
`;

const Sidebar = ({ sidebarType }) => {
  const menuItems = useSelector(({ helpersReducer }) => helpersReducer.menuItems);
  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const zoneColorGradient = useSelector(({ zoneReducer }) => zoneReducer.colorGradient);
  const phoneWidth = useMediaQuery('(max-width:600px)');
  const t105 = useTr(105);

  return (
    <Wrapper
      activeColor={sidebarType}
      color1={zoneColor}
      color2={zoneColorGradient}
      className="sidebar"
      aria-label={t105}
    >
      <ul className="sidebar__list">
        {menuItems.map((item) => (
          <li key={item.name} className="sidebar__list-item">
            <Link to={`${item.linkTo}`} className="sidebar__link">
              {item.badge
                ? <Badge badgeContent={item.badgeContent} color={item.badgeColor} variant={(item.badge && !item.badgeContent) ? 'dot' : null}><FontAwesomeIcon icon={['far', item.icon]} color="#fff" size={phoneWidth ? '1x' : '2x'} className="loader__icon" /></Badge>
                : <FontAwesomeIcon icon={['far', item.icon]} color="#fff" size={phoneWidth ? '1x' : '2x'} className="loader__icon" />}
              <LinkTextStyled
                className="sidebar__link-text"
                active={item.isActive}
                color={zoneColor}
              >
                {item.name}
              </LinkTextStyled>
            </Link>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  sidebarType: PropTypes.oneOf(['landingPage', 'app']),
};

Sidebar.defaultProps = {
  sidebarType: 'app',
};

export default Sidebar;

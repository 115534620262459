import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Heading, Text } from '../components/atoms';
import { FeedbackForm } from '../components/molecules';
import FeedbackList from '../components/organisms/FeedbackList/FeedbackList';
import { useTr } from '../hooks';

const FeedbackView = ({ zoneId }) => {
  const sharedZonesListMy = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.sharedZonesListMy);
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);
  const t200 = useTr(200);
  const t238 = useTr(238);
  const [isSharedZone, setIsSharedZone] = useState(false);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(0);

  useEffect(() => {
    if (sharedZonesListMy && sharedZonesListMy.length > 0) {
      const sharedZone = sharedZonesListMy.filter((el) => el.zone.id === parseFloat(zoneId));
      setIsSharedZone(sharedZone && sharedZone.length > 0);
    }

    if (sharedZonesListMy && sharedZonesListMy.length > 0) {
      const invitationsAccepted = sharedZonesListMy.filter((el) => el.zone.id === parseFloat(zoneId) && el.accepted).length;
      setIsInvitationAccepted(invitationsAccepted);
    }
  }, [sharedZonesListMy, zoneId]);

  return (
    <>
      <Heading>Feedback</Heading>
      {(!isSharedZone && zoneMode === 'RW')
        ? <Text warning>{t200}</Text>
        : (isSharedZone && zoneMode === 'RW' && isInvitationAccepted === 0)
          ? <Text warning>{t238}</Text>
          : (
            <>
              <FeedbackList zoneId={zoneId} />
              <FeedbackForm zoneId={zoneId} />
            </>
          )}
    </>
  );
};

FeedbackView.propTypes = {
  zoneId: PropTypes.string,
};

FeedbackView.defaultProps = {
  zoneId: null,
};

export default FeedbackView;

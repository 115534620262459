import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import List from '@mui/material/List';

import { formatDate } from '../../../helpers';
import { Text } from '../../atoms';
import {
  ActionAddWidget,
  ActionsProgress,
  ActionWidget,
  DialogCustom,
} from '../../molecules';
import congratsImg from '../../../assets/congrats.svg';
import { useTr } from '../../../hooks';
import './ActionsList.sass';

const ActionsList = ({ actionsList, isToday }) => {
  const {
    actionsDate,
    actionDelDoneErrors,
  } = useSelector(({ actionsReducer }) => actionsReducer);

  const [doneActionsCount, setDoneActionsCount] = useState(0);
  const [todayDoneActionsCount, setTodayDoneActionsCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const t55 = useTr(55);
  const t56 = useTr(56);
  const t57 = useTr(57);

  const MAX_ACTIONS_COUNT = 5;
  const today = formatDate();

  useEffect(() => {
    if (actionsList) {
      setDoneActionsCount(actionsList.filter((act) => act.done === true).length);

      setTodayDoneActionsCount(actionsList.filter((act) => act.done === true && act.date === today).length);
    }
  }, [actionsList, today]);

  useEffect(() => {
    if (todayDoneActionsCount === 5 && !window.sessionStorage.getItem('dontShowCongrats')) {
      setIsOpen(true);
    }

    if (window.sessionStorage.getItem('dontShowCongrats') !== today) {
      window.sessionStorage.removeItem('dontShowCongrats');
    }
  }, [today, todayDoneActionsCount]);

  const handleCloseCongrats = () => {
    setIsOpen(false);
    window.sessionStorage.setItem('dontShowCongrats', today);
  };

  return (
    <div className="actions-list">
      <div className="actions-list__progress-container">
        <div className="actions-list__progress__count">
          <Text
            size="xxl"
            weight="weightBold"
            colorCustom="#fff"
            className="actions-list__count-number"
          >
            {doneActionsCount}/5
          </Text>
          {actionsList && actionsList.length === 0 && isToday && <Text warning className="actions-list__warning">{t55}</Text>}
        </div>
        <ActionsProgress doneCount={doneActionsCount} />
      </div>
      <List>
        {actionsList && actionsList.length > 0 && actionsList.map((action) => (
          <ActionWidget
            key={action.id}
            id={action.id}
            actionName={action.description}
            actionDone={action.done}
            isToday={isToday}
          />
        ))}
        {actionsDate >= today && (
          [...Array(MAX_ACTIONS_COUNT - actionsList.length)].map((_, idx) => (
            <ActionAddWidget key={`add_form_${idx}`} actionDate={actionsDate} />
          ))
        )}
      </List>
      {actionDelDoneErrors && <Text error>{actionDelDoneErrors}</Text>}
      <DialogCustom
        dialogTitle={t56}
        onClose={handleCloseCongrats}
        open={isToday && isOpen}
      >
        <>
          <Text weight="weightBold">{t57}</Text>
          <img src={congratsImg} alt="congratulations" className="actions-list__dialog-img" />
        </>
      </DialogCustom>
    </div>
  );
};

ActionsList.propTypes = {
  actionsList: PropTypes.arrayOf(PropTypes.shape),
  isToday: PropTypes.bool,
};

ActionsList.defaultProps = {
  actionsList: [],
  isToday: true,
};

export default ActionsList;

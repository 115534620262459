import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useLocation,
  matchPath,
} from 'react-router-dom';
import IconWin from '@mui/icons-material/EmojiEventsTwoTone';

import { routes } from '../../routes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AccountSettingsView from '../../views/AccountSettingsView/AccountSettingsView';
import ActionsView from '../../views/ActionsView';
import SphereView from '../../views/SphereView';
import ConfirmEmailView from '../../views/ConfirmEmailView';
import LandingPageView from '../../views/LandingPageView/LandingPageView';
import LoginView from '../../views/LoginView';
import NewPasswordView from '../../views/NewPasswordView';
import PasswordResetView from '../../views/PasswordResetView';
import RegistrationView from '../../views/RegistrationView';
import NotFoundView from '../../views/NotFoundView';
import SharedZonesListView from '../../views/SharedZonesListView';
import SpheresArchiveView from '../../views/SpheresArchiveView';
import ZoneView from '../../views/ZoneView/ZoneView';

import { setMenuItemsAction } from '../../actions';
import { MenuItem } from '../../helpers';
import { useTr } from '../../hooks';

const MainRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const todayActionsDone = useSelector(({ actionsReducer }) => actionsReducer.todayActionsDone);
  const sharedZonesListToMe = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.sharedZonesListToMe);
  const [sharedZonesCounter, setSharedZonesCounter] = useState(0);

  const t170 = useTr(170);
  const t171 = useTr(171);
  const t172 = useTr(172);

  const {
    ABOUT_URL,
    ACCOUNT_SETTINGS_URL,
    ACTIONS_URL,
    CONFIRM_EMAIL_URL,
    HOME_URL,
    LOGIN_URL,
    NEW_PASSWORD_URL,
    PASSWORD_RESET_URL,
    REGISTER_URL,
    SHARED_ZONES_LIST_URL,
    SPHERE_URL,
    SPHERES_ARCHIVE_URL,
    ZONE_URL,
  } = routes;

  const isMatchedPath = useMemo(() => matchPath(location.pathname, {
    path: ['', SPHERE_URL, SHARED_ZONES_LIST_URL, ACTIONS_URL, SPHERES_ARCHIVE_URL],
    exact: true,
    strict: false,
  }), [location]);

  const spheres = useMemo(() => new MenuItem(
    HOME_URL,
    t170,
    false,
    null,
    null,
    'circle',
    location.pathname.includes('sfera'),
  ), [location]);

  const actions = useMemo(() => new MenuItem(
    ACTIONS_URL,
    t171,
    true,
    'secondary',
    todayActionsDone === 5 ? <IconWin fontSize="small" /> : `${todayActionsDone}/5`,
    'calendar-check',
    location.pathname === ACTIONS_URL,
  ), [location, todayActionsDone]);

  const sharedZones = useMemo(() => new MenuItem(
    SHARED_ZONES_LIST_URL,
    t172,
    sharedZonesCounter > 0,
    'secondary',
    sharedZonesCounter >= 10 ? '10+' : sharedZonesCounter,
    'handshake',
    location.pathname === SHARED_ZONES_LIST_URL,
  ), [location, sharedZonesCounter]);

  useEffect(() => {
    if (sharedZonesListToMe && sharedZonesListToMe.length > 0) {
      setSharedZonesCounter(sharedZonesListToMe.filter((zone) => zone.accepted === false && zone.expire_at).length);
    }
  }, [sharedZonesListToMe]);

  useEffect(() => {
    if (isMatchedPath) {
      dispatch(setMenuItemsAction([spheres, actions, sharedZones]));
    }
  }, [dispatch, todayActionsDone, sharedZonesCounter, location, isMatchedPath]);

  const { is_authenticated: isAuthenticated } = user;

  return (
    <Switch>
      <Route exact path={HOME_URL}>
        {isAuthenticated ? <SphereView /> : <LandingPageView />}
      </Route>
      <PublicRoute restricted path={LOGIN_URL}>
        <LoginView />
      </PublicRoute>
      <PublicRoute restricted path={REGISTER_URL}>
        <RegistrationView />
      </PublicRoute>
      <PublicRoute restricted path={PASSWORD_RESET_URL}>
        <PasswordResetView />
      </PublicRoute>
      <PublicRoute path={ABOUT_URL}>
        <LandingPageView />
      </PublicRoute>
      <PrivateRoute path={ACCOUNT_SETTINGS_URL}>
        <AccountSettingsView />
      </PrivateRoute>
      <PrivateRoute path={ACTIONS_URL}>
        <ActionsView />
      </PrivateRoute>
      <PublicRoute restricted path={NEW_PASSWORD_URL}>
        <NewPasswordView />
      </PublicRoute>
      <Route path={CONFIRM_EMAIL_URL}>
        <ConfirmEmailView />
      </Route>
      <PrivateRoute path={`${SPHERE_URL}${ZONE_URL}`}>
        <ZoneView />
      </PrivateRoute>
      <PrivateRoute path={SPHERE_URL}>
        <SphereView />
      </PrivateRoute>
      <PrivateRoute path={SPHERES_ARCHIVE_URL}>
        <SpheresArchiveView />
      </PrivateRoute>
      <PrivateRoute path={SHARED_ZONES_LIST_URL}>
        <SharedZonesListView />
      </PrivateRoute>
      <PublicRoute path="*">
        <NotFoundView />
      </PublicRoute>
    </Switch>
  );
};

export default MainRoutes;

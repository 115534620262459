import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { ActionAddForm } from '../ActionAddForm/ActionAddForm';
import { useTr } from '../../../hooks';

export const ActionAddWidget = ({ actionDate }) => {
  const actionsLoading = useSelector(({ actionsReducer }) => actionsReducer.actionsLoading);
  const [isFormOpened, setIsFormOpened] = useState(false);
  const t7 = useTr(7);

  const handleFormActive = () => {
    setIsFormOpened(!isFormOpened);
  };
  return (
    isFormOpened ? (
      <ActionAddForm
        callbackSuccess={() => setIsFormOpened(false)}
        date={actionDate}
        handleCancelForm={handleFormActive}
      />
    ) : (
      <ListItem button onClick={handleFormActive}>
        <ListItemText primary={t7} disabled={actionsLoading} />
        <AddCircleOutlineOutlinedIcon edge="end" aria-hidden="true" />
      </ListItem>
    )
  );
};

ActionAddWidget.propTypes = {
  actionDate: PropTypes.string,
};

ActionAddWidget.defaultProps = {
  actionDate: null,
};

import styled, { css } from 'styled-components';
import iconSearch from '../../../assets/icons/search-duotone.svg';

// This is basic input, it can have all native input props
export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 1rem 2.2rem;
  border: 2px solid transparent;
  border-radius: 5rem;
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.weightRegular};
  background-color: ${({ theme }) => theme.colorGreyLite};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colorBlack};
  }

  :active,
  :focus {
    border: 2px solid ${({ theme }) => theme.colorSecondary};
  }

  ${({ search }) => search && css`
      padding: 1rem 2rem 1rem 4rem;
      font-size: ${({ theme }) => theme.fontSize.xs};
      background-image: url(${() => iconSearch});
      background-size: 1.6rem;
      background-position: 1.4rem 50%;
      background-repeat: no-repeat;
    `}
`;

import {
  OBJECTIVES_LIST_GET_FAILURE,
  OBJECTIVES_LIST_GET_REQUEST,
  OBJECTIVES_LIST_GET_SUCCESS,
  OBJECTIVE_ADD_EDIT_FAILURE,
  OBJECTIVE_ADD_EDIT_REQUEST,
  OBJECTIVE_ADD_EDIT_SUCCESS,
  OBJECTIVE_GET_DEL_REQUEST,
  OBJECTIVE_GET_DEL_SUCCESS,
  OBJECTIVE_GET_DEL_FAILURE,
  OBJECTIVE_DONE_REQUEST,
  OBJECTIVE_DONE_SUCCESS,
  OBJECTIVE_DONE_FAILURE,
  OBJECTIVE_CLEAR_ERRORS,
} from '../actions/actionTypes';

const objective = {
  id: null,
  name: null,
  done: false,
  is_active: true,
  start_date: null,
  end_date: null,
  zone: null,
};

const initialState = {
  objective,
  objectiveMode: null,
  objectivesCount: null,
  objectivesList: [],
  errorsObjectiveAddEdit: null,
  errorsObjectiveGetDel: null,
  errorsObjectivesListGet: null,
  errorsObjectiveDone: null,
  objectivesListNext: null,
  objectivesListPrevious: null,
  objectiveLoading: false,
};

export const objectiveReducer = (state = initialState, action) => {
  const {
    errors,
    errorsObjectivesListGet,
    errorsObjectiveGetDel,
    errorsObjectiveDone,
    objectiveData,
    objectiveMode,
    objectivesList,
    objectivesCount,
    objectivesListNext,
    objectivesListPrevious,
    type,
  } = action;

  switch (type) {
    case OBJECTIVES_LIST_GET_REQUEST:
      return {
        ...state,
        errorsObjectivesListGet: null,
        objectiveLoading: true,
      };
    case OBJECTIVES_LIST_GET_SUCCESS:
      return {
        ...state,
        objectivesList,
        objectivesCount,
        objectivesListNext,
        objectivesListPrevious,
        objectiveLoading: false,
      };
    case OBJECTIVES_LIST_GET_FAILURE:
      return {
        ...state,
        errorsObjectivesListGet,
        objectiveLoading: false,
      };
    case OBJECTIVE_ADD_EDIT_REQUEST:
      return {
        ...state,
        errorsObjectiveAddEdit: null,
        objectiveLoading: true,
      };
    case OBJECTIVE_ADD_EDIT_SUCCESS:
      return {
        ...state,
        objectiveLoading: false,
      };
    case OBJECTIVE_ADD_EDIT_FAILURE:
      return {
        ...state,
        errorsObjectiveAddEdit: {
          zone: errors.zone || null,
          name: errors.name || null,
          endDate: errors.endDate || null,
          nonField: errors.nonField || null,
        },
        objectiveLoading: false,
      };
    case OBJECTIVE_GET_DEL_REQUEST:
      return {
        ...state,
        errorsObjectiveGetDel: null,
        objectiveLoading: true,
      };
    case OBJECTIVE_GET_DEL_SUCCESS:
      return {
        ...state,
        objective: { ...objectiveData },
        objectiveMode,
        objectiveLoading: false,
      };
    case OBJECTIVE_GET_DEL_FAILURE:
      return {
        ...state,
        errorsObjectiveGetDel,
        objectiveLoading: false,
      };
    case OBJECTIVE_DONE_REQUEST:
      return {
        ...state,
        errorsObjectiveDone: null,
        objectiveLoading: true,
      };
    case OBJECTIVE_DONE_SUCCESS:
      return {
        ...state,
        objective: { ...objectiveData },
        objectiveLoading: false,
      };
    case OBJECTIVE_DONE_FAILURE:
      return {
        ...state,
        errorsObjectiveDone,
        objectiveLoading: false,
      };
    case OBJECTIVE_CLEAR_ERRORS:
      return {
        ...state,
        errorsObjectiveAddEdit: null,
        errorsObjectiveGetDel: null,
        errorsObjectiveDone: null,
        errorsObjectivesListGet: null,
      };
    default:
      return state;
  }
};

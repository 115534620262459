import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@mui/material';

import { ACCOUNTS_API_URL } from '../apiRequests';
import { routes } from '../routes';
import { newUserUpdateData } from '../actions';
import { ErrorsList, Loader } from '../components/atoms';
import { ConfirmButton } from '../components/molecules';
import AuthTemplate from '../templates/AuthTemplate';
import { useAxiosPostNew, useTr } from '../hooks';

const RemindPassLink = styled(Button)`
  display: flex;
  justify-content: center;
`;

const LoginView = () => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (credentials) => {
    setEmail(credentials.email);
    setPassword(credentials.password);
    setIsSaving(true);
  };

  const {
    responseData,
    responseError,
    isFetching,
  } = useAxiosPostNew({
    url: `${ACCOUNTS_API_URL}/authentication/login`,
    body: { email, password },
    isSaving,
    setIsSaving,
  });

  useEffect(() => {
    if (responseData) {
      dispatch(newUserUpdateData(responseData.meta?.user));
    }

    if (responseError) {
      dispatch(newUserUpdateData({}));
    }
  }, [responseData, responseError]);

  const t141 = useTr(141);
  const t142 = useTr(142);
  const t205 = useTr(205);
  const t233 = useTr(233);

  return (
    <AuthTemplate
      authFormHeader={t141}
      buttonName={isFetching ? <Loader small /> : t141}
      disabled={isFetching}
      errorEmail={responseError?.email && responseError.email[0]}
      errorPassword={responseError?.password && responseError.password[0]}
      errorNonFiled={responseError?.non_field_errors && <ErrorsList list={responseError.non_field_errors} />}
      isPasswordField
      onSubmit={handleSubmit}
    >
      <>
        <RemindPassLink buttonlink="true" component={Link} to={routes.PASSWORD_RESET_URL}>{t233}</RemindPassLink>
        <ConfirmButton
          confirmNote={t205}
          linkName={t142}
          tag={Link}
          url="/rejestracja"
        />
      </>
    </AuthTemplate>
  );
};

export default LoginView;

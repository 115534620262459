// USAGE:
// const firstRender = useFirstRender();
// if (!firstRender) {
//   do it not on first render
// }

import { useRef, useEffect } from 'react';

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

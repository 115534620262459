import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPatch,
  OBJECTIVE_API_URL,
  ZONE_API_URL,
} from '../apiRequests';

import {
  OBJECTIVE_ADD_EDIT_FAILURE,
  OBJECTIVE_ADD_EDIT_REQUEST,
  OBJECTIVE_ADD_EDIT_SUCCESS,
  OBJECTIVES_LIST_GET_FAILURE,
  OBJECTIVES_LIST_GET_REQUEST,
  OBJECTIVES_LIST_GET_SUCCESS,
  OBJECTIVE_GET_DEL_REQUEST,
  OBJECTIVE_GET_DEL_SUCCESS,
  OBJECTIVE_GET_DEL_FAILURE,
  OBJECTIVE_DONE_REQUEST,
  OBJECTIVE_DONE_SUCCESS,
  OBJECTIVE_DONE_FAILURE,
  OBJECTIVE_CLEAR_ERRORS,
} from './actionTypes';

// OBJECTIVES LIST GET
export const objectivesListGetAction = (zoneId, isActive = null, done = null, fetchUrl = `${ZONE_API_URL}/${zoneId}/objectives`) => (dispatch) => {
  dispatch({ type: OBJECTIVES_LIST_GET_REQUEST });

  return axiosGet(
    fetchUrl,
    {
      zone_id: zoneId,
      is_active: isActive,
      done,
    },
  ).then((response) => {
    dispatch({
      type: OBJECTIVES_LIST_GET_SUCCESS,
      objectivesList: response.data.data.results.reverse(),
      objectivesCount: response.data.data.count,
      objectivesListNext: response.data.data.next || null,
      objectivesListPrevious: response.data.data.previous || null,
    });
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response && (response.status === 403 || response.status) === 404) {
        dispatch({
          type: OBJECTIVES_LIST_GET_FAILURE,
          errorsObjectivesListGet: response.data ? response.data.data.detail : null,
        });
      } else {
        dispatch({
          type: OBJECTIVES_LIST_GET_FAILURE,
          errorsObjectivesListGet: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// SINGLE OBJECTIVE ADD / EDIT
export const objectiveAddEditAction = (zoneId, objectiveId = null, name, endDate, callbackSuccess = null, callbackError = null) => (dispatch) => {
  dispatch({ type: OBJECTIVE_ADD_EDIT_REQUEST });

  const method = objectiveId ? axiosPatch : axiosPost;

  return method(
    `${OBJECTIVE_API_URL}/${objectiveId || 'create'}`,
    {
      zone: zoneId,
      objective_id: objectiveId,
      name,
      end_date: endDate && endDate.length ? endDate : null,
    },
  ).then(() => {
    dispatch({
      type: OBJECTIVE_ADD_EDIT_SUCCESS,
    });
    objectivesListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: OBJECTIVE_ADD_EDIT_FAILURE,
          errors: {
            zone: response.data.data.zone ? response.data.data.zone[0] : null,
            name: response.data.data.name ? response.data.data.name[0] : null,
            endDate: response.data.data.end_date ? response.data.data.end_date[0] : null,
            nonField: response.data.data.non_field_errors ? response.data.data.non_field_errors[0] : null,
          },
        });
      } else if (response.status === 403 || response.status === 404) {
        dispatch({
          type: OBJECTIVE_ADD_EDIT_FAILURE,
          errors: { nonField: response.data && response.data.data.detail },
        });
      } else {
        dispatch({
          type: OBJECTIVE_ADD_EDIT_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
      if (callbackError !== null) {
        callbackError();
      }
    }
  });
};

// SINGLE OBJECTIVE GET / DEL
export const objectiveGetDelAction = (objectiveId, zoneId, callbackSuccess = null, objectiveDelete = false) => (dispatch) => {
  dispatch({ type: OBJECTIVE_GET_DEL_REQUEST });

  const method = objectiveDelete ? axiosDelete : axiosGet;

  return method(
    `${OBJECTIVE_API_URL}/${objectiveId}`,
    { objective_id: objectiveId },
  ).then((response) => {
    dispatch({
      type: OBJECTIVE_GET_DEL_SUCCESS,
      objectiveData: response.data.data,
      objectiveMode: response.data.meta.mode,
    });
    objectivesListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: OBJECTIVE_GET_DEL_FAILURE,
          errorsObjectiveGetDel: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: OBJECTIVE_GET_DEL_FAILURE,
          errorsObjectiveGetDel: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// SINGLE OBJECTIVE DONE
export const objectiveDoneAction = (objectiveId, zoneId, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: OBJECTIVE_DONE_REQUEST });

  return axiosPatch(
    `${OBJECTIVE_API_URL}/${objectiveId}/done`,
    { objective_id: objectiveId },
  ).then((response) => {
    dispatch({
      type: OBJECTIVE_DONE_SUCCESS,
      objectiveData: response.data.data,
    });
    objectivesListGetAction(zoneId)(dispatch);
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    if (response) {
      if (response.status === 403 || response.status === 404) {
        dispatch({
          type: OBJECTIVE_DONE_FAILURE,
          errorsObjectiveDone: response.data && response.data.data.detail,
        });
      } else {
        dispatch({
          type: OBJECTIVE_DONE_FAILURE,
          errorsObjectiveDone: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.`,
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearObjectiveErrorsAction = () => (dispatch) => {
  dispatch({ type: OBJECTIVE_CLEAR_ERRORS });
};

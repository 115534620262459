import React from 'react';
import PropTypes from 'prop-types';
import './SphereBig.sass';

const SphereBigSvg = ({
  zone1color1,
  zone1color2,
  zone2color1,
  zone2color2,
  zone3color1,
  zone3color2,
  zone4color1,
  zone4color2,
  zone5color1,
  zone5color2,
  onClickZone1,
  onClickZone2,
  onClickZone3,
  onClickZone4,
  onClickZone5,
  ...props
}) => (
  <svg width="943px" height="992px" viewBox="0 0 943 992" {...props}>
    <defs>
      <linearGradient x1="58.9436409%" y1="30.160125%" x2="28.782593%" y2="69.0771553%" id="linearGradient-1">
        <stop stopColor={zone1color1} offset="0%" />
        <stop stopColor={zone1color2} offset="100%" />
      </linearGradient>
      <linearGradient x1="58.9436409%" y1="29.986795%" x2="28.782593%" y2="69.2438219%" id="linearGradient-2">
        <stop stopColor={zone2color1} offset="0%" />
        <stop stopColor={zone2color2} offset="100%" />
      </linearGradient>
      <linearGradient x1="58.9436409%" y1="29.6471641%" x2="28.782593%" y2="69.570396%" id="linearGradient-3">
        <stop stopColor={zone3color1} offset="0%" />
        <stop stopColor={zone3color2} offset="100%" />
      </linearGradient>
      <linearGradient x1="58.9436409%" y1="30.0978389%" x2="28.782593%" y2="69.1370469%" id="linearGradient-4">
        <stop stopColor={zone4color1} offset="0%" />
        <stop stopColor={zone4color2} offset="100%" />
      </linearGradient>
      <linearGradient x1="58.9436409%" y1="30.285072%" x2="28.782593%" y2="68.9570118%" id="linearGradient-5">
        <stop stopColor={zone5color1} offset="0%" />
        <stop stopColor={zone5color2} offset="100%" />
      </linearGradient>
    </defs>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M503.780799,81.1134018 L517.517145,276.627794 C517.83799,281.194446 521.21885,284.960097 525.724651,285.769428 C558.222604,291.606703 587.758995,303.875626 614.333825,322.576196 C640.915479,341.281568 661.805997,365.42577 677.005379,395.008803 C679.105752,399.096831 683.722208,401.226812 688.195519,400.171812 L878.226586,355.354246 C883.601959,354.086497 886.931849,348.701184 885.664103,343.32581 C885.576563,342.95463 885.467861,342.588762 885.33853,342.229998 C857.163351,264.071874 808.587287,200.248265 739.610338,150.75917 C670.714255,101.328094 595.598371,74.5501673 514.262686,70.4253892 C508.746928,70.1456714 504.048765,74.3903181 503.769044,79.9060758 C503.748642,80.3083872 503.752567,80.7115639 503.780799,81.1134018 Z" id="zone1" fill="url(#linearGradient-1)" onClick={onClickZone1} className="sphere-big__zone-svg" role="button" stroke={zone1color2} strokeWidth="6" />
      <path d="M607.438398,455.979035 L617.185162,650.985792 C617.421718,655.718589 620.947583,659.635547 625.629525,660.36681 C660.085843,665.748473 690.755162,678.617037 717.637479,698.972504 C744.454756,719.27872 765.480224,743.596605 780.713884,771.926159 C782.87126,775.938161 787.467417,777.985155 791.892738,776.904894 L982.50613,730.37445 C987.871435,729.064733 991.159137,723.653557 989.849418,718.288252 C989.765015,717.942493 989.662213,717.601486 989.541451,717.266687 C961.349614,639.107924 912.728582,575.286742 843.678356,525.803141 C774.66422,476.345403 699.413547,449.575146 617.926336,445.49237 C612.410407,445.216004 607.714825,449.463508 607.438459,454.979437 C607.421775,455.312427 607.421755,455.646043 607.438398,455.979035 Z" id="zone2" fill="url(#linearGradient-2)" transform="translate(800.035871, 611.925615) rotate(72.000000) translate(-800.035871, -611.925615) " onClick={onClickZone2} className="sphere-big__zone-svg" role="button" stroke={zone2color2} strokeWidth="6" />
      <path d="M279.774255,668.306236 L294.793269,864.91254 C295.140644,869.459914 298.520716,873.19618 303.010674,873.995904 C334.532251,879.61034 363.416791,891.952522 389.664294,911.02245 C415.998333,930.155249 436.973929,955.280652 452.591083,986.398662 C454.702552,990.605874 459.462355,992.770853 464.021121,991.597572 L654.700363,942.522809 C660.048913,941.146266 663.268858,935.694498 661.892312,930.345949 C661.814244,930.042617 661.721968,929.743121 661.615788,929.44845 C633.431084,851.229625 584.794212,787.364504 515.705171,737.853087 C446.775552,688.455917 371.624661,661.690694 290.252498,657.557418 C284.736761,657.277246 280.03825,661.521512 279.758079,667.037249 C279.736598,667.46016 279.742,667.884009 279.774255,668.306236 Z" id="zone3" fill="url(#linearGradient-3)" transform="translate(472.062686, 825.385325) rotate(144.000000) translate(-472.062686, -825.385325) " onClick={onClickZone3} className="sphere-big__zone-svg" role="button" stroke={zone3color2} strokeWidth="6" />
      <path d="M-21.7313743,425.587303 L-8.90297717,619.602475 C-8.59912412,624.197866 -5.1960721,627.991499 -0.66055755,628.790906 C33.0965788,634.740764 63.4847056,647.647678 90.5038229,667.511647 C117.362499,687.257663 137.958715,711.465963 152.292469,740.136547 C154.373608,744.299278 159.051347,746.476027 163.576193,745.387342 L353.104603,699.786507 C358.474213,698.494566 361.779821,693.094315 360.487884,687.724704 C360.401974,687.36764 360.296459,687.01558 360.171818,686.670123 C331.978074,608.52745 283.361289,544.718039 214.321463,495.241891 C145.358707,445.820974 70.168696,419.053769 -11.2485695,414.940276 C-16.7643819,414.661597 -21.4617451,418.907133 -21.7404238,424.422945 C-21.7600252,424.810911 -21.7570036,425.199689 -21.7313743,425.587303 Z" id="zone4" fill="url(#linearGradient-4)" transform="translate(170.678978, 580.900844) rotate(216.000000) translate(-170.678978, -580.900844) " onClick={onClickZone4} className="sphere-big__zone-svg" role="button" stroke={zone4color2} strokeWidth="6" />
      <path d="M114.317883,64.1066892 L128.975951,258.895062 C129.322503,263.500317 132.77762,267.269246 137.335476,268.013842 C171.248612,273.554076 200.83824,286.057362 226.104361,305.523702 C251.345715,324.97096 271.85975,348.963492 287.646467,377.5013 C289.810413,381.41309 294.307809,383.413434 298.662038,382.400787 L488.942759,338.147911 C494.322045,336.896869 497.66865,331.521926 496.41761,326.142639 C496.328516,325.75955 496.216899,325.382051 496.08334,325.012109 C467.885196,246.90662 419.278229,183.124415 350.262441,133.665493 C281.329125,84.2656736 206.17387,57.5002289 124.796676,53.369159 C119.280933,53.0891596 114.582553,57.3335645 114.302549,62.8493071 C114.281277,63.2683411 114.286398,63.6882985 114.317883,64.1066892 Z" id="zone5" fill="url(#linearGradient-5)" transform="translate(306.619956, 218.539959) rotate(288.000000) translate(-306.619956, -218.539959) " onClick={onClickZone5} className="sphere-big__zone-svg" role="button" stroke={zone5color2} strokeWidth="6" />
      <path d="M493.879293,981 C637.919528,981 781.959764,981 926,981 C929.865993,981 933,977.865993 933,974 C933,970.134007 929.865993,967 926,967 L503.156093,967 C378.334427,963.00535 273.752637,925.392092 189.410725,854.160228 C68.9529025,752.426284 25.5201168,628.748785 22.9965157,496 C20.2494102,351.494235 95.6753665,194.755451 242.612173,98.6875483 C312.519103,52.9821035 399.367076,28.4195874 503.156093,25 L926,25 C929.865993,25 933,21.8659932 933,18 C933,14.1340068 929.865993,11 926,11 C781.959764,11 637.919528,11 493.879293,11 C226.087854,11 9,228.141896 9,496 C9,763.858104 226.087854,981 493.879293,981 Z" id="half-circle" fill="#D8D8D8" className="sphere-big__half-circle" />
    </g>
  </svg>
);

SphereBigSvg.propTypes = {
  zone1color1: PropTypes.string,
  zone1color2: PropTypes.string,
  zone2color1: PropTypes.string,
  zone2color2: PropTypes.string,
  zone3color1: PropTypes.string,
  zone3color2: PropTypes.string,
  zone4color1: PropTypes.string,
  zone4color2: PropTypes.string,
  zone5color1: PropTypes.string,
  zone5color2: PropTypes.string,
  onClickZone1: PropTypes.func,
  onClickZone2: PropTypes.func,
  onClickZone3: PropTypes.func,
  onClickZone4: PropTypes.func,
  onClickZone5: PropTypes.func,
};

SphereBigSvg.defaultProps = {
  zone1color1: '#f4f4f4',
  zone1color2: '#f4f4f4',
  zone2color1: '#f4f4f4',
  zone2color2: '#f4f4f4',
  zone3color1: '#f4f4f4',
  zone3color2: '#f4f4f4',
  zone4color1: '#f4f4f4',
  zone4color2: '#f4f4f4',
  zone5color1: '#f4f4f4',
  zone5color2: '#f4f4f4',
  onClickZone1: null,
  onClickZone2: null,
  onClickZone3: null,
  onClickZone4: null,
  onClickZone5: null,
};

export default SphereBigSvg;

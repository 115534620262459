import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  fetchDataWithRedux,
  SPHERE_API_URL,
} from '../apiRequests';

import {
  NEW_SPHERES_LIST_GET_REQUEST,
  NEW_SPHERES_LIST_GET_SUCCESS,
  NEW_SPHERES_LIST_GET_FAILURE,
  NEW_SPHERE_POST_PATCH_REQUEST,
  NEW_SPHERE_POST_PATCH_SUCCESS,
  NEW_SPHERE_POST_PATCH_FAILURE,
  NEW_SPHERE_DELETE_REQUEST,
  NEW_SPHERE_DELETE_SUCCESS,
  NEW_SPHERE_DELETE_FAILURE,
  SPHERES_CLEAR_ERRORS,
} from './actionTypes';

// GET LIST OF SPHERES
const getSpheresListRequest = () => ({ type: NEW_SPHERES_LIST_GET_REQUEST });
const getSpheresListSuccess = (response) => (
  {
    type: NEW_SPHERES_LIST_GET_SUCCESS,
    newSpheresListData: response?.data.data,
  }
);
const getSpheresListFailure = (response) => (
  {
    type: NEW_SPHERES_LIST_GET_FAILURE,
    newGetSpheresListError: response?.data.data ? response?.data.data.detail : null,
  }
);

export const getSpheresListAction = (isActive, callbackSuccess, fetchUrl = `${SPHERE_API_URL}/my`) => fetchDataWithRedux(
  axiosGet,
  fetchUrl,
  { is_active: isActive },
  getSpheresListRequest,
  getSpheresListSuccess,
  getSpheresListFailure,
  callbackSuccess,
);

// EDIT/ADD SINGLE SPHERE
const editAddSphereRequest = () => ({ type: NEW_SPHERE_POST_PATCH_REQUEST });
const editAddSphereSuccess = (response) => ({
  type: NEW_SPHERE_POST_PATCH_SUCCESS,
  newSphereDataId: response?.data.data.id,
});

const editAddSphereFailure = (response) => ({
  type: NEW_SPHERE_POST_PATCH_FAILURE,
  newErrors: {
    newEditAddSphereNameError: response?.data.data.name ? response?.data.data.name[0] : null,
    newEditAddSpherePhotoUrlError: response?.data.data.photo_url ? response?.data.data.photo_url[0] : null,
    newEditAddSphereMainError: response?.data.data.is_main ? response?.data.data.is_main[0] : null,
    newEditAddSphereNonFieldError: response?.data.data.detail ? response?.data.data.detail : null,
  },
});

export const editAddSphereAction = (name, photoUrl, isMain, id, callbackSuccess) => fetchDataWithRedux(
  id ? axiosPatch : axiosPost,
  `${SPHERE_API_URL}/${id || 'my'}`,
  {
    name,
    photo_url: photoUrl,
    is_main: isMain,
    id,
  },
  editAddSphereRequest,
  editAddSphereSuccess,
  editAddSphereFailure,
  callbackSuccess,
);

// DELETE SINGLE SPHERE
const delSphereRequest = () => ({ type: NEW_SPHERE_DELETE_REQUEST });
const delSphereSuccess = () => ({ type: NEW_SPHERE_DELETE_SUCCESS });
const delSphereFailure = (response) => ({
  type: NEW_SPHERE_DELETE_FAILURE,
  newDeleteSphereNonFieldError: response?.data.data.detail ? response?.data.data.detail : null,
});

export const delSphereAction = (id, callbackSuccess, callbackError) => fetchDataWithRedux(
  axiosDelete,
  `${SPHERE_API_URL}/${id}`,
  {},
  delSphereRequest,
  delSphereSuccess,
  delSphereFailure,
  callbackSuccess,
  callbackError,
);

// CLEAR FORMS ERRORS
export const clearSphereErrorsAction = () => (dispatch) => {
  dispatch({ type: SPHERES_CLEAR_ERRORS });
};

export const routes = {
  ABOUT_URL: '/aplikacja-umaster',
  ACCOUNT_SETTINGS_URL: '/ustawienia-konta',
  ACCOUNT_SETTINGS_PROFILE_URL: '/profil',
  ACCOUNT_SETTINGS_PASSWORD_URL: '/haslo',
  ACCOUNT_SETTINGS_LANGUAGE_URL: '/jezyk',
  ACTIONS_URL: '/zwycieski-dzien',
  CONFIRM_EMAIL_URL: '/potwierdz-email/:token',
  FEEDBACK_URL: '/feedback',
  HABITS_URL: '/nawyki',
  HELP_URL: '/pomoc',
  HOME_URL: '/',
  LOGIN_URL: '/logowanie',
  NEW_PASSWORD_URL: '/zmien-haslo/:token',
  OBJECTIVES_URL: '/cele',
  PASSWORD_RESET_URL: '/reset-hasla',
  REGISTER_URL: '/rejestracja/:token?',
  SPHERES_ARCHIVE_URL: '/archiwum-sfer',
  SHARED_ZONES_LIST_URL: '/udostepnione-obszary',
  SPHERE_URL: '/sfera/:id',
  SUBSCRIPTIONS_URL: '/plany-umaster',
  VISION_URL: '/wizja',
  ZONE_URL: '/obszar/:zoneId',
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { routes } from '../routes';
import { userConfirmEmailAction } from '../actions';
import { Text } from '../components/atoms';
import { Card } from '../components/molecules';
import { useTr } from '../hooks';
import './Views.sass';
import cancelImg from '../assets/icons/icon-cancel.svg';
import unlockedImg from '../assets/icons/unlock.svg';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 9.5rem 1.5rem 1.5rem;
`;

const ButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    width: auto;
    margin: 2.5rem auto 0;
  }
`;

const ConfirmEmailView = () => {
  const errors = useSelector(({ userReducer }) => userReducer.emailConfirmedErrors);
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);

  const dispatch = useDispatch();
  const t141 = useTr(141);
  const t196 = useTr(196);
  const t197 = useTr(197);
  const t198 = useTr(198);
  const t199 = useTr(199);

  const { HOME_URL, LOGIN_URL } = routes;
  const { token } = useParams();

  useEffect(() => {
    dispatch(userConfirmEmailAction(token));
  }, [dispatch, token]);

  return (
    <Wrapper>
      <Card cardHeader={errors.nonField ? t196 : t197}>
        <>
          <img src={errors.nonField ? cancelImg : unlockedImg} alt="" className="unlocked-img" />
          {errors.nonField ? <Text error centered>{errors.nonField}</Text> : <Text success centered>{t198}</Text>}
          <ButtonStyled
            color="primary"
            component="a"
            href={user.is_authenticated ? HOME_URL : LOGIN_URL}
            variant="contained"
          >
            {user.is_authenticated ? t199 : t141}
          </ButtonStyled>
        </>
      </Card>
    </Wrapper>
  );
};

export default ConfirmEmailView;

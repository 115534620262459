import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { useTr } from '../../../hooks';

import './Pagination.sass';

export const Pagination = ({
  className,
  isNext,
  isPrev,
  nextButtonText,
  onClickNext,
  onClickPrev,
  prevButtonText,
}) => {
  const t32 = useTr(32);
  const t33 = useTr(33);

  return (
    (isPrev || isNext)
      && (
        <div className={`pagination ${className || ''}`}>
          {onClickPrev && <Button disabled={!isPrev} onClick={onClickPrev}>{prevButtonText || t33}</Button>}
          {onClickNext && <Button disabled={!isNext} onClick={onClickNext}>{nextButtonText || t32}</Button>}
        </div>
      )
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  isNext: PropTypes.bool,
  isPrev: PropTypes.bool,
  nextButtonText: PropTypes.string,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
  prevButtonText: PropTypes.string,
};

Pagination.defaultProps = {
  className: null,
  isNext: false,
  isPrev: false,
  nextButtonText: '',
  onClickNext: null,
  onClickPrev: null,
  prevButtonText: '',
};

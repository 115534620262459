import {
  SET_INITIAL_GRID_ACTION,
  SET_MENU_ITEMS,
} from '../actions/actionTypes';

const initialState = {
  initialGrid: null,
  menuItems: [],
};

export const helpersReducer = (state = initialState, action) => {
  const {
    type,
    initialGrid,
    menuItems,
  } = action;

  switch (type) {
    case SET_INITIAL_GRID_ACTION:
      return {
        ...state,
        initialGrid,
      };
    case SET_MENU_ITEMS:
      return {
        ...state,
        menuItems,
      };
    default:
      return state;
  }
};

export const theme = {
  // colors
  colorBluePrimary: '#7D19E3',
  colorPrimary: '#930DD4',
  colorPrimaryBg: '#F7EBFC',
  colorBlueBg: '#edf5fe',
  colorPinkSecondary: '#FF0030',
  colorSecondary: '#EE024A',
  colorYellow: '#FCB703',
  colorOrange: '#ff5e00',
  colorGreyLite: '#f4f4f4',
  colorGreyMedium: '#d2d2d2',
  colorGreyDark: '#5e5e5e',
  colorBlack: '#000000',
  colorError: '#ff0000',
  colorSuccess: '#40a913',
  colorBg: '#fafbfc',
  colorBgBlueShadow: '#f3f7fd',
  colorWhite: '#ffffff',

  pink: '#FF0030',
  blue: '#930DD4',
  green: '#40a913',

  // fontWeight
  weightRegular: 400,
  weightBold: 700,
  weightExtraBold: 800,

  // fontSize
  fontSize: {
    xxs: '1.1rem',
    xs: '1.3rem',
    s: '1.6rem',
    m: '2.1rem',
    l: '2.4rem',
    xl: '3.2rem',
    xxl: '8rem',
  },

  globalWidth: '1300px',
};

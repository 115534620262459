export const dimensions = {
  widthXS: '300px',
  widthS: '600px',
  widthM: '900px',
  widthL: '1400px',
  widthXL: '1920px',
  globalWidth: '1100px',
};

export const media = {
  xs: `(min-width: ${dimensions.widthXS})`,
  s: `(min-width: ${dimensions.widthS})`,
  m: `(min-width: ${dimensions.widthM})`,
  l: `(min-width: ${dimensions.widthL})`,
  global: `(min-width: ${dimensions.globalWidth})`,
};

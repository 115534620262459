import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Badge,
  IconButton,
  SvgIcon,
  Tooltip,
  Zoom,
} from '@mui/material';

export const ButtonIcon = ({
  ariaControls,
  ariaHasPopup,
  ariaLabel,
  badgeColor,
  badgeContent,
  badgeVariant,
  btnClassName,
  className,
  color,
  disabled,
  icon,
  href,
  onClick,
  targetBlank,
  title,
  to,
  widthBadge,
  submit,
  ...toltipProps
}) => (
  <div className={className}>
    <Tooltip title={title} TransitionComponent={Zoom} {...toltipProps}>
      <span className={btnClassName}>
        <IconButton
          aria-label={ariaLabel}
          aria-controls={ariaControls}
          aria-haspopup={ariaHasPopup}
          component={to ? Link : href ? 'a' : 'button'}
          disabled={disabled}
          onClick={onClick}
          href={href}
          target={targetBlank ? '_blank' : null}
          to={to}
          style={{ color }}
          type={submit ? 'submit' : 'button'}
        >
          {
            widthBadge
              ? <Badge badgeContent={badgeContent} color={badgeColor} variant={badgeVariant}><SvgIcon>{icon}</SvgIcon></Badge>
              : <SvgIcon>{icon}</SvgIcon>
          }
        </IconButton>
      </span>
    </Tooltip>
  </div>
);

ButtonIcon.propTypes = {
  ariaControls: PropTypes.string,
  ariaHasPopup: PropTypes.bool,
  ariaLabel: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeContent: PropTypes.string,
  badgeVariant: PropTypes.string,
  btnClassName: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  targetBlank: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string,
  widthBadge: PropTypes.bool,
  submit: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  ariaControls: null,
  ariaHasPopup: false,
  ariaLabel: null,
  badgeColor: null,
  badgeContent: null,
  badgeVariant: null,
  btnClassName: null,
  className: null,
  color: '#000000',
  disabled: false,
  href: null,
  onClick: null,
  targetBlank: false,
  title: null,
  to: null,
  widthBadge: false,
  submit: false,
};

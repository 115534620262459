/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';

import { useTr } from '../../../hooks';
import { Text } from '../../atoms';
import './ImageUpload.sass';

export const ImageUpload = ({ successText, onImageUpload }) => {
  const t2 = useTr(2);
  const t1 = useTr(1);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    multiple: false,
  });

  useEffect(() => {
    if (files.length === 0) return;
    onImageUpload(files);
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);


  return (
    <section className="image-upload">
      <div {...getRootProps({ className: 'image-upload__dropzone' })}>
        <input
          {...getInputProps()}
          id="img-uploader"
        />
        <Button>
          {successText || t1}
          <Text info>{t2}</Text>
        </Button>
      </div>
    </section>
  );
};

ImageUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
  imageUploadText: '',
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '../../../atoms';
import { SphereImage } from '../../../molecules';
import SphereMiniSvg from './SphereMiniSvg';
import { useTr } from '../../../../hooks';
import './SphereMini.sass';

const SphereMini = ({
  id,
  is_active: isActive,
  is_main: isMain,
  name,
  noSphereName,
  photo_url: photoUrl,
  singleZone,
  singleZoneColor,
  singleZoneName,
  sphereMiniHref,
  zones,
}) => {
  const [colorsList, setColorsList] = useState([]);
  const { id: sphereId } = useParams();

  const t108 = useTr(108);
  const t125 = useTr(125);
  const t126 = useTr(126);

  const Tag = isActive ? Link : 'div';
  const ContainerTag = singleZoneName ? 'div' : 'li';

  useEffect(() => {
    const list = [];
    zones.map((zone) => list.push(zone.color));

    setColorsList([...list]);
  }, [zones]);

  return (
    <ContainerTag key={id} className={`sphere-mini${singleZone ? ' sphere-mini--single-zone' : ''}${(!singleZone && isActive) ? ' sphere-mini--main-page' : ''}`}>
      {isMain && <FontAwesomeIcon icon="crown" color="#b8b8b8" title={t108} />}
      <Tag to={sphereMiniHref || `${id}`} className="sphere-mini__sphere">
        <SphereMiniSvg
          color1={singleZoneColor || colorsList[0]}
          color2={colorsList[1]}
          color3={colorsList[2]}
          color4={colorsList[3]}
          color5={colorsList[4]}
          borderColor={((singleZoneName && isActive) || id === parseInt(sphereId, 10)) ? '#930DD4' : '#E4E4E4'}
          className="sphere-mini__colors"
        />
        <SphereImage
          className="sphere-mini__img-container"
          url={photoUrl}
          sphereName={name}
        />
      </Tag>
      {!noSphereName && (
        <div className="sphere-mini__name">
          <div className="sphere-mini__name-part">
            <Text size="xxs" weight="weightBold" className="sphere-mini__label">{t125}</Text>
            <Text size="xxs" weight="weightBold" className="sphere-mini__sphere-title">{name}</Text>
          </div>
          {singleZoneName && (
            <div className="sphere-mini__name-part">
              <Text size="xxs" weight="weightBold" className="sphere-mini__label">{t126}</Text>
              <Text size="s" weight="weightBold" className="sphere-mini__sphere-title">{singleZoneName}</Text>
            </div>
          )}
        </div>
      )}
    </ContainerTag>
  );
};

SphereMini.propTypes = {
  id: PropTypes.number,
  is_active: PropTypes.bool.isRequired,
  is_main: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noSphereName: PropTypes.bool,
  photo_url: PropTypes.string,
  singleZone: PropTypes.bool,
  singleZoneColor: PropTypes.string,
  singleZoneName: PropTypes.string,
  sphereMiniHref: PropTypes.string,
  zones: PropTypes.arrayOf(PropTypes.shape),
};

SphereMini.defaultProps = {
  id: null,
  is_main: false,
  noSphereName: false,
  photo_url: null,
  singleZone: false,
  singleZoneColor: '',
  singleZoneName: '',
  sphereMiniHref: '',
  zones: [],
};

export default SphereMini;

// Add or edit Sphere form
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { Loader, Text } from '../../atoms';
import { DialogCustom, InputField } from '../../molecules';
import ConfirmWidget from '../ConfirmWidget/ConfirmWidget';
import SuccessSvg from '../../../assets/SuccessSvg';
import { useTr } from '../../../hooks';
import '../ObjectiveManageForm/ObjectiveManageForm.sass';

import {
  clearSubObjectiveErrorsAction,
  subObjectiveAddEditAction,
  subObjectiveGetDelAction,
  subObjectiveDoneAction,
} from '../../../actions';

const SubObjectiveManageForm = ({
  className,
  subObjectiveAdd,
  subObjectiveId,
  name,
  openDialogButtonText,
  objectiveId,
}) => {
  const dispatch = useDispatch();
  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const {
    errorsObjectiveAddEdit,
    errorsSubObjectiveDone,
    errorsSubObjectiveGetDel,
    subObjectiveLoading,
  } = useSelector(({ subObjectiveReducer }) => subObjectiveReducer);

  const t11 = useTr(11);
  const t31 = useTr(31);
  const t45 = useTr(45);
  const t130 = useTr(130);
  const t131 = useTr(131);
  const t132 = useTr(132);
  const t133 = useTr(133);
  const t134 = useTr(134);
  const t135 = useTr(135);

  const [newSubObjectiveId, setNewSubObjectiveId] = useState(subObjectiveId);
  const [newName, setNewName] = useState(name);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteWidgetOpened, setIsDeleteWidgetOpened] = useState(false);
  const [isDoneWidgetOpened, setIsDoneWidgetOpened] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
    setNewSubObjectiveId(subObjectiveId);
    setNewName(name);
    setIsDeleteWidgetOpened(false);
    setIsDoneWidgetOpened(false);
    dispatch(clearSubObjectiveErrorsAction());
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearSubObjectiveErrorsAction());
    dispatch(subObjectiveAddEditAction(
      objectiveId,
      newSubObjectiveId || null,
      newName,
      handleClose,
    ));
  };

  const handleOpenConfirmWidget = () => {
    setIsDeleteWidgetOpened(true);
  };

  const handleOpenDoneWidget = () => {
    setIsDoneWidgetOpened(true);
  };

  const handleObjectiveAction = (e) => {
    e.preventDefault();
    if (isDeleteWidgetOpened) {
      dispatch(subObjectiveGetDelAction(newSubObjectiveId, objectiveId, handleClose, true));
    }

    if (isDoneWidgetOpened) {
      dispatch(subObjectiveDoneAction(newSubObjectiveId, objectiveId, handleClose));
    }
  };

  return (
    <DialogCustom
      onClose={handleClose}
      onOpen={handleOpen}
      openIcon={subObjectiveAdd ? 'plus' : 'cogs'}
      openDialogButton={subObjectiveAdd}
      openDialogButtonText={openDialogButtonText || t31}
      openDialogButtonIcon={!subObjectiveAdd}
      dialogTitle={isDeleteWidgetOpened ? t130 : isDoneWidgetOpened ? t131 : subObjectiveAdd ? t31 : t45}
      open={isDialogOpen}
      openBtnClassName={className}
      btnIconClassName="objective-manage-form__btn-icon"
      btnVariant="outlined"
    >
      {subObjectiveLoading
        ? <Loader /> : (
          <>
            {(isDeleteWidgetOpened || isDoneWidgetOpened) ? (
              <>
                <ConfirmWidget
                  additionalContent={!isDeleteWidgetOpened && <SuccessSvg className="objective-manage-form__success-svg" zoneColor={zoneColor} />}
                  confirmationBtnText={isDeleteWidgetOpened ? t130 : t11}
                  onConfirm={handleObjectiveAction}
                  onCancel={() => isDeleteWidgetOpened ? setIsDeleteWidgetOpened(false) : setIsDoneWidgetOpened(false)}
                  confirmationText={isDeleteWidgetOpened ? t132 : t133}
                  positive={!isDeleteWidgetOpened}
                />
                {errorsSubObjectiveDone && <Text error>{errorsSubObjectiveDone}</Text>}
                {errorsSubObjectiveGetDel && <Text error>{errorsSubObjectiveGetDel}</Text>}
              </>
            ) : (
              <>
                {(!isDeleteWidgetOpened && !isDoneWidgetOpened && !subObjectiveAdd) && (
                  <Button onClick={handleOpenDoneWidget} variant="contained" color="primary">{t131}</Button>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="objective-manage-form__form">
                    <InputField
                      autoFocus
                      disabled={subObjectiveLoading}
                      error={errorsObjectiveAddEdit && errorsObjectiveAddEdit.name}
                      inputId="nameInput"
                      label={t134}
                      maxLength={100}
                      name="name"
                      onChange={(e) => setNewName(e.target.value)}
                      value={newName}
                    />
                  </div>
                  <Button
                    color="primary"
                    disabled={subObjectiveLoading || !newName}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    {t135}
                  </Button>
                  {errorsObjectiveAddEdit && <Text error>{errorsObjectiveAddEdit.objective}</Text>}
                  {errorsObjectiveAddEdit && <Text error>{errorsObjectiveAddEdit.nonField}</Text>}
                </form>
              </>
            )}
            {(!isDeleteWidgetOpened && !isDoneWidgetOpened && !subObjectiveAdd) && (
              <Button onClick={handleOpenConfirmWidget} color="error" fullWidth>{t130}</Button>
            )}
          </>
        )}
    </DialogCustom>
  );
};

SubObjectiveManageForm.propTypes = {
  className: PropTypes.string,
  subObjectiveId: PropTypes.number,
  subObjectiveAdd: PropTypes.bool,
  name: PropTypes.string,
  openDialogButtonText: PropTypes.string,
  objectiveId: PropTypes.number,
};

SubObjectiveManageForm.defaultProps = {
  className: null,
  subObjectiveId: null,
  subObjectiveAdd: false,
  name: '',
  openDialogButtonText: '',
  objectiveId: null,
};

export default SubObjectiveManageForm;

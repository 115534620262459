import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Link,
  useParams,
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@mui/material';

import { ACCOUNTS_API_URL } from '../apiRequests';
import { routes } from '../routes';
import { newUserUpdateData } from '../actions';
import {
  Background,
  Loader,
  Text,
} from '../components/atoms';
import { Card, InputField, ConfirmButton } from '../components/molecules';
import { useAxiosGetNew, useAxiosPostNew, useTr } from '../hooks';

const Wrapper = styled(Background)`
  position: relative;
  width: 100%;
  padding: 9.5rem 1.5rem 1.5rem;
`;

const NonFieldError = styled(Text)`
  margin: .3rem 0;
  text-align: center;
`;

const RegistrationView = () => {
  const { token } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tosAccepted, setTosAccept] = useState(false);
  const [marketingAccepted, setSpamAccept] = useState(false);

  const t141 = useTr(141);
  const t142 = useTr(142);
  const t232 = useTr(232);
  const t173 = useTr(173);
  const t174 = useTr(174);
  const t176 = useTr(176);
  const t177 = useTr(177);
  const t240 = useTr(240);

  const dispatch = useDispatch();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsSaving(true);
  };

  const {
    responseError: responseErrorEmailToToken,
    isFetching: isFetchingEmailToToken,
  } = useAxiosGetNew({
    url: token ? `${ACCOUNTS_API_URL}/registration/email-for-token?token=${token}` : null,
    body: {},
    skipRequest: !token,
    callbackSuccess: (response) => {
      setEmail(response.data_.data.email);
    },
  });

  const {
    responseData,
    responseError,
    isFetching,
  } = useAxiosPostNew({
    url: `${ACCOUNTS_API_URL}/registration/register`,
    body: {
      email,
      password,
      tos_accepted: tosAccepted,
      marketing_accepted: marketingAccepted,
      token: token || null,
    },
    isSaving,
    setIsSaving,
  });

  useEffect(() => {
    if (responseData) {
      dispatch(newUserUpdateData(responseData.meta?.user));
    }
    if (responseError) {
      dispatch(newUserUpdateData({}));
    }
  }, [responseData, responseError]);

  return (
    <Wrapper>
      <Card cardHeader={t142}>
        <>
          <form onSubmit={handleSubmit}>
            <InputField
              autoCapitalize="off"
              error={responseError?.email && responseError.email[0]}
              inputId="emailInput"
              label={t173}
              disabled={isFetching || isFetchingEmailToToken}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputField
              autoCapitalize="off"
              error={responseError?.password && responseError.password[0]}
              inputType="password"
              inputId="passwordInput"
              isPasswordInput
              label={t174}
              disabled={isFetching || isFetchingEmailToToken}
              value={password}
              onChange={(e) => (setPassword(e.target.value))}
            />
            <Text info className="password-info">{t240}</Text>
            <InputField
              error={responseError?.tos_accepted && responseError.tos_accepted[0]}
              inputType="checkbox"
              inputId="tosAcceptedInput"
              label={t176}
              checked={tosAccepted}
              disabled={isFetching || isFetchingEmailToToken}
              onChange={(e) => setTosAccept(e.target.checked)}
            />
            <InputField
              error={responseError?.marketing_accepted && responseError.marketing_accepted[0]}
              inputType="checkbox"
              inputId="marketingAcceptedInput"
              label={t177}
              checked={marketingAccepted}
              disabled={isFetching || isFetchingEmailToToken}
              onChange={(e) => setSpamAccept(e.target.checked)}
            />
            <Button
              disabled={isFetching || isFetchingEmailToToken}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              {(isFetching || isFetchingEmailToToken) ? <Loader small /> : t142}
            </Button>
            <NonFieldError error>{(responseError?.non_field_errors && responseError.non_field_errors[0]) || (responseError?.token && responseError.token[0]) || (responseErrorEmailToToken?.token && responseErrorEmailToToken.token[0])}</NonFieldError>
          </form>
          <ConfirmButton
            confirmNote={t232}
            linkName={t141}
            tag={Link}
            url={routes.LOGIN_URL}
          />
        </>
      </Card>
    </Wrapper>
  );
};

RegistrationView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RegistrationView.defaultProps = {
  location: null,
};

export default withRouter(RegistrationView);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';

import { newUserUpdateData } from '../../../actions';
import { ACCOUNTS_API_URL } from '../../../apiRequests';
import { routes } from '../../../routes';

import { ButtonIcon, SrOnly, Text } from '../../atoms';
import UserAccount from '../UserAccount/UserAccount';
import logo from '../../../assets/logo-beta.svg';
import { useAxiosGetNew, useTr } from '../../../hooks';

import { ReactComponent as IconUser } from '../../../assets/icons/user-circle-duotone.svg';
import { ReactComponent as IconLogout } from '../../../assets/icons/power-off-duotone.svg';

import './TopAppBar.sass';

const Wrapper = styled.nav`
  ${({ isTransparent }) => (
    isTransparent && css`
      background-color: transparent;
      box-shadow: none;
    }`
  )};
`;

const LogoStyled = styled.div`
  background-image: url(${logo});
`;

const TopAppBar = ({
  isTransparent,
  location,
}) => {
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const dispatch = useDispatch();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [skipRequest, setSkipRequest] = useState(true);

  const t137 = useTr(137);
  const t138 = useTr(138);
  const t139 = useTr(139);
  const t140 = useTr(140);
  const t141 = useTr(141);
  const t142 = useTr(142);
  const t143 = useTr(143);

  const {
    responseError,
    isFetching,
  } = useAxiosGetNew({
    url: `${ACCOUNTS_API_URL}/authentication/logout`,
    skipRequest,
    callbackSuccess: () => {
      dispatch(newUserUpdateData({}));
      setSkipRequest(true);
    },
  });

  const {
    CONFIRM_EMAIL_URL,
    LOGIN_URL,
    REGISTER_URL,
    HOME_URL,
    PASSWORD_RESET_URL,
    NEW_PASSWORD_URL,
  } = routes;

  const {
    email,
    email_confirmed: emailConfirmed,
    is_authenticated: isAuthenticated,
    username,
  } = user;

  const authUrls = [
    CONFIRM_EMAIL_URL,
    LOGIN_URL,
    REGISTER_URL,
    PASSWORD_RESET_URL,
    NEW_PASSWORD_URL,
  ];

  // Get part of the urls without token to match this url with location.pathname
  const getUrlWithoutToken = (url) => {
    const endOfUrlPosition = url.lastIndexOf('/');
    let pathnamePattern = null;

    if (endOfUrlPosition > 0) {
      pathnamePattern = url.substring(0, endOfUrlPosition);
    } else {
      pathnamePattern = url;
    }
    return pathnamePattern;
  };

  // If url is isInAuthLocation dont show the login/register buttons in top app bar
  const isInAuthLocation = authUrls.map((el) => getUrlWithoutToken(el)).includes(getUrlWithoutToken(location.pathname));

  useEffect(() => {
    setAccountMenuOpen(false);
  }, [location.pathname]);

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setAccountMenuOpen(!accountMenuOpen);
  };

  return (
    <Wrapper isAuthenticatedView={isAuthenticated} isTransparent={isTransparent} className="top-app-bar">
      <ul className="top-app-bar__list">
        <li><Link to={HOME_URL}><SrOnly>{t137}</SrOnly><LogoStyled className="top-app-bar__logo" /></Link></li>
        <li className="top-app-bar__user-actions">
          {isAuthenticated ? (
            <>
              <ButtonIcon
                ariaControls="account_menu"
                ariaHasPopup
                ariaLabel={`${t138} ${username || email}. ${t143}${!user.email_confirmed && t139}`}
                badgeVariant="dot"
                badgeColor="secondary"
                onClick={toggleDrawer(true)}
                title={`${t138} ${username || email}${!emailConfirmed ? t139 : ''}`}
                icon={<IconUser />}
                widthBadge={!emailConfirmed}
              />
              <ButtonIcon
                ariaLabel={t140}
                disabled={isFetching}
                onClick={() => setSkipRequest(false)}
                title={t140}
                icon={<IconLogout />}
              />
              {accountMenuOpen && (
                <UserAccount
                  menuOpen={accountMenuOpen}
                  handleMenuClose={toggleDrawer(false)}
                />
              )}
            </>
          ) : (
            !isInAuthLocation && (
              <>
                <Button component={Link} to={LOGIN_URL} color={isTransparent ? 'warning' : 'primary'}>{t141}</Button>
                <Button
                  component={Link}
                  to="rejestracja"
                  variant="contained"
                  color="warning"
                  disableElevation
                >
                  {t142}
                </Button>
              </>
            )
          )}
        </li>
      </ul>
      {responseError && <Text error>{responseError}</Text>}
    </Wrapper>
  );
};

TopAppBar.propTypes = {
  isTransparent: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

TopAppBar.defaultProps = {
  location: null,
  isTransparent: true,
};

export default withRouter(TopAppBar);

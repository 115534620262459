import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import {
  actionsReducer,
  objectiveReducer,
  feedbackReducer,
  habitReducer,
  helpersReducer,
  sharedZoneReducer,
  spheresReducer,
  subObjectiveReducer,
  userReducer,
  zoneReducer,
  newUserReducer,
} from './reducers';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  actionsReducer,
  objectiveReducer,
  feedbackReducer,
  habitReducer,
  helpersReducer,
  sharedZoneReducer,
  spheresReducer,
  subObjectiveReducer,
  userReducer,
  zoneReducer,
  newUserReducer,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
/* eslint-enable */

export default store;

import {
  NEW_USER_UPDATE_DATA,
} from '../actions/actionTypes';

const user = {
  avatar_url: null,
  is_authenticated: false,
  email: null,
  email_confirmed: false,
  id: null,
  marketing_accepted: false,
  language: null,
  username: '',
  is_pro: false,
  is_business: false,
};

const initialState = {
  user,
  userInitialized: false,
};

export const newUserReducer = (state = initialState, action) => {
  const { type, userData } = action;

  switch (type) {
    case NEW_USER_UPDATE_DATA:
      return {
        ...state,
        user: { ...userData },
        userInitialized: true,
      };

    default:
      return state;
  }
};

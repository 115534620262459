/* eslint-disable */
import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

import uDream from '../assets/images/assets/img-udream.svg';
import uPlan from '../assets/images/assets/img-uplan.svg';
import uMaster from '../assets/images/assets/img-umaster.svg';

const FeatureContent = [
    {
        icon: uDream,
        title: 'uDream',
        desc: `Zbuduj śmiałą wizję siebie i zyskaj nieograniczoną motywację do działania.`,
        fadeUp: 'fade-up',
        dataDelay: ''
    }, {
        icon: uPlan,
        title: 'uPlan',
        desc: `Zaplanuj cele, zbuduj wspierające nawyki, dawaj i otrzymuj feedback, mierz postępy.`,
        fadeUp: 'fade-up',
        dataDelay: '100'
    }, {
        icon: uMaster,
        title: 'uMaster',
        desc: `Graj w Zwycięski Dzień, zaliczaj zadania i wyrób w sobie nawyk zwyciężania.`,
        fadeUp: 'fade-up',
        dataDelay: '200'
    }
]

const FancyFeatureEight = () => {
    return (
        <Fragment>
            <div className="row justify-content-center gx-xxl-5">
                {FeatureContent.map((val, i) => (
                    <div key={i} className="col-lg-4 col-sm-6 d-flex mb-30" data-aos={val.fadeUp} data-aos-delay={val.dataDelay}>
                        <div className="block-style-seven">
                            <div className="icon"><img src={val.icon} alt=""/></div>
                            <h5>
                                <Link to="/service-details">{val.title}</Link>
                            </h5>
                            <p>{val.desc}</p>
                        </div>
                        {/* /.block-style-seven */}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FancyFeatureEight
// Translate strings according to used language
// Usage:
// import { useTr } from '../../../hooks';
// Add languages pair in TRANSLATIONS_DICT object
// Use it in component ex. const text = useTr(3), as 3 is a key for this string in TRANSLATIONS_DICT
// As all hooks call it top level of your React function

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TRANSLATIONS_DICT } from '../translations';

export const useTr = (stringKey) => {
  const language = useSelector(({ newUserReducer }) => newUserReducer.user.language);
  const browserLang = window.navigator.language;

  const text = useMemo(() => {
    if (stringKey in TRANSLATIONS_DICT) {
      if ((!language && (browserLang === 'pl-PL' || browserLang === 'pl')) || (language && language === 'pl')) {
        return TRANSLATIONS_DICT[stringKey][0];
      }
      return TRANSLATIONS_DICT[stringKey][1];
    }
    return '---';
  }, [language, browserLang]);

  return text;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import {
  Close,
  ErrorOutline,
} from '@mui/icons-material';

import { ACCOUNTS_API_URL } from '../../../apiRequests';
import { newUserUpdateData } from '../../../actions';
import { routes } from '../../../routes';
import { media } from '../../../theme/GlobalVars';
import { ButtonIcon, Loader, Text } from '../../atoms';
import { Avatar, ListItemLink } from '../../molecules';
import { useAxiosGetNew, useTr } from '../../../hooks';

import { ReactComponent as IconEmail } from '../../../assets/icons/at-duotone.svg';
import { ReactComponent as IconInfo } from '../../../assets/icons/info-circle-duotone.svg';
import { ReactComponent as IconHelp } from '../../../assets/icons/question-circle-duotone.svg';
import { ReactComponent as IconLogout } from '../../../assets/icons/power-off-duotone.svg';
import { ReactComponent as IconSettings } from '../../../assets/icons/user-cog-duotone.svg';

import './UserAccount.sass';
import uMasterBeta from '../../../assets/logo-beta.svg';

const DrawerStyled = styled(Drawer)`
  .MuiPaper-root {
    height: auto;
    width: 60rem;
    max-width: 100vw;
    bottom: auto;
    border-radius: 1rem 0 0 1rem;
  }
`;

const HeaderStyled = styled.header`
  position: relative;
  padding: 4.5rem 2.5rem;
  background: linear-gradient(120deg, ${({ theme }) => theme.colorPrimary} 65%, ${({ theme }) => theme.colorSecondary});
`;

const CloseButtonContainerStyled = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  button {
    color: #fff;
  }
`;

const UserInfoContainerStyled = styled.section`
  display: flex;
  align-items: center;
`;

const UserNameStyled = styled(Text)`
  max-width: calc(80vw - 3rem - 2.5rem);
  // ^ 100vw - Avatar width - padding - margin
  margin-bottom: 0;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${media.s} {
    max-width: 41rem;
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

const TextWarningStyled = styled(Text)`
  padding-left: 0;
`;

const LinkStyled = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;

const ErrorOutlineIconStyled = styled(ErrorOutline)`
  &.MuiSvgIcon-root {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 .5rem -.4rem 0;
  }
`;

const UserEmailStyled = styled(Text)`
  max-width: calc(80vw - 3rem - 2.5rem);
  margin-bottom: 0;

  @media ${media.s} {
    max-width: 41rem;
  }
`;

const AvatarStyled = styled.div`
  margin-right: 2rem;

  @media ${media.s} {
    margin-right: 2.5rem;
  }
`;

const TextLanguageStyled = styled(Text)`
  position: absolute;
  right: 2.2rem;
  text-transform: uppercase;
`;

const ListItemLogoutStyled = styled(ListItemText)`
  text-transform: none;
  text-align: left;
`;

const UserAccount = ({
  handleMenuClose,
  menuOpen,
}) => {
  const user = useSelector(({ newUserReducer }) => newUserReducer.user);
  const dispatch = useDispatch();
  const [skipRequest, setSkipRequest] = useState(true);

  const t140 = useTr(140);
  const t144 = useTr(144);
  const t145 = useTr(145);
  const t146 = useTr(146);
  const t147 = useTr(147);
  const t148 = useTr(148);
  const t149 = useTr(149);
  const t150 = useTr(150);
  const t151 = useTr(151);
  const t152 = useTr(152);

  const {
    responseError,
    isFetching,
  } = useAxiosGetNew({
    url: `${ACCOUNTS_API_URL}/authentication/logout`,
    skipRequest,
    callbackSuccess: () => {
      dispatch(newUserUpdateData({}));
    },
  });

  const {
    avatar_url: avatarUrl,
    email,
    email_confirmed: emailConfirmed,
    language,
    username,
  } = user;

  const {
    ABOUT_URL,
    ACCOUNT_SETTINGS_URL,
    HELP_URL,
  } = routes;

  return (
    <DrawerStyled
      anchor="right"
      id="account_menu"
      open={menuOpen}
      onClose={handleMenuClose}
    >
      <HeaderStyled>
        <CloseButtonContainerStyled>
          <IconButton aria-label="delete" onClick={handleMenuClose}>
            <Close />
          </IconButton>
        </CloseButtonContainerStyled>
        <UserInfoContainerStyled>
          <AvatarStyled>
            <Avatar
              url={avatarUrl}
              username={username || email}
            />
          </AvatarStyled>
          <header>
            <UserNameStyled title={t144} size="l" weight="weightBold" color="colorWhite">{username}</UserNameStyled>
            <UserEmailStyled title="e-mail" size="xs" weight="weightBold" color="colorWhite">{email}</UserEmailStyled>
            {!emailConfirmed && <TextWarningStyled warning><ErrorOutlineIconStyled />{t146}<LinkStyled to={ACCOUNT_SETTINGS_URL}>{t145}</LinkStyled></TextWarningStyled>}
          </header>
        </UserInfoContainerStyled>
        <TextLanguageStyled size="xs" weight="weightBold" color="colorWhite" title={language === 'pl' ? 'Język polski' : 'English language'}>{language}</TextLanguageStyled>
      </HeaderStyled>
      <List>
        <ListItem>
          <Link
            className="user-account__umaster-link"
            to={routes.HOME_URL}
          >
            {t147}
            <img className="user-account__logo-img" src={uMasterBeta} alt="uMaster" />
          </Link>
        </ListItem>
        <Divider />
        <ListItemLink
          to={ACCOUNT_SETTINGS_URL}
          primary={t148}
          icon={<IconSettings color="#000" style={{ fontSize: '2.1rem' }} />}
        />
        <ListItemLink
          to={HELP_URL}
          disabled
          primary={t149}
          icon={<IconHelp color="#000" style={{ fontSize: '2.1rem' }} />}
        />
        <ListItemButton
          className="user-account__logout-btn"
          disabled={isFetching}
          onClick={() => setSkipRequest(false)}
        >
          <>
            <ListItemIcon><IconLogout color="#000" style={{ fontSize: '2.1rem' }} /></ListItemIcon>
            <ListItemLogoutStyled>{isFetching ? <Loader small /> : t140}</ListItemLogoutStyled>
          </>
        </ListItemButton>
        {responseError && <Text error>{responseError}</Text>}
        <Divider />
        <ListItem>
          <div className="user-account__footer">
            <ListItemText secondary={`uMaster, ${moment().format('YYYY')}`} />
            <ListItemText secondary="v1.0.0" />
          </div>
          <ButtonIcon
            ariaLabel={t150}
            icon={<IconInfo color="#000" />}
            title={t151}
            to={ABOUT_URL}
          />
          <ButtonIcon
            ariaLabel={t152}
            href="mailto:piotr.walenko@gmail.com"
            icon={<IconEmail color="#000" />}
            title="piotr.walenko@gmail.com"
          />
        </ListItem>
      </List>
    </DrawerStyled>
  );
};

UserAccount.propTypes = {
  handleMenuClose: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool,
};

UserAccount.defaultProps = {
  menuOpen: false,
};

export default withRouter(UserAccount);

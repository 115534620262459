import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '../../atoms';
import './FeedbackListItem.sass';

import { formatDateTime } from '../../../helpers';

const TextContentStyled = styled.div`
  background: ${({ color1, color2, theme }) => `linear-gradient(160deg, ${color1 || theme.colorPrimary} 30%, ${color2 || theme.colorPrimary})`};
`;

export const FeedbackListItem = ({
  createdAt,
  readAt,
  text,
  userId,
  userName,
}) => {
  const today = formatDateTime();
  const userIdfromState = useSelector(({ newUserReducer }) => newUserReducer.user.id);
  const {
    zone,
    colorGradient,
  } = useSelector(({ zoneReducer }) => zoneReducer);
  const [isNew, setIsNew] = useState(formatDateTime(readAt) === today);

  useEffect(() => {
    const timer = isNew && setTimeout(() => setIsNew(false), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isNew]);

  return (
    <li className={`feedback-item${userId === userIdfromState ? ' feedback-item--my' : ''}`}>
      {userId !== userIdfromState && (
        <div className="feedback-item__name">
          <FontAwesomeIcon icon="user-circle" color={zone.color} />
          <Text size="xs" weight="weightBold" colorCustom={zone.color}>{userName}</Text>
        </div>
      )}
      <TextContentStyled
        color1={userId === userIdfromState ? zone.color : '#e8e8e8'}
        color2={userId === userIdfromState ? colorGradient : '#f4f4f4'}
        className="feedback-item__text"
      >
        <Text size="xs" weight={(userId === userIdfromState || isNew) && 'weightBold'} colorCustom={userId === userIdfromState ? '#ffffff' : '#000000'} className="feedback-item__text-p">{text}</Text>
      </TextContentStyled>
      <Text size="xxs" weight="weightBold" color="colorGreyDark" className="feedback-item__date">{formatDateTime(createdAt)}</Text>
    </li>
  );
};

FeedbackListItem.propTypes = {
  createdAt: PropTypes.string,
  readAt: PropTypes.string,
  text: PropTypes.string,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string,
};

FeedbackListItem.defaultProps = {
  createdAt: '',
  readAt: '',
  text: '',
  userName: '',
};

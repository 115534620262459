import React from 'react';

import ActionsListAndCalendar from '../components/organisms/ActionsListAndCalendar/ActionsListAndCalendar';
import { Heading } from '../components/atoms';
import { useTr } from '../hooks';

const ActionsView = () => {
  const t171 = useTr(171);

  return (
    <>
      <Heading>{t171}</Heading>
      <ActionsListAndCalendar />
    </>
  );
};

export default ActionsView;

import {
  axiosPatch,
  axiosPost,
  ACCOUNTS_API_URL,
} from '../apiRequests';

import {
  USER_CLEAR_ERRORS,
  USER_AUTHENTICATION_REQUEST,
  USER_UPDATE_DATA,
  USER_CONFIRM_EMAIL_SUCCESS,
  USER_CONFIRM_EMAIL_FAILURE,
  USER_CHANGE_PROFILE_SUCCESS,
  USER_CHANGE_PROFILE_FAILURE,
  USER_CHANGE_LANGUAGE_SUCCESS,
  USER_CHANGE_LANGUAGE_FAILURE,
} from './actionTypes';

// UPDATE USER DATA
const userUpdateData = (response) => (dispatch) => {
  if (response && response.data.meta && response.data.meta.user) {
    dispatch({
      type: USER_UPDATE_DATA,
      userData: response.data.meta.user,
    });
  }
};

// CONFIRM EMAIL
export const userConfirmEmailAction = (token) => (dispatch) => {
  dispatch({ type: USER_AUTHENTICATION_REQUEST });

  return axiosPost(
    `${ACCOUNTS_API_URL}/registration/confirm-email/${token}`,
  ).then((response) => {
    userUpdateData(response)(dispatch);
    dispatch({
      type: USER_CONFIRM_EMAIL_SUCCESS,
      userData: {
        emailConfirmed: response.data.data.email_confirmed,
      },
    });
  }).catch((error) => {
    const { response } = error;
    userUpdateData(response)(dispatch);
    if (response) {
      if (response.status === 404) {
        dispatch({
          type: USER_CONFIRM_EMAIL_FAILURE,
          errors: { nonField: response.data.data.detail },
        });
      } else {
        dispatch({
          type: USER_CONFIRM_EMAIL_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// CHANGE PROFILE DATA
export const userChangeProfileAction = (
  username = null,
  avatarUrl = null,
  marketingAccepted = null,
  callbackSuccess = null,
) => (dispatch) => {
  dispatch({ type: USER_AUTHENTICATION_REQUEST });

  return axiosPatch(
    `${ACCOUNTS_API_URL}/user`,
    {
      username,
      avatar_url: avatarUrl,
      marketing_accepted: marketingAccepted,
    },
  ).then((response) => {
    userUpdateData(response)(dispatch);
    window.sessionStorage.setItem('userAuthenticated', JSON.stringify(response.data));

    dispatch({
      type: USER_CHANGE_PROFILE_SUCCESS,
      userData: response.data.meta.user,
    });

    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    userUpdateData(response)(dispatch);
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: USER_CHANGE_PROFILE_FAILURE,
          errors: {
            username: response.data.data.username ? response.data.data.username[0] : null,
            avatarUrl: response.data.data.avatar_url ? response.data.data.avatar_url[0] : null,
            marketingAccepted: response.data.data.marketing_accepted ? response.data.data.marketing_accepted[0] : null,
          },
        });
      } else if (response.status === 403) {
        dispatch({
          type: USER_CHANGE_PROFILE_FAILURE,
          errors: { nonField: response.data.data.detail },
        });
      } else {
        dispatch({
          type: USER_CHANGE_PROFILE_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie ${response.status}.` },
        });
      }
    }
  });
};

// CHANGE LANGUAGE
export const userChangeLanguageAction = (language, callbackSuccess = null) => (dispatch) => {
  dispatch({ type: USER_AUTHENTICATION_REQUEST });
  dispatch({ type: USER_CLEAR_ERRORS });

  return axiosPost(
    `${ACCOUNTS_API_URL}/set-language`,
    { language },
  ).then((response) => {
    userUpdateData(response)(dispatch);
    window.sessionStorage.setItem('userAuthenticated', JSON.stringify(response.data));

    dispatch({
      type: USER_CHANGE_LANGUAGE_SUCCESS,
      userData: {
        language: response.data.data.language,
      },
    });
    if (callbackSuccess !== null) {
      callbackSuccess();
    }
  }).catch((error) => {
    const { response } = error;
    userUpdateData(response)(dispatch);
    if (response) {
      if (response.status === 400) {
        dispatch({
          type: USER_CHANGE_LANGUAGE_FAILURE,
          errors: { nonField: response.data.data.detail },
        });
      } else {
        dispatch({
          type: USER_CHANGE_LANGUAGE_FAILURE,
          errors: { nonField: `Ups… Nieoczekiwany błąd o kodzie: ${response.status}.` },
        });
      }
    }
  });
};

// CLEAR FORMS ERRORS
export const clearUserErrorsAction = () => (dispatch) => {
  dispatch({ type: USER_CLEAR_ERRORS });
};

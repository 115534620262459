import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { config, useSpring, animated } from 'react-spring';

import { Heading, Loader, Text } from '../../../atoms';
import SphereManageForm from '../SphereManageForm/SphereManageForm';
import SphereMini from '../SphereMini/SphereMini';
import { Pagination } from '../../../molecules';
import { useTr } from '../../../../hooks';
import './SpheresList.sass';

import { getSpheresListAction } from '../../../../actions';

const SpheresList = ({ activeSpheresList }) => {
  const [isListLoaded, setIsListLoaded] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSpheresListAction(activeSpheresList, () => setIsListLoaded(true)));
  }, [dispatch]);

  const {
    newGetSpheresListError,
    newSpheresListLoading,
    newSpheresListCount,
    newListNext,
    newListPrev,
    newListResults,
  } = useSelector(({ spheresReducer }) => spheresReducer);

  const t127 = useTr(127);
  const t128 = useTr(128);
  const t129 = useTr(129);

  const handleClickNext = (e) => {
    e.preventDefault();
    if (newListNext) {
      dispatch(getSpheresListAction(null, null, newListNext));
    }
  };

  const handleClickPrev = (e) => {
    e.preventDefault();
    if (newListPrev) {
      dispatch(getSpheresListAction(null, null, newListPrev));
    }
  };

  // animations
  const slideUpStyles = useSpring({
    config: { ...config.gentle },
  });

  return (
    <>
      <Heading className="sheres-list__heading">
        <span>{t127} {!activeSpheresList && 'nieaktywne'} {!!newSpheresListCount && `(${newSpheresListCount || 0})`}</span>
        <div>
          {(!newSpheresListLoading && !!newSpheresListCount && newSpheresListCount === 0) && <Text info className="spheres-list__info">{t128}</Text>}
          {activeSpheresList && newSpheresListCount === 3 && <Text info className="spheres-list__info">{t129}</Text>}
        </div>
      </Heading>
      {newGetSpheresListError && <Text error>{newGetSpheresListError}</Text>}
      <animated.ul style={slideUpStyles} className="spheres-mini-list">
        <>
          {newListResults && newListResults.map((sphere) => (
            <SphereMini key={sphere.id} noSphereName {...sphere} />
          ))}
          {isListLoaded && activeSpheresList && newSpheresListCount < 3 && (
            <li><SphereManageForm customButton spheresList={newListResults} /></li>
          )}
          {newSpheresListLoading && <li className="sheres-list__loader"><Loader /></li>}
        </>
      </animated.ul>
      {(newListNext || newListPrev) && (
        <Pagination
          isNext={newListNext !== null}
          isPrev={newListPrev !== null}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
        />
      )}
    </>
  );
};

SpheresList.propTypes = {
  activeSpheresList: PropTypes.bool,
};

SpheresList.defaultProps = {
  activeSpheresList: true,
};

export default SpheresList;

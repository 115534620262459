import {
  SET_INITIAL_GRID_ACTION,
  SET_MENU_ITEMS,
} from './actionTypes';

// SET INITIAL GRID
export const setInitialGridAction = (initialGrid) => (dispatch) => {
  dispatch({ type: SET_INITIAL_GRID_ACTION, initialGrid });
};

// SET MENU ITEMS
export const setMenuItemsAction = (menuItems) => (dispatch) => {
  dispatch({ type: SET_MENU_ITEMS, menuItems });
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import IconPrev from '@mui/icons-material/ArrowBackIosRounded';
import IconNext from '@mui/icons-material/ArrowForwardIosRounded';

import { ButtonIcon } from '../../atoms';
import { ActionsProgress } from '../../molecules';
import { formatDate, globalDateFormat } from '../../../helpers';
import {
  actionWeeklyDoneAction,
  setActionsDateAction,
} from '../../../actions';
import { useTr } from '../../../hooks';

import './WeeklyActions.sass';

const ListItemStyled = styled.li`
  background-color: ${({ isShown, today, theme }) => isShown ? theme.colorPrimary : (today && !isShown) ? theme.colorPrimaryBg : 'transparent'};
`;

const WeeklyActionsActiveDayStyled = styled.span`
  border: ${({ isShown, theme }) => isShown && `2px solid ${theme.colorPrimary}`};
  color: ${({ today, isShown, theme }) => today ? theme.colorPrimary : (isShown && !today) ? '#fff' : '#000'};
  background-color: ${({ today, theme }) => today && theme.colorPrimaryBg};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ isShown, today, theme }) => (today || isShown) && theme.weightBold};
`;

const WeeklyActions = ({
  actionsDate,
  actionsList,
  displayedWeek,
  displayedYear,
  today,
}) => {
  const dispatch = useDispatch();
  const actionsWeeklyDoneList = useSelector(({ actionsReducer }) => actionsReducer.actionsWeeklyDoneList);
  const t161 = useTr(161);
  const t162 = useTr(162);

  const [daysList, setDaysList] = useState({});
  const [emptyWeek, setEmptyWeek] = useState(false);

  const getWeekDaysByWeekNumber = (year, weeknumber) => {
    let result = {};
    let weeklength = 7;

    if (year && weeknumber) {
      let date = moment().year(year).isoWeek(weeknumber).startOf('week');
      while (weeklength--) {
        result[date.format(globalDateFormat)] = null;
        date.add(1, 'day');
      }
    }
    return result;
  };

  useEffect(() => {
    if (actionsWeeklyDoneList) {
      if (Object.keys(actionsWeeklyDoneList.week_passed_days).length === 0) {
        setDaysList({ ...getWeekDaysByWeekNumber(actionsWeeklyDoneList.year, actionsWeeklyDoneList.week) });
        setEmptyWeek(true);
      } else {
        setDaysList({ ...actionsWeeklyDoneList.week_passed_days });
        setEmptyWeek(false);
      }
    }
  }, [actionsWeeklyDoneList]);

  useEffect(() => {
    dispatch(actionWeeklyDoneAction(displayedYear, displayedWeek));
  }, [
    actionsList,
    dispatch,
    displayedWeek,
    displayedYear,
  ]);

  return (
    <div className="weekly-actions">
      <ButtonIcon
        className="weekly-actions__nav"
        icon={<IconPrev />}
        onClick={() => dispatch(setActionsDateAction(moment(actionsDate).subtract(1, 'week').format(globalDateFormat)))}
        title={t161}
      />
      <ul className="weekly-actions__list">
        {daysList && Object.keys(daysList).map((day, i) => (
          <ListItemStyled
            key={i}
            className="weekly-actions__list-item"
            today={today === day}
            isShown={actionsDate === day}
          >
            <button
              className="weekly-actions__day"
              type="button"
              onClick={() => dispatch(setActionsDateAction(formatDate(day)))}
            >
              <ActionsProgress
                doneCount={actionsWeeklyDoneList.week_passed_days[day] ? 5 : 0}
                noActionsAdded={(today < day) || (actionsWeeklyDoneList.week_passed_days[day] === null) || emptyWeek}
                onCalendar
              />
              <WeeklyActionsActiveDayStyled
                className="weekly-actions__day-name"
                today={today === day}
                isShown={actionsDate === day}
              >
                {moment(day).format('dd')}
              </WeeklyActionsActiveDayStyled>
            </button>
          </ListItemStyled>
        ))}
      </ul>
      <ButtonIcon
        className="weekly-actions__nav"
        icon={<IconNext />}
        onClick={() => dispatch(setActionsDateAction(moment(actionsDate).add(1, 'week').format(globalDateFormat)))}
        title={t162}
      />
    </div>
  );
};

WeeklyActions.propTypes = {
  actionsDate: PropTypes.string,
  actionsList: PropTypes.arrayOf(PropTypes.shape),
  displayedWeek: PropTypes.string,
  displayedYear: PropTypes.string,
  today: PropTypes.string,
};

WeeklyActions.defaultProps = {
  actionsDate: null,
  actionsList: [],
  displayedWeek: null,
  displayedYear: null,
  today: null,
};

export default WeeklyActions;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Heading, Progress, Text } from '../../../atoms';
import { SphereImage } from '../../../molecules';
import SphereManageForm from '../SphereManageForm/SphereManageForm';
import ZoneWidget from '../../ZoneWidget/ZoneWidget';
import ZoneManageForm from '../../ZoneManageForm/ZoneManageForm';
import { matchZoneColorGradient, sharedZonesListData } from '../../../../helpers';
import SphereBigSvg from './SphereBigSvg';
import { useTr } from '../../../../hooks';
import './SphereBig.sass';

const SphereBig = () => {
  const { id: urlId } = useParams();
  const sharedZonesListMy = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.sharedZonesListMy);
  const {
    newListResults,
    newGetSpheresListError,
    newSpheresListLoading,
  } = useSelector(({ spheresReducer }) => spheresReducer);
  const history = useHistory();

  const t106 = useTr(106);
  const t107 = useTr(107);
  const t108 = useTr(108);
  const t109 = useTr(109);
  const t110 = useTr(110);
  const t111 = useTr(111);
  const t112 = useTr(112);

  const [colorsList, setColorsList] = useState([]);
  const [zoneProgressList, setZoneProgressList] = useState([]);
  const [sphereBigData, setSphereBigData] = useState(null);
  const [isMySphere, setIsMySphere] = useState(true);

  const phoneWidth = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    const spheresData = newListResults && newListResults.length > 0 ? newListResults.find((sp) => sp.id === parseFloat(urlId)) : {};
    setSphereBigData(spheresData);
  }, [newListResults, urlId]);

  useEffect(() => {
    if (sphereBigData && sphereBigData.zones) {
      const list = [];
      sphereBigData.zones?.map((zone) => list.push(zone.color));

      setColorsList([...list]);

      const progList = [];
      sphereBigData.zones?.map((zone) => progList.push(zone.progress));

      setZoneProgressList([...progList]);
    }
  }, [sphereBigData, sphereBigData?.zones]);

  useEffect(() => {
    const idsList = [];
    if (newListResults) {
      newListResults.map((res) => idsList.push(res.id));
      setIsMySphere(idsList.includes(parseFloat(urlId)));
    }
  }, [newListResults, urlId]);

  const MAX_ZONES_COUNT = 5;

  const redirectToZone = (zoneNo) => {
    if (sphereBigData.zones.length >= zoneNo + 1) {
      history.push(`/sfera/${sphereBigData?.id}/obszar/${sphereBigData.zones[zoneNo].id}/wizja`);
    }
  };

  return (
    <div className={(!newSpheresListLoading && sphereBigData) ? 'sphere-big-wrapper' : ''}>
      {newGetSpheresListError && <Text error>{t106}</Text>}
      {!newSpheresListLoading && !isMySphere && <Text error>{t107}</Text>}
      {sphereBigData && newListResults.length > 0 && (
        <>
          <Heading className="sphere-big__heading">
            <span className="sphere-big__name">{sphereBigData.name}</span>
            <div className="sphere-big__actions">
              {sphereBigData?.is_main && <Text info className="sphere-big__main"><FontAwesomeIcon icon="crown" /> {t108}</Text>}
              {sphereBigData.is_active && (
                <SphereManageForm
                  openDialogButtonText={t109}
                  formTitle={t110}
                  id={sphereBigData.id}
                  name={sphereBigData.name}
                  isMain={sphereBigData.is_main}
                  photoUrl={sphereBigData.photo_url}
                  submitBtnText={t111}
                  btnVariant="outlined"
                  disabled={newSpheresListLoading}
                  spheresList={newListResults}
                />
              )}
            </div>
          </Heading>
          <div className="sphere-big-container">
            <div className="sphere-big">
              <SphereBigSvg
                zone1color1={colorsList[0]}
                zone1color2={matchZoneColorGradient(colorsList[0])}
                onClickZone1={() => redirectToZone(0)}
                zone2color1={colorsList[1]}
                zone2color2={matchZoneColorGradient(colorsList[1])}
                onClickZone2={() => redirectToZone(1)}
                zone3color1={colorsList[2]}
                zone3color2={matchZoneColorGradient(colorsList[2])}
                onClickZone3={() => redirectToZone(2)}
                zone4color1={colorsList[3]}
                zone4color2={matchZoneColorGradient(colorsList[3])}
                onClickZone4={() => redirectToZone(3)}
                zone5color1={colorsList[4]}
                zone5color2={matchZoneColorGradient(colorsList[4])}
                onClickZone5={() => redirectToZone(4)}
                className="sphere-big__sphere-svg"
              />
              {sphereBigData.zones && [...Array(MAX_ZONES_COUNT - sphereBigData.zones.length)].map((count, idx) => (
                <ZoneManageForm key={idx} position={sphereBigData.zones.length + (idx || 0) + 1} />
              ))}
              {zoneProgressList.map((prog, idx) => (
                <div key={idx}>
                  <Progress
                    className={`sphere-big__progress sphere-big__progress--${idx + 1}`}
                    size={phoneWidth ? '4rem' : '5rem'}
                    title={t112}
                    progressValue={prog * 100}
                  />
                  <p className={`sphere-big__sphere-no sphere-big__sphere-no--${idx + 1}`}>{idx + 1}</p>
                </div>
              ))}
              <SphereImage
                className="sphere-big__img-container"
                url={sphereBigData.photo_url}
                sphereName={sphereBigData.name}
              />
            </div>
            <div className="sphere-big-buttons">
              {sphereBigData.zones?.map((zone, idx) => (
                <ZoneWidget
                  key={zone.id}
                  sphereId={sphereBigData?.id}
                  zoneId={zone.id}
                  name={zone.name}
                  color={zone.color}
                  sharedZoneMy={sharedZonesListData(sharedZonesListMy, zone.id).length > 0}
                  zoneNo={idx + 1}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SphereBig;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pl';

import {
  BorderedPart,
  Heading,
  Progress,
  Text,
} from '../../atoms';
import ObjectiveManageForm from '../../organisms/ObjectiveManageForm/ObjectiveManageForm';
import SubObjectiveManageForm from '../../organisms/SubObjectiveManageForm/SubObjectiveManageForm';
import SubObjectivesList from '../../organisms/SubObjectivesList/SubObjectivesList';
import SuccessSvg from '../../../assets/SuccessSvg';
import ObjectiveSvg from './ObjectiveSvg';
import { useTr } from '../../../hooks';
import './ObjectivesListItem.sass';

export const ObjectivesListItem = ({
  objectiveId,
  name,
  done,
  endDate,
  zoneId,
}) => {
  const { subObjectivesCount, subObjectivesDoneCount } = useSelector(({ subObjectiveReducer }) => subObjectiveReducer);
  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const zoneMode = useSelector(({ zoneReducer }) => zoneReducer.zoneMode);

  const [progress, setProgress] = useState(null);
  const t25 = useTr(25);
  const t26 = useTr(26);
  const t27 = useTr(27);
  const t28 = useTr(28);
  const t29 = useTr(29);
  const t30 = useTr(30);
  const t31 = useTr(31);

  useEffect(() => {
    if (subObjectivesCount && subObjectivesDoneCount && (subObjectivesCount[objectiveId] > 0 || subObjectivesDoneCount[objectiveId] > 0)) {
      const percent = subObjectivesDoneCount[objectiveId] / (subObjectivesDoneCount[objectiveId] + subObjectivesCount[objectiveId]);
      setProgress(percent);
    }
  }, [subObjectivesDoneCount, subObjectivesCount, objectiveId]);

  return (
    <BorderedPart className="objective-container">
      {done && (
        <div className="objective-list-item__done">
          <Text size="xxs" weight="weightBold" success className="objective-list-item__done-text">{t25}</Text>
          <SuccessSvg className="objective-list-item__done-svg" zoneColor={zoneColor} />
        </div>
      )}
      {!done && zoneMode === 'RW' && (
        <ObjectiveManageForm
          formTitle={t26}
          name={name}
          openDialogButtonText={t26}
          objectiveId={objectiveId}
          endDate={endDate}
          zoneId={zoneId}
          className="objective-list-item__add-objective-btn"
        />
      )}
      <div className="objective-list-item">
        <div className="objective-list-item__main-obj">
          <div className="objective-list-item__name">
            <ObjectiveSvg zoneColor={zoneColor} className="objective-list-item__svg" />
            <Text size="s" weight="weightBold">{name}</Text>
          </div>
          {!done && (
            <div className="objective-list-item__meta">
              {subObjectivesCount && subObjectivesCount[objectiveId] > 0 && (
                <Progress
                  color={zoneColor}
                  className="objective-list-item__progress"
                  size="5rem"
                  title={t29}
                  progressValue={progress ? (progress * 100) : 0}
                />
              )}
              <div className="objective-list-item__dates">
                <Text warning>{moment(endDate) < moment() ? t27 : t28}: {moment(endDate).fromNow()}</Text>
                <Text info>({endDate})</Text>
              </div>
            </div>
          )}
        </div>
        <div className={`objective-list-item__subobjectives${(done || zoneMode === 'RO') ? ' objective-list-item__subobjectives--done' : ''}${subObjectivesCount && subObjectivesCount[objectiveId] === 0 ? ' objective-list-item__subobjectives--empty' : ''}`}>
          <div className={`objective-list-item__subobjectives-heading${subObjectivesCount && subObjectivesCount[objectiveId] > 0 ? '' : ' objective-list-item__subobjectives-heading--empty'}`}>
            {subObjectivesCount && subObjectivesCount[objectiveId] > 0 && <Heading className="objective-list-item__sub-heading">{t30}</Heading>}
            {!done && zoneMode === 'RW' && (
              <SubObjectiveManageForm
                openDialogButtonText={t31}
                objectiveId={objectiveId}
                subObjectiveAdd
              />
            )}
          </div>
          <SubObjectivesList objectiveDone={done} objectiveId={objectiveId} />
        </div>
      </div>
    </BorderedPart>
  );
};

ObjectivesListItem.propTypes = {
  objectiveId: PropTypes.number,
  name: PropTypes.string,
  endDate: PropTypes.string,
  done: PropTypes.bool,
  zoneId: PropTypes.string,
};

ObjectivesListItem.defaultProps = {
  objectiveId: null,
  name: '',
  endDate: '-',
  done: false,
  zoneId: '',
};

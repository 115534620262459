import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { formatDate } from '../../../helpers';
import { ActionAddForm } from '../ActionAddForm/ActionAddForm';
import { ButtonIcon } from '../../atoms';
import ConfirmWidget from '../../organisms/ConfirmWidget/ConfirmWidget';

import {
  actionDelDoneAction,
} from '../../../actions';
import { useTr } from '../../../hooks';
import successIcon from '../../../assets/congrats-small.svg';
import './ActionWidget.sass';

export const ActionWidget = ({
  id,
  actionDone,
  actionName,
  isToday,
}) => {
  const dispatch = useDispatch();
  const { actionsDate } = useSelector(({ actionsReducer }) => actionsReducer);

  const [checked, setChecked] = useState(actionDone);
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const t8 = useTr(8);
  const t9 = useTr(9);
  const t10 = useTr(10);
  const t11 = useTr(11);

  const today = formatDate();

  const handleActionDone = () => {
    dispatch(actionDelDoneAction(id, today, true));
    setIsConfirmationActive(false);
  };

  const handleChange = () => {
    setChecked(true);
    setIsConfirmationActive(true);
  };

  const handleCancel = () => {
    setChecked(false);
    setIsConfirmationActive(false);
  };

  const handleDeleteClick = () => {
    dispatch(actionDelDoneAction(id, actionsDate));
  };

  const handleFormActive = () => {
    setIsFormOpened(!isFormOpened);
  };

  const checkbox = (
    <Checkbox
      id={`action_checkbox_${id}`}
      checked={checked}
      disabled={!isToday || actionDone}
      onChange={handleChange}
      className="action-widget__checkbox"
    />
  );

  return (
    isFormOpened ? (
      <ActionAddForm
        actionId={id}
        actionText={actionName}
        callbackSuccess={() => setIsFormOpened(false)}
        handleCancelForm={handleFormActive}
      />
    ) : (
      <>
        <li className={`action-widget${isConfirmationActive ? ' action-widget--confirm' : ''}${actionDone ? ' action-widget--done' : ''}`}>
          <div className="action-widget__action">
            <FormControlLabel control={checkbox} label={actionName} />
          </div>
          {actionDone
            ? <img src={successIcon} alt="Gratulacje" className="action-widget__success" />
            : (actionsDate >= today)
              ? (
                <div className="action-widget__flex">
                  <ButtonIcon
                    ariaLabel={t8}
                    title={t8}
                    icon={<DeleteForeverIcon />}
                    onClick={handleDeleteClick}
                    color="#5e5e5e"
                  />
                  <ButtonIcon
                    ariaLabel={t9}
                    title={t9}
                    icon={<EditIcon />}
                    onClick={handleFormActive}
                    color="#930DD4"
                  />
                </div>
              ) : null}
        </li>
        {isConfirmationActive && (
          <ConfirmWidget
            confirmationBtnText={t11}
            className="action-widget__confirm"
            onCancel={handleCancel}
            onConfirm={handleActionDone}
            confirmationText={t10}
            positive
          />
        )}
      </>
    )
  );
};

ActionWidget.propTypes = {
  id: PropTypes.number,
  actionDone: PropTypes.bool.isRequired,
  actionName: PropTypes.string,
  isToday: PropTypes.bool,
};

ActionWidget.defaultProps = {
  id: null,
  actionName: '',
  isToday: true,
};

import React from 'react';
import PropTypes from 'prop-types';

const SphereMiniSvg = ({
  borderColor,
  color1,
  color2,
  color3,
  color4,
  color5,
  ...props
}) => (
  <svg width="200px" height="200px" viewBox="0 0 200 200" {...props}>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval" stroke={borderColor} strokeWidth="3" cx="100" cy="100" r="96" />
      <path d="M95.2370459,38.7286487 C108.612745,47.6767416 124.197661,52.5827671 141.991792,53.446725 C159.899476,54.3161963 176.62061,49.4454818 192.155194,38.8345814 C193.523334,37.9000572 193.874864,36.0333822 192.940351,34.665235 C192.904269,34.6124108 192.866507,34.5607543 192.827123,34.5103445 L187.456505,27.6362673 C186.521261,26.4391915 184.838828,26.1301336 183.539194,26.9166608 C171.976892,33.9140665 158.486823,37.0877791 143.068987,36.4377987 C127.792278,35.7937679 114.786267,32.1831903 104.050955,25.6060661 C102.692911,24.7740606 100.921417,25.1482448 100.01586,26.4583905 L94.4372744,34.529398 C93.4952015,35.8923637 93.8364091,37.7609654 95.1993781,38.7030335 C95.211869,38.7116671 95.2244253,38.7202057 95.2370459,38.7286487 Z" id="id1" fill={color1} transform="translate(143.750494, 38.808202) rotate(-144.000000) translate(-143.750494, -38.808202) " />
      <path d="M7.23704587,39.7286487 C20.6127454,48.6767416 36.1976607,53.5827671 53.9917918,54.446725 C71.8994759,55.3161963 88.6206101,50.4454818 104.155194,39.8345814 C105.523334,38.9000572 105.874864,37.0333822 104.940351,35.665235 C104.904269,35.6124108 104.866507,35.5607543 104.827123,35.5103445 L99.4565051,28.6362673 C98.5212605,27.4391915 96.8388279,27.1301336 95.5391941,27.9166608 C83.976892,34.9140665 70.486823,38.0877791 55.0689873,37.4377987 C39.7922778,36.7937679 26.7862671,33.1831903 16.0509551,26.6060661 C14.6929113,25.7740606 12.9214169,26.1482448 12.0158596,27.4583905 L6.43727443,35.529398 C5.49520149,36.8923637 5.83640909,38.7609654 7.19937809,39.7030335 C7.21186901,39.7116671 7.2244253,39.7202057 7.23704587,39.7286487 Z" id="id5" fill={color5} transform="translate(55.750494, 39.808202) rotate(-218.000000) translate(-55.750494, -39.808202) " />
      <path d="M-18.7629541,123.728649 C-5.38725457,132.676742 10.1976607,137.582767 27.9917918,138.446725 C45.8994759,139.316196 62.6206101,134.445482 78.1551944,123.834581 C79.5233341,122.900057 79.8748639,121.033382 78.9403507,119.665235 C78.9042692,119.612411 78.8665073,119.560754 78.8271229,119.510345 L73.4565051,112.636267 C72.5212605,111.439192 70.8388279,111.130134 69.5391941,111.916661 C57.976892,118.914066 44.486823,122.087779 29.0689873,121.437799 C13.7922778,120.793768 0.786267067,117.18319 -9.94904491,110.606066 C-11.3070887,109.774061 -13.0785831,110.148245 -13.9841404,111.458391 L-19.5627256,119.529398 C-20.5047985,120.892364 -20.1635909,122.760965 -18.8006219,123.703034 C-18.788131,123.711667 -18.7755747,123.720206 -18.7629541,123.728649 Z" id="id4" fill={color4} transform="translate(29.750494, 123.808202) rotate(-289.000000) translate(-29.750494, -123.808202) " />
      <path d="M52.2370459,174.728649 C65.6127454,183.676742 81.1976607,188.582767 98.9917918,189.446725 C116.899476,190.316196 133.62061,185.445482 149.155194,174.834581 C150.523334,173.900057 150.874864,172.033382 149.940351,170.665235 C149.904269,170.612411 149.866507,170.560754 149.827123,170.510345 L144.456505,163.636267 C143.521261,162.439192 141.838828,162.130134 140.539194,162.916661 C128.976892,169.914066 115.486823,173.087779 100.068987,172.437799 C84.7922778,171.793768 71.7862671,168.18319 61.0509551,161.606066 C59.6929113,160.774061 57.9214169,161.148245 57.0158596,162.458391 L51.4372744,170.529398 C50.4952015,171.892364 50.8364091,173.760965 52.1993781,174.703034 C52.211869,174.711667 52.2244253,174.720206 52.2370459,174.728649 Z" id="id3" fill={color3} transform="translate(100.750494, 174.808202) rotate(-361.000000) translate(-100.750494, -174.808202) " />
      <path d="M123.237046,122.728649 C136.612745,131.676742 152.197661,136.582767 169.991792,137.446725 C187.899476,138.316196 204.62061,133.445482 220.155194,122.834581 C221.523334,121.900057 221.874864,120.033382 220.940351,118.665235 C220.904269,118.612411 220.866507,118.560754 220.827123,118.510345 L215.456505,111.636267 C214.521261,110.439192 212.838828,110.130134 211.539194,110.916661 C199.976892,117.914066 186.486823,121.087779 171.068987,120.437799 C155.792278,119.793768 142.786267,116.18319 132.050955,109.606066 C130.692911,108.774061 128.921417,109.148245 128.01586,110.458391 L122.437274,118.529398 C121.495201,119.892364 121.836409,121.760965 123.199378,122.703034 C123.211869,122.711667 123.224425,122.720206 123.237046,122.728649 Z" id="id2" fill={color2} transform="translate(171.750494, 122.808202) rotate(-433.000000) translate(-171.750494, -122.808202) " />
    </g>
  </svg>
);

SphereMiniSvg.propTypes = {
  borderColor: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  color4: PropTypes.string,
  color5: PropTypes.string,
};

SphereMiniSvg.defaultProps = {
  borderColor: '#f4f4f4',
  color1: '#f4f4f4',
  color2: '#f4f4f4',
  color3: '#f4f4f4',
  color4: '#f4f4f4',
  color5: '#f4f4f4',
};

export default SphereMiniSvg;

/* eslint-disable */

import React, {Fragment} from 'react';
import icon21 from '../assets/images/icon/icon_21.svg';
import icon22 from '../assets/images/icon/icon_22.svg';

const ProcessContent = [
    {
        icon: icon21,
        num: '1',
        text: 'Zapisz 5 zadań',
        text2: 'na ten dzień',
        fade: 'fade-right',
        dataDelay: '',
        className: '',
    }, {
        icon: icon22,
        num: '2',
        text: 'Zaznaczaj zrealizowane',
        text2: 'zadania',
        fade: 'fade-up',
        dataDelay: '100',
        className: 'shape-arrow'
    }, {
        icon: icon21,
        num: '3',
        text: 'Zalicz jak najwięcej',
        text2: 'dni z rzędu',
        fade: 'fade-left',
        dataDelay: '200',
        className: '',
    }
]

const FancyFeatureEleven = () => {
    return (
        <Fragment>
            <div className="row justify-content-center gx-xxl-5">
                {ProcessContent.map((val, i) => (
                    <div key={i} className="col-md-4 col-sm-6" data-aos={val.fade} data-aos-delay={val.dataDelay}>
                        <div className={`block-style-eight position-relative mt-50 ${val.className}`}>
                            <div className="icon d-flex align-items-center justify-content-center">
                                <img src={val.icon} alt=""/>
                                <div className="num">{val.num}</div>
                            </div>
                            <h5>{val.text}
                                <br/>
                                {val.text2}</h5>
                        </div>
                        {/* /.block-style-eight */}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FancyFeatureEleven
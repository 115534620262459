import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SpherePlaceholder from '../../../assets/spher-placeholder.svg';
import './SphereImage.sass';

const SpherePhotoImgStyled = styled.img`
  width: 100%;
  height: auto;
  max-width: ${({ emptyImg }) => emptyImg ? '75%' : '100%'};
  max-height: ${({ emptyImg }) => emptyImg ? '75%' : 'none'};
`;

export const SphereImage = ({
  className,
  sphereName,
  url,
}) => (
  <div className={`sphere-image__img-container ${className}`}>
    <SpherePhotoImgStyled emptyImg={!url} src={url || SpherePlaceholder} alt={`Zdjęcie sfery ${sphereName}`} />
  </div>
);

SphereImage.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  sphereName: PropTypes.string,
};

SphereImage.defaultProps = {
  className: '',
  url: '',
  sphereName: '',
};

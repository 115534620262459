import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTr } from '../../../hooks';
import './Loader.sass';

export const Loader = ({ small, view }) => {
  const t3 = useTr(3);

  const loaderClass = clsx([
    'loader',
    view && 'loader--fixed',
    small && 'loader--small',
  ]);

  return (
    <div className={loaderClass} role="status">
      <span className="sr-only">{t3}</span>
      <FontAwesomeIcon icon="spinner" spin size="2x" />
    </div>
  );
};

Loader.propTypes = {
  small: PropTypes.bool,
  view: PropTypes.bool,
};

Loader.defaultProps = {
  small: false,
  view: false,
};

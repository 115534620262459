// Add or edit Sphere form
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import { Loader, Text } from '../../../atoms';
import { DialogCustom, InputField } from '../../../molecules';
import ConfirmWidget from '../../ConfirmWidget/ConfirmWidget';
import HabitSvgPl from '../HabitSvgPl';
import HabitSvgEn from '../HabitSvgEn';
import SuccessSvg from '../../../../assets/SuccessSvg';
import { useTr } from '../../../../hooks';

import './HabitManageForm.sass';

import {
  clearHabitErrorsAction,
  habitAddEditAction,
  habitGetDelAction,
  habitDevelopAction,
} from '../../../../actions';

const HabitManageForm = ({
  className,
  habitAdd,
  habitId,
  name,
  openDialogButtonText,
  prize,
  release,
  zoneId,
}) => {
  const dispatch = useDispatch();

  const zoneColor = useSelector(({ zoneReducer }) => zoneReducer.zone.color);
  const language = useSelector(({ newUserReducer }) => newUserReducer.user.language);

  const {
    errorsHabitAddEdit,
    errorsHabitDevelop,
    errorsHabitGetDel,
    habitLoading,
  } = useSelector(({ habitReducer }) => habitReducer);

  const [newHabitId, setNewHabitId] = useState(habitId);
  const [newName, setNewName] = useState(name);
  const [newRelease, setNewRelease] = useState(release);
  const [newPrize, setNewPrize] = useState(prize);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteWidgetOpened, setIsDeleteWidgetOpened] = useState(false);
  const [isDevelopWidgetOpened, setIsDevelopWidgetOpened] = useState(false);

  const t11 = useTr(11);
  const t67 = useTr(67);
  const t68 = useTr(68);
  const t69 = useTr(69);
  const t70 = useTr(70);
  const t71 = useTr(71);
  const t72 = useTr(72);
  const t73 = useTr(73);
  const t74 = useTr(74);
  const t75 = useTr(75);
  const t231 = useTr(231);
  const t235 = useTr(235);

  const handleClose = () => {
    setIsDialogOpen(false);
    setNewHabitId(habitId);
    setNewName(name);
    setNewRelease(release);
    setNewPrize(prize);
    setIsDeleteWidgetOpened(false);
    setIsDevelopWidgetOpened(false);
    dispatch(clearHabitErrorsAction());
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearHabitErrorsAction());
    dispatch(habitAddEditAction(
      zoneId,
      newHabitId || null,
      newName,
      newRelease,
      newPrize,
      handleClose,
    ));
  };

  const handleOpenConfirmWidget = () => setIsDeleteWidgetOpened(true);

  const handleOpenDevelopWidget = () => setIsDevelopWidgetOpened(true);

  const handleHabitAction = (e) => {
    e.preventDefault();
    if (isDeleteWidgetOpened) {
      dispatch(habitGetDelAction(newHabitId, zoneId, handleClose, true));
    }

    if (isDevelopWidgetOpened) {
      dispatch(habitDevelopAction(newHabitId, zoneId, handleClose));
    }
  };

  return (
    <DialogCustom
      onClose={handleClose}
      onOpen={handleOpen}
      openDialogButton={habitAdd}
      openDialogButtonIcon={!habitAdd}
      openIcon="cogs"
      openDialogButtonText={openDialogButtonText || t235}
      dialogTitle={isDeleteWidgetOpened ? t67 : isDevelopWidgetOpened ? t68 : habitAdd ? t69 : t70}
      open={isDialogOpen}
      openBtnClassName={className}
      disabled={habitLoading}
      btnIconClassName="habit-manage-form__btn-icon"
    >
      {habitLoading
        ? <Loader view /> : (
          <>
            {(isDeleteWidgetOpened || isDevelopWidgetOpened) ? (
              <>
                <ConfirmWidget
                  additionalContent={!isDeleteWidgetOpened && <SuccessSvg className="habit-manage-form__success-svg" zoneColor={zoneColor} />}
                  confirmationBtnText={isDeleteWidgetOpened ? t67 : t11}
                  onConfirm={handleHabitAction}
                  onCancel={() => isDeleteWidgetOpened ? setIsDeleteWidgetOpened(false) : setIsDevelopWidgetOpened(false)}
                  confirmationText={isDeleteWidgetOpened ? t71 : t72}
                  positive={!isDeleteWidgetOpened}
                />
                {errorsHabitDevelop && <Text error>{errorsHabitDevelop}</Text>}
                {errorsHabitGetDel && <Text error>{errorsHabitGetDel}</Text>}
              </>
            ) : (
              <>
                {(!isDeleteWidgetOpened && !isDevelopWidgetOpened && !habitAdd) && (
                  <Button onClick={handleOpenDevelopWidget} color="primary" variant="contained">{t68}</Button>
                )}
                <div className="habit-manage-form__form">
                  {language === 'en' ? <HabitSvgEn className="habit-manage-form__svg" zoneColor={zoneColor} /> : <HabitSvgPl className="habit-manage-form__svg" zoneColor={zoneColor} />}
                  <form onSubmit={handleSubmit}>
                    <InputField
                      autoFocus
                      disabled={habitLoading}
                      error={errorsHabitAddEdit && errorsHabitAddEdit.release}
                      inputId="releaseInput"
                      label={t73}
                      maxLength={100}
                      name="release"
                      onChange={(e) => setNewRelease(e.target.value)}
                      value={newRelease}
                    />
                    <InputField
                      disabled={habitLoading}
                      error={errorsHabitAddEdit && errorsHabitAddEdit.name}
                      inputId="nameInput"
                      label={t74}
                      maxLength={100}
                      name="name"
                      onChange={(e) => setNewName(e.target.value)}
                      value={newName}
                    />
                    <InputField
                      disabled={habitLoading}
                      error={errorsHabitAddEdit && errorsHabitAddEdit.prize}
                      inputId="prizeInput"
                      label={t75}
                      maxLength={100}
                      name="prize"
                      onChange={(e) => setNewPrize(e.target.value)}
                      value={newPrize}
                    />
                    <Button
                      color="primary"
                      disabled={habitLoading || !newName}
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      {t231}
                    </Button>
                    {errorsHabitAddEdit && <Text error>{errorsHabitAddEdit.zone}</Text>}
                    {errorsHabitAddEdit && <Text error>{errorsHabitAddEdit.nonField}</Text>}
                  </form>
                </div>
              </>
            )}
            {(!isDeleteWidgetOpened && !isDevelopWidgetOpened && !habitAdd) && (
              <Button onClick={handleOpenConfirmWidget} color="error" fullWidth>{t67}</Button>
            )}
          </>
        )}
    </DialogCustom>
  );
};

HabitManageForm.propTypes = {
  className: PropTypes.string,
  habitId: PropTypes.number,
  habitAdd: PropTypes.bool,
  name: PropTypes.string,
  openDialogButtonText: PropTypes.string,
  prize: PropTypes.string,
  release: PropTypes.string,
  zoneId: PropTypes.string,
};

HabitManageForm.defaultProps = {
  className: null,
  habitId: null,
  habitAdd: false,
  name: '',
  openDialogButtonText: '',
  prize: '',
  release: '',
  zoneId: '',
};

export default HabitManageForm;

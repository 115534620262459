import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../../routes';
import PrivateRoute from './PrivateRoute';
import NotFoundView from '../../views/NotFoundView';
import FeedbackView from '../../views/FeedbackView';
import HabitsView from '../../views/HabitsView/HabitsView';
import ObjectivesView from '../../views/ObjectivesView/ObjectivesView';
import VisionView from '../../views/VisionView';

const ZoneRoutes = () => {
  const { url } = useRouteMatch();
  const { zoneId } = useParams();
  const {
    FEEDBACK_URL,
    HABITS_URL,
    OBJECTIVES_URL,
    VISION_URL,
  } = routes;

  const zoneName = useSelector(({ zoneReducer }) => zoneReducer.zone.name);

  return (
    <Switch>
      <PrivateRoute path={`${url}${VISION_URL}`}>
        <VisionView zoneId={zoneId} zoneName={zoneName} />
      </PrivateRoute>
      <PrivateRoute path={`${url}${HABITS_URL}`}>
        <HabitsView zoneId={zoneId} />
      </PrivateRoute>
      <PrivateRoute path={`${url}${OBJECTIVES_URL}`}>
        <ObjectivesView zoneId={zoneId} zoneName={zoneName} />
      </PrivateRoute>
      <PrivateRoute path={`${url}${FEEDBACK_URL}`}>
        <FeedbackView zoneId={zoneId} zoneName={zoneName} />
      </PrivateRoute>
      <Route path={`${url}/*`}>
        <NotFoundView />
      </Route>
    </Switch>
  );
};

export default ZoneRoutes;

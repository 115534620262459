/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colorBg};
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';

import { theme } from '../../src/theme/mainTheme';
import GlobalStyle from '../../src/theme/GlobalStyle';
import { Loader, Text } from '../components/atoms';
import Sidebar from '../components/organisms/Sidebar/Sidebar';
import TopAppBar from '../components/organisms/TopAppBar/TopAppBar';
import {
  actionsListGetAction,
  sharedZonesListGetAction,
  newUserUpdateData,
} from '../actions';
import { globalDateFormat } from '../helpers';
import { useAxiosGetNew } from '../hooks/useAxiosNew';
import { ACCOUNTS_API_URL } from '../apiRequests';
import LandingPageView from '../views/LandingPageView/LandingPageView';

import './MainTemplate.sass';

const MainTemplate = ({ children }) => {
  const dispatch = useDispatch();
  const initialGrid = useSelector(({ helpersReducer }) => helpersReducer.initialGrid);

  const userInitialized = useSelector(({ newUserReducer }) => newUserReducer);
  const userAuthenticated = useSelector(({ newUserReducer }) => newUserReducer.user.is_authenticated);

  const [isNavTransparent, setIsNavTransparent] = useState(true);
  const today = moment().format(globalDateFormat);

  // Helpers
  const makeNavOpaque = (scrollPositionY) => {
    setIsNavTransparent(!(scrollPositionY > 80));
  };

  const {
    responseData,
    responseError,
    isFetching,
  } = useAxiosGetNew({
    url: `${ACCOUNTS_API_URL}/user`,
  });

  useEffect(() => {
    if (responseData) {
      dispatch(newUserUpdateData(responseData.meta?.user));
    }

    if (responseError) {
      dispatch(newUserUpdateData({}));
    }
  }, [responseData, responseError]);

  useEffect(() => {
    if (userAuthenticated) {
      dispatch(actionsListGetAction(today));
      dispatch(sharedZonesListGetAction());
      dispatch(sharedZonesListGetAction(true));
    }
  }, [userAuthenticated]);

  useEffect(() => {
    let lastScrollPosition;

    window.addEventListener('scroll', () => {
      lastScrollPosition = window.scrollY;
      makeNavOpaque(lastScrollPosition);
    });
    makeNavOpaque(lastScrollPosition);

    return window.removeEventListener('scroll', makeNavOpaque());
  }, [userAuthenticated]);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <div className={(userAuthenticated && !initialGrid) ? 'main-grid' : 'initial-grid'}>
          {userInitialized && <TopAppBar backendConnectionError={responseError || null} isTransparent={isNavTransparent} />}
          <main className="main">
            {typeof responseError === 'string' && <div className="banckend-global-error"><Text error>{responseError}</Text></div>}
            {!isFetching && userInitialized ? children : responseError ? <LandingPageView /> : <Loader view />}
          </main>
          {(userAuthenticated && !initialGrid) && <Sidebar sidebarType="app" />}
        </div>
      </ThemeProvider>
    </>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainTemplate;

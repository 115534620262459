import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { green, grey } from '@mui/material/colors';

// Icons
import Icon0 from '@mui/icons-material/CancelTwoTone';
import Icon1 from '@mui/icons-material/ThumbUpAltTwoTone';
import Icon2 from '@mui/icons-material/VerifiedUserTwoTone';
import Icon3 from '@mui/icons-material/SentimentSatisfiedAltTwoTone';
import Icon4 from '@mui/icons-material/FavoriteTwoTone';
import Icon5 from '../../../assets/congrats-small.svg';

import { Progress } from '../../atoms';
import './ActionsProgress.sass';

export const ActionsProgress = ({
  doneCount,
  noActionsAdded,
  onCalendar,
}) => {
  const [step, setStep] = useState(0);
  const MAX_ACTIONS_COUNT = 5;
  const iconsList = [
    <Icon0 fontSize={onCalendar ? 'small' : 'large'} style={{ color: onCalendar ? grey[500] : '#fff', opacity: 0.7 }} />,
    <Icon1 fontSize={onCalendar ? 'small' : 'large'} style={{ color: '#fff' }} />,
    <Icon2 fontSize={onCalendar ? 'small' : 'large'} style={{ color: '#fff' }} />,
    <Icon3 fontSize={onCalendar ? 'small' : 'large'} style={{ color: '#fff' }} />,
    <Icon4 fontSize={onCalendar ? 'small' : 'large'} style={{ color: '#fff' }} />,
    <img src={Icon5} className="actions-progress__congrats" alt="" />,
  ];

  useEffect(() => {
    setStep((doneCount / MAX_ACTIONS_COUNT) * 100);
  }, [doneCount]);

  return (
    <div className="actions-progress">
      <div className="actions-progress__icon">
        {!noActionsAdded && iconsList[doneCount]}
      </div>
      <Progress
        color={(step === 100) ? green[500] : '#fff'}
        className={onCalendar ? 'actions-progress__on-calendar' : ''}
        progressValue={step}
        size={onCalendar ? '3.5rem' : '10rem'}
      />
    </div>
  );
};

ActionsProgress.propTypes = {
  doneCount: PropTypes.number,
  noActionsAdded: PropTypes.bool,
  onCalendar: PropTypes.bool,
};

ActionsProgress.defaultProps = {
  doneCount: 0,
  noActionsAdded: false,
  onCalendar: false,
};

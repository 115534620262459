import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from '@mui/material';

import { formatDateTime } from '../../../helpers';

import {
  BorderedPart,
  Text,
} from '../../atoms';
import { DialogCustom } from '../DialogCustom/DialogCustom';
import SphereMini from '../../organisms/Spheres/SphereMini/SphereMini';
import ConfirmWidget from '../../organisms/ConfirmWidget/ConfirmWidget';
import { clearSharedZoneErrorsAction, sharedZoneGetDelAcceptAction } from '../../../actions';
import { useTr } from '../../../hooks';

import './SharedZonesListItem.sass';

export const SharedZonesListItem = ({
  expireAt,
  isAccepted,
  isLinkActive,
  sharedZoneId,
  sharedZonesToMe,
  userEmail,
  userName,
  unreadFeedbackCount,
  ...sphereMiniProps
}) => {
  const dispatch = useDispatch();

  const [deleteWidgetOpened, setDeleteWidgetOpened] = useState(false);
  const t34 = useTr(34);
  const t35 = useTr(35);
  const t36 = useTr(36);
  const t37 = useTr(37);
  const t38 = useTr(38);
  const t39 = useTr(39);
  const t40 = useTr(40);
  const t41 = useTr(41);
  const t42 = useTr(42);
  const t43 = useTr(43);
  const t44 = useTr(44);
  const t11 = useTr(11);
  const t239 = useTr(239);

  const ACTIONS = {
    del: 'del',
    accept: 'accept',
  };

  const errorsSharedZoneGetDelAccept = useSelector(({ sharedZoneReducer }) => sharedZoneReducer.errorsSharedZoneGetDelAccept);

  const delCallbackSuccess = () => {
    setDeleteWidgetOpened(false);
  };

  const handleClickAcceptDeleteShareBtn = (e) => {
    e.preventDefault();
    dispatch(clearSharedZoneErrorsAction());
    if (sharedZonesToMe) {
      dispatch(sharedZoneGetDelAcceptAction(sharedZoneId, ACTIONS.accept));
    } else {
      setDeleteWidgetOpened(true);
    }
  };

  const handleDeleteShareBtn = (e) => {
    e.preventDefault();
    dispatch(sharedZoneGetDelAcceptAction(sharedZoneId, ACTIONS.del, delCallbackSuccess));
  };

  const handleClose = () => {
    setDeleteWidgetOpened(false);
    dispatch(clearSharedZoneErrorsAction());
  };

  return (
    <BorderedPart className="shared-item">
      <SphereMini is_active={isAccepted || isLinkActive} {...sphereMiniProps} />
      {unreadFeedbackCount > 0
        && <Badge title={t239} badgeContent={unreadFeedbackCount} color="secondary" className="shared-item__feedback"><FontAwesomeIcon color="#ccc" icon="thumbs-up" /></Badge>}
      <div className="shared-item__share-info">
        <div className="shared-item__user-info">
          <Text size="xxs" weight="weightBold">{sharedZonesToMe ? t34 : t35}</Text>
          <Text size="s" weight="weightBold" className="shared-item__email">{userEmail}</Text>
          {userName && <Text size="s">{userName}</Text>}
        </div>
        <div className="shared-item__accept-container">
          {isAccepted ? <Text success><FontAwesomeIcon icon="check-circle" />{t36}</Text> : (
            (sharedZonesToMe && !isAccepted && expireAt) && <Button onClick={handleClickAcceptDeleteShareBtn} variant="contained" color="primary">{t37}</Button>
          )}
          {!sharedZonesToMe && (
            <>
              {!isAccepted && <Text error><FontAwesomeIcon icon="times-circle" /> {t38}</Text>}
              <Button variant="outlined" onClick={handleClickAcceptDeleteShareBtn}>{!expireAt && !isAccepted ? t39 : t40}</Button>
            </>
          )}
          {!isAccepted && (
            <>
              <Text warning>{expireAt ? `${t41} ${moment(expireAt).fromNow()}` : t42}</Text>
              {expireAt && <Text info>({formatDateTime(expireAt)})</Text>}
            </>
          )}
        </div>
        <DialogCustom
          open={deleteWidgetOpened}
          dialogTitle={t43}
          onClose={handleClose}
          openBtnClassName="shared-item__confirm-dialog"
        >
          <>
            <ConfirmWidget
              confirmationBtnText={t11}
              onConfirm={handleDeleteShareBtn}
              confirmationText={t44}
            />
            {errorsSharedZoneGetDelAccept && <Text error>{errorsSharedZoneGetDelAccept}</Text>}
          </>
        </DialogCustom>
      </div>
    </BorderedPart>
  );
};

SharedZonesListItem.propTypes = {
  expireAt: PropTypes.string,
  isAccepted: PropTypes.bool,
  isLinkActive: PropTypes.bool,
  onClickAcceptDeleteShareBtn: PropTypes.func,
  sharedZoneId: PropTypes.number.isRequired,
  sharedZonesToMe: PropTypes.bool.isRequired,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
  unreadFeedbackCount: PropTypes.number,
};

SharedZonesListItem.defaultProps = {
  expireAt: '',
  isAccepted: false,
  isLinkActive: false,
  onClickAcceptDeleteShareBtn: null,
  userEmail: '',
  userName: '',
  unreadFeedbackCount: 0,
};

// Add or edit Sphere form
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

import { useTr } from '../../../../hooks';

import { routes } from '../../../../routes';
import {
  BorderedPart,
  Heading,
  Text,
} from '../../../atoms';
import {
  ConfirmEmailInfo,
  DialogCustom,
  InputField,
  SphereImage,
  UploadImageWidget,
} from '../../../molecules';
import ConfirmWidget from '../../ConfirmWidget/ConfirmWidget';
import AddSphereIcon from '../../../../assets/icons/sphere-mini-add.svg';
import crownImg from '../../../../assets/icons/crown-sphere.svg';
import './SphereManageForm.sass';

import {
  clearSphereErrorsAction,
  editAddSphereAction,
  getSpheresListAction,
  delSphereAction,
} from '../../../../actions';

const SphereManageForm = ({
  customButton,
  disabled,
  formTitle,
  id,
  name,
  photoUrl,
  isMain,
  openDialogButtonText,
  spheresList,
  submitBtnText,
  ...props
}) => {
  const {
    newSphereDataId,
    newMainSphereId,
    newRedirectToHome,
    newEditAddSphereNameError,
    newEditAddSpherePhotoUrlError,
    newEditAddSphereMainError,
    newEditAddSphereNonFieldError,
    newEditAddSphereLoading,
    newDeleteSphereNonFieldError,
  } = useSelector(({ spheresReducer }) => spheresReducer);
  const userEmailConfirmed = useSelector(({ newUserReducer }) => newUserReducer.user.email_confirmed);

  const dispatch = useDispatch();
  const history = useHistory();

  const t108 = useTr(108);
  const t113 = useTr(113);
  const t114 = useTr(114);
  const t115 = useTr(115);
  const t116 = useTr(116);
  const t117 = useTr(117);
  const t118 = useTr(118);
  const t119 = useTr(119);
  const t120 = useTr(120);
  const t121 = useTr(121);
  const t122 = useTr(122);
  const t123 = useTr(123);
  const t124 = useTr(124);
  const t237 = useTr(237);

  const [newName, setName] = useState('');
  const [newPhotoUrl, setPhotoUrl] = useState('');
  const [newIsMain, setIsMain] = useState(false);
  const [isMainCheckboxDisabled, setIsMainCheckboxDisabled] = useState(false);
  const [mainSphereName, setMainSphereName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmWidgetOpened, setisConfirmWidgetOpened] = useState(false);

  useEffect(() => setName(name), [name]);
  useEffect(() => setPhotoUrl(photoUrl), [photoUrl]);
  useEffect(() => setIsMain(isMain), [isMain]);

  useEffect(() => {
    if (spheresList && spheresList?.length > 0) {
      setMainSphereName(spheresList?.find((sp) => sp.is_main === true).name);
    }

    setIsMainCheckboxDisabled(isMain);
  }, [spheresList, spheresList?.length, isMain]);

  useEffect(() => {
    // Redirect to new sphere after its added
    if (newSphereDataId) {
      history.push(`/sfera/${newSphereDataId}`);
    }
  }, [newSphereDataId]);

  useEffect(() => {
    // Redirect to HOME_URL after deleting last sphere
    if (newRedirectToHome) {
      history.push(routes.HOME_URL);
    }
  }, [newRedirectToHome]);

  const handleClose = () => {
    dispatch(clearSphereErrorsAction());
    setIsDialogOpen(false);
  };

  const handleOpen = () => setIsDialogOpen(true);

  const submitCallbackSuccess = () => {
    handleClose();
    dispatch(getSpheresListAction(true));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editAddSphereAction(newName, newPhotoUrl, newIsMain, id, submitCallbackSuccess));
  };

  const deleteCallbackSuccess = () => {
    setisConfirmWidgetOpened(false);
    dispatch(getSpheresListAction(true));
    handleClose();

    if (!newEditAddSphereLoading) {
      history.push(newMainSphereId ? `/sfera/${newMainSphereId}` : routes.HOME_URL);
    }
  };

  const handleDeleteSphere = () => {
    dispatch(delSphereAction(id, deleteCallbackSuccess, () => setisConfirmWidgetOpened(false)));
  };

  const crownImgClassName = clsx([
    'sphere-manage-form__crown-img',
    !newIsMain && 'sphere-manage-form__crown-img--inactive',
  ]);

  return (
    <>
      {customButton && (
        <button onClick={handleOpen} type="button" className="sphere-manage-form__btn-add">
          <Text size="xxs" weight="weightBold" className="sphere-manage-form__btn-text">{t113}</Text>
          <img src={AddSphereIcon} aria-hidden="true" alt="" className="sphere-manage-form__btn-add-img" />
        </button>
      )}
      <DialogCustom
        onClose={handleClose}
        onOpen={handleOpen}
        openDialogButton={!customButton}
        openDialogButtonText={!customButton ? (openDialogButtonText || t237) : null}
        dialogTitle={isConfirmWidgetOpened ? t114 : formTitle || t237}
        open={isDialogOpen}
        disabled={disabled}
        {...props}
      >
        <>
          {!userEmailConfirmed ? (
            <ConfirmEmailInfo />
          ) : isConfirmWidgetOpened ? (
            <ConfirmWidget
              confirmationBtnText={t114}
              onConfirm={handleDeleteSphere}
              onCancel={() => setisConfirmWidgetOpened(false)}
              confirmationText={t115}
              confirmationInfo={t116}
            />
          ) : (
            <form onSubmit={handleSubmit}>
              <BorderedPart>
                <Heading>{t117}</Heading>
                <SphereImage
                  className="sphere-manage-form__img-container"
                  url={newPhotoUrl}
                  sphereName={newName}
                />
                <UploadImageWidget
                  imgTitle={t117}
                  imageUploadText={photoUrl ? t118 : t119}
                  imgChangeText={t118}
                  onGetImageUrl={(url) => setPhotoUrl(url)}
                />
                {newEditAddSpherePhotoUrlError && <Text error>{newEditAddSpherePhotoUrlError}</Text>}
              </BorderedPart>
              <BorderedPart>
                <Heading>{t120}</Heading>
                <InputField
                  autoFocus
                  disabled={newEditAddSphereLoading}
                  error={newEditAddSphereNameError}
                  inputId="nameInput"
                  label={t120}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={newName}
                  maxLength={200}
                />
              </BorderedPart>
              <BorderedPart>
                <Heading>{t108}</Heading>
                <div className="flex-container">
                  <img src={crownImg} alt="" className={crownImgClassName} />
                  <div>
                    {!newIsMain && mainSphereName && <Text success>{t121} &quot;{mainSphereName}&quot;. {t122}</Text>}
                    {id && isMain && <Text success>{t123}</Text>}
                    {spheresList.length !== 0 && (
                      <InputField
                        disabled={isMainCheckboxDisabled}
                        checked={newIsMain}
                        inputId="isMainInput"
                        inputType="checkbox"
                        label={t108}
                        name="isMain"
                        onChange={(e) => setIsMain(e.target.checked)}
                      />
                    )}
                    {newEditAddSphereMainError && <Text error>{newEditAddSphereMainError}</Text>}
                  </div>
                </div>
              </BorderedPart>
              <Button
                color="primary"
                disabled={newName.length === 0 || newEditAddSphereLoading || newEditAddSphereLoading}
                fullWidth
                type="submit"
                variant="contained"
              >
                {newEditAddSphereLoading ? <FontAwesomeIcon icon="spinner" spin /> : submitBtnText || t113}
              </Button>
              {newEditAddSphereNonFieldError && <Text error>{newEditAddSphereNonFieldError}</Text>}
              {newDeleteSphereNonFieldError && <Text error>{newDeleteSphereNonFieldError}</Text>}
            </form>
          )}
          {spheresList.length > 1 && isMain && <Text warning>{t124}</Text>}
          {(id && !isConfirmWidgetOpened) && <Button disabled={newEditAddSphereLoading || newEditAddSphereLoading || (spheresList.length > 1 && isMain)} onClick={() => setisConfirmWidgetOpened(true)} color="error" fullWidth>{t114}</Button>}
        </>
      </DialogCustom>
    </>
  );
};

SphereManageForm.propTypes = {
  customButton: PropTypes.bool,
  disabled: PropTypes.bool,
  formTitle: PropTypes.string,
  id: PropTypes.number,
  isMain: PropTypes.bool,
  name: PropTypes.string,
  openDialogButtonText: PropTypes.string,
  spheresList: PropTypes.arrayOf(PropTypes.shape),
  photoUrl: PropTypes.string,
  submitBtnText: PropTypes.string,
};

SphereManageForm.defaultProps = {
  customButton: false,
  disabled: false,
  formTitle: '',
  id: null,
  isMain: false,
  name: '',
  openDialogButtonText: '',
  spheresList: null,
  photoUrl: '',
  submitBtnText: '',
};

export default SphereManageForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from '../../atoms';
import { ZONE_COLOR_DICT } from '../../../helpers';
import { useTr } from '../../../hooks';
import './ColorPickerWidget.sass';

const ColoredLabelStyled = styled.span`
  background: ${({ colorMain, colorGradient }) => `linear-gradient(150deg, ${colorMain} 50%, ${colorGradient})`};
  width: ${({ isChecked }) => isChecked ? '6rem' : '3rem'};
  height: ${({ isChecked }) => isChecked ? '6rem' : '3rem'};
  cursor: pointer;
`;

export const ColorPickerWidget = ({
  disabled,
  error,
  value,
  onColorChange,
}) => {
  const [newValue, setValue] = useState(value);
  const t12 = useTr(12);
  const t13 = useTr(13);

  const handleRadioChange = (ev) => {
    setValue(ev.target.value);
    onColorChange(ev.target.value);
  };

  return (
    <fieldset className="color-picker-widget">
      <legend className="sr-only">{t12}</legend>
      {ZONE_COLOR_DICT.map((colors) => (
        <label
          key={`id_${colors.main}`}
          htmlFor={`id_${colors.main}`}
          className="color-picker-widget__label"
        >
          <input
            className="sr-only"
            checked={newValue === colors.main}
            disabled={disabled}
            id={`id_${colors.main}`}
            name="zoneColor"
            type="radio"
            value={colors.main}
            onChange={handleRadioChange}
          />
          <ColoredLabelStyled
            colorMain={colors.main}
            colorGradient={colors.gradient}
            className="color-picker-widget__color"
            isChecked={newValue === colors.main}
          >
            {newValue === colors.main && <FontAwesomeIcon icon="check" />}
          </ColoredLabelStyled>
          <span className="sr-only">{t13} {colors.main}</span>
        </label>
      ))}
      {error && <Text error>{error}</Text>}
    </fieldset>
  );
};

ColorPickerWidget.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onColorChange: PropTypes.func,
  value: PropTypes.string,
};

ColorPickerWidget.defaultProps = {
  disabled: false,
  error: null,
  onColorChange: null,
  value: null,
};

/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

import './BorderedPart.sass';

export const BorderedPart = ({
  children,
  className,
  ...props
}) => (
  <Paper
    className={`bordered-part ${className}`}
    variant="outlined"
    {...props}
  >
    {children}
  </Paper>
);

BorderedPart.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.string,
};

BorderedPart.defaultProps = {
  children: null,
  className: '',
};

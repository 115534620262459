import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

import {
  ButtonIcon,
  Input,
  Text,
} from '../../atoms';
import { ReactComponent as IconShow } from '../../../assets/icons/eye-duotone.svg';
import { ReactComponent as IconHide } from '../../../assets/icons/eye-slash-duotone.svg';
import { useTr } from '../../../hooks';
import './InputField.sass';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  padding-left: 1rem;
  margin-bottom: .8rem;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme }) => theme.colorGreyDark};
  text-transform: uppercase;
`;

const CheckboxLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: ${({ theme }) => theme.fontSize.xxs};
    font-weight: ${({ theme }) => theme.weightBold};
    color: ${({ theme }) => theme.colorGreyDark};
  }
`;

const ButtonIconStyled = styled(ButtonIcon)`
  position: absolute !important;
  top: -.3rem;
  right: 0;
`;

const ChartCounterStyled = styled.span`
  display: block;
  padding: .5rem 1rem 1rem 0;
  text-align: right;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-style: italic;
  color: ${({ theme }) => theme.colorGreyDark};
`;

export const InputField = ({
  autoCapitalize,
  checked,
  className,
  disabled,
  error,
  inputId,
  inputType,
  label,
  maxLength,
  name,
  isPasswordInput,
  value,
  onChange,
  textarea,
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const t23 = useTr(23);
  const t24 = useTr(24);

  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown);
  };

  const inputDynamicType = passwordShown ? 'text' : 'password';

  return (
    <Wrapper className={className}>
      {inputType !== 'checkbox' && <Label htmlFor={inputId}>{label}</Label>}
      <InputWrapper>
        {inputType === 'checkbox' ? (
          <CheckboxLabel
            control={(
              <Checkbox
                checked={checked}
                disabled={disabled}
                id={inputId}
                name={name}
                onChange={onChange}
                type="checkbox"
                value={value}
                {...props}
              />
            )}
            label={label}
          />
        ) : (
          textarea ? (
            <TextField
              autoCapitalize={autoCapitalize}
              disabled={disabled}
              id={inputId}
              name={name}
              multiline
              onChange={onChange}
              type="text"
              value={value}
              variant="outlined"
              fullWidth
              maxLength={maxLength}
              className="input-field__textarea"
              {...props}
            />
          ) : (
            <Input
              autoCapitalize={autoCapitalize}
              disabled={disabled}
              id={inputId}
              name={name}
              onChange={onChange}
              type={isPasswordInput ? inputDynamicType : inputType}
              value={value}
              maxLength={maxLength}
              {...props}
            />
          )
        )}
        {isPasswordInput && (
          <ButtonIconStyled
            ariaLabel={passwordShown ? t23 : t24}
            onClick={togglePasswordShown}
            icon={passwordShown ? <IconHide /> : <IconShow />}
            title={passwordShown ? t23 : t24}
          />
        )}
        {maxLength && <ChartCounterStyled className="input__charts">{value.length || 0}/{maxLength}</ChartCounterStyled>}
      </InputWrapper>
      {(error && error.length > 0) && <Text error>{error}</Text>}
    </Wrapper>
  );
};

InputField.propTypes = {
  autoCapitalize: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  inputType: PropTypes.string,
  isPasswordInput: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  textarea: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  autoCapitalize: 'on',
  className: null,
  checked: false,
  disabled: false,
  error: '',
  inputType: 'text',
  isPasswordInput: false,
  label: '',
  maxLength: null,
  name: '',
  value: '',
  textarea: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

import { useAxios, useTr } from '../../../hooks';
import { ACCOUNTS_API_URL, axiosPost } from '../../../apiRequests';
import { Text } from '../../atoms';
import { ReactComponent as IconWarning } from '../../../assets/icons/exclamation-triangle-duotone.svg';
import './ConfirmEmailInfo.sass';

export const ConfirmEmailInfo = ({ disabled }) => {
  const userEmail = useSelector(({ newUserReducer }) => newUserReducer.user.email);

  const t223 = useTr(223);
  const t224 = useTr(224);
  const t225 = useTr(225);
  const t226 = useTr(226);

  const {
    axiosFetch: axiosFetchResendEmail,
    isLoading: isLoadingResendEmail,
    responseError: responseErrorResendEmail,
    successInfo: successInfoResendEmail,
  } = useAxios({
    method: axiosPost,
    url: `${ACCOUNTS_API_URL}/registration/confirm-email/resend`,
    data: {},
  });

  const handleResendEmailClick = () => {
    axiosFetchResendEmail();
  };

  return (
    <section className="confirm-emila-info__activate-account-info">
      <div className="confirm-emila-info__text-container">
        <IconWarning className="confirm-emila-info__icon-warning" />
        <div>
          <Text size="xs">{t223} ({userEmail}).</Text>
          <Text size="xs">{t224}</Text>
        </div>
      </div>
      <Button
        color="secondary"
        disabled={disabled || isLoadingResendEmail}
        fullWidth
        onClick={handleResendEmailClick}
        variant="outlined"
      >
        {t225}
      </Button>
      {successInfoResendEmail && <Text success>{t226}</Text>}
      {responseErrorResendEmail && <Text error>{responseErrorResendEmail.detail}</Text>}
    </section>
  );
};

ConfirmEmailInfo.propTypes = {
  disabled: PropTypes.bool,
};

ConfirmEmailInfo.defaultProps = {
  disabled: false,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormData from 'form-data';

import {
  ImageUpload,
  Loader,
  Text,
} from '../../atoms';
import { PICTURES_UPLOAD_API_URL, axiosPost } from '../../../apiRequests';
import { useTr } from '../../../hooks';
import './UploadImageWidget.sass';

export const UploadImageWidget = ({
  addNextPhotoText,
  className,
  imgTitle,
  onGetImageUrl,
}) => {
  const [imageError, setImageError] = useState(null);
  const [imageTitleError, setImageTitleError] = useState(null);
  const [progress, setProgress] = useState('getUpload');
  const t47 = useTr(47);
  const t48 = useTr(48); // Zmień obrazek

  const handleImageUpload = (picture) => {
    setProgress('uploading');
    const formData = new FormData();

    formData.append('image_file', picture[0]);
    formData.append('title', imgTitle);

    axiosPost(
      PICTURES_UPLOAD_API_URL,
      formData,
    ).then((response) => {
      setProgress('uploaded');
      onGetImageUrl(response.data.data.url, response.data.data.title);
    }).catch((error) => {
      setProgress('uploadError');

      const { response } = error;
      if (response) {
        if (response.status === 400) {
          setImageError(response.data.data.image_file ? response.data.data.image_file[0] : null);
          setImageTitleError(response.data.data.title ? response.data.data.title[0] : null);
        } else if (response.status === 403) {
          setImageError(response.data.data.detail ? response.data.data.detail[0] : null);
        } else if (response.status === 413) {
          setImageError(t47);
        } else {
          setImageError(response.status);
        }
      } else {
        setImageError(t47);
      }
    });
  };

  const content = () => {
    switch (progress) {
      case 'getUpload':
        return <ImageUpload onImageUpload={handleImageUpload} />;
      case 'uploading':
        return <Loader />;
      case 'uploaded':
        return <ImageUpload successText={addNextPhotoText || t48} onImageUpload={handleImageUpload} />;
      case 'uploadError':
        return (
          <>
            <ImageUpload onImageUpload={handleImageUpload} />
            {imageError && <Text error centered>{imageError}</Text>}
            {imageTitleError && <Text error centered>{imageTitleError}</Text>}
          </>
        );
      default:
        return <ImageUpload onImageUpload={handleImageUpload} />;
    }
  };

  return <div className={className}>{content()}</div>;
};

UploadImageWidget.propTypes = {
  addNextPhotoText: PropTypes.string,
  className: PropTypes.string,
  imgTitle: PropTypes.string,
  onGetImageUrl: PropTypes.func,
};

UploadImageWidget.defaultProps = {
  addNextPhotoText: '',
  className: null,
  imgTitle: '',
  onGetImageUrl: null,
};

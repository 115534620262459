import { useEffect, useState } from 'react';

// Custom API request hook:
// method - use one of: axiosGet, axiosPost, axiosPatch
export const useAxios = ({
  fetchOnMount = false,
  method,
  url,
  data,
  params = null,
  callbackSuccess = (() => {}),
  callbackError = (() => {}),
} = {}) => {
  const [responseData, setResponseData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successInfo, setSuccessInfo] = useState(false);

  const axiosFetch = () => {
    setIsLoading(true);
    setResponseError(null);
    method(
      url,
      data,
      params,
    ).then((response) => {
      setResponseData(response.data);
      if (response.status === 200) {
        setSuccessInfo(true);
      }
      callbackSuccess();
    }).catch((error) => {
      const { response } = error;
      setResponseError(response.data.data);
      callbackError(response.data.data);
    }).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (fetchOnMount && method !== null) axiosFetch();
  }, [fetchOnMount, method, url]);

  return {
    axiosFetch,
    isLoading,
    responseData,
    responseError,
    successInfo,
  };
};
